import { connect } from 'react-redux'
import AverageTable from './components/AverageTable'

import { actions } from './reducer'

// default export = container
// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function mapStateToProps(state) {
  return {
    loading: state.average.loading,
    hasError: state.average.hasError,
    timezone: state.profiles.selectedProfile
      ? state.profiles.selectedProfile.timezone
      : '',
    metrics: state.average.metrics,
    profileService: state.profiles.selectedProfileService,
    profile: state.profiles.selectedProfile,
    startDate: state.date.startDate,
    endDate: state.date.endDate,
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
function mapDispatchToProps(dispatch) {
  return {
    // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
    save: (data) => dispatch(actions.save(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AverageTable)

export { Table } from './components/AverageTable'
export { default as Title } from './components/Title'
// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
