import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ChevronDownIcon from '@bufferapp/ui/Icon/Icons/ChevronDown'
import Title from './Title'
import { gray, grayDark } from '@bufferapp/ui/style/colors'
import { fontFamily, lineHeight } from '@bufferapp/ui/style/fonts'

const Button = styled.button`
  cursor: pointer;
  display: flex;
  padding: 0.75rem 1rem;
  color: ${grayDark};
  text-decoration: none;
  text-shadow: none;
  text-align: left;
  border: 1px solid ${gray};
  border-radius: 4px;
  background: #fff;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: ${lineHeight};

  outline: none !important;
  outline-color: transparent !important;
  outline-style: none !important;

  transition: all 0.2s ease-out;
  &:hover {
    border-color: ${grayDark};
  }
  &:active {
    border-color: ${grayDark};
  }

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'ThemedS... Remove this comment to see the full error message
    props.loading &&
    css`
      pointer-events: none;
    `}
`

const Arrow = styled.span`
  margin-left: auto;
`

const DatePickerButton = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'presets' implicitly has an 'any' ... Remove this comment to see the full error message
  presets,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isOpen' implicitly has an 'any' t... Remove this comment to see the full error message
  isOpen,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'startDate' implicitly has an 'any... Remove this comment to see the full error message
  startDate,
  // @ts-expect-error TS(7031) FIXME: Binding element 'endDate' implicitly has an 'any' ... Remove this comment to see the full error message
  endDate,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleClick' implicitly has an 'a... Remove this comment to see the full error message
  handleClick,
}) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <Button loading={loading} disabled={loading} onClick={handleClick}>
    <Title
      presets={presets}
      loading={loading}
      startDate={startDate}
      endDate={endDate}
    />
    {!loading ? (
      <Arrow>
        <ChevronDownIcon />
      </Arrow>
    ) : null}
  </Button>
)

DatePickerButton.defaultProps = {
  startDate: 0,
  endDate: 0,
  loading: false,
}

DatePickerButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
}

export default DatePickerButton
