import React from 'react'
import PropTypes from 'prop-types'
import { ChartTitle } from '~/shared-components'

// @ts-expect-error TS(7006) FIXME: Parameter 'metricType' implicitly has an 'any' typ... Remove this comment to see the full error message
export const getTitle = (metricType) => {
  switch (metricType) {
    case 'day':
      return 'Which day gets the most engagement?'
    case 'type':
      return 'Which is the best type of post?'
    case 'frequency':
      return 'Which is the best frequency to post?'
    default:
      break
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
const Title = ({ forReport, metricType }) => (
  // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  <ChartTitle forReport={forReport}>{getTitle(metricType)}</ChartTitle>
)

Title.propTypes = {
  forReport: PropTypes.bool,
}

Title.defaultProps = {
  forReport: false,
}

export default Title
