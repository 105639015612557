import { gql } from '@apollo/client'
import { snakeCase } from '~/shared-components/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

const GET_POST_INSIGHTS = gql`
  query AnalyticsPosts(
    $serviceId: String!
    $serviceType: String!
    $startDate: Date!
    $endDate: Date!
    $limit: Int
    $orderBy: AnalyticsOrderByField!
    $searchTerms: [String]
  ) {
    analyticsPosts(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      orderBy: $orderBy
      searchTerms: $searchTerms
    ) {
      id
      postId
      content {
        description
        url
        type
        thumbnail
      }
      statistics {
        impressions
        engagements
        likes
        comments
        shares
        clicks
        engagementRate
      }
      sentAt
      updatedAt
    }
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const dataParser = (data) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
  const metrics = data.analyticsPosts.map((post) => ({
    // Apollo returns a formatted time string without timezone data
    // moment doesn't know the timestring is UTC so we have to tell it and
    // and normalise it to a timestamp
    date: dayjs.utc(post.sentAt).format(),
    id: post.id,
    text: post.content.description,
    last_checked: post.updatedAt,
    media: {
      thumbnail: post.content.thumbnail,
    },
    serviceLink: post.content.url,
    statistics: snakeCase(post.statistics),
    type: post.content.type,
  }))

  return { metrics }
}

export default GET_POST_INSIGHTS
