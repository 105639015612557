import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useChannels } from '~/profile-loader'

import ProfileSelectorDropdown from './components/ProfileSelectorDropdown'
import { actions } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ProfileSelector = (props) => {
  const { setSelectedChannel, channels } = useChannels()

  useEffect(() => {
    const selectedChannel = channels.find(
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      (channel) => channel.id === props.selectedProfileId,
    )
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setSelectedChannel(selectedChannel)
  }, [props.selectedProfileId])

  return <ProfileSelectorDropdown {...props} />
}

ProfileSelector.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      service: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectProfile: PropTypes.func.isRequired,
  selectedProfileId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => ({
  profiles: state.profiles.profiles,
  selectedProfileId: state.profiles.selectedProfile
    ? state.profiles.selectedProfile.id
    : '',
  loading: state.profiles.loading,
})

// @ts-expect-error TS(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  selectProfile: (profile) => dispatch(actions.selectProfile(profile)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSelector)

export {
  default as reducer,
  actionTypes,
  actions,
  getAllProfilesFromResult,
} from './reducer'
export { default as middleware } from './middleware'
