import styled from 'styled-components'
import { blue } from '@bufferapp/ui/style/colors'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import React from 'react'

const CheckmarkBackground = styled.span`
  background: ${blue};
  border-radius: 100%;
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-height: 1.5rem;
  max-width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`

export default () => (
  <CheckmarkBackground>
    <CheckmarkIcon color="white" size={'medium'} />
  </CheckmarkBackground>
)
