import { connect } from 'react-redux'
import Resources from './components/Resources'
import { getHasEnoughFollowers } from '~/shared-components/utils'

export default connect((state) => ({
  hasEnoughFollowers: getHasEnoughFollowers(
    // @ts-expect-error TS(2339) FIXME: Property 'compare' does not exist on type 'Default... Remove this comment to see the full error message
    state.compare.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'compare' does not exist on type 'Default... Remove this comment to see the full error message
    state.compare.metrics,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    state.profiles.selectedProfile,
  ),
}))(Resources)
