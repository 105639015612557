import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import reducer, { actions } from './reducer'
import middleware from './middleware'
import CampaignPostsMetrics from './components/CampaignPostsMetrics'

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('campaignPostsMetrics', reducer)
addMiddleware(middleware)

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsMetrics' does not exist on ... Remove this comment to see the full error message
    hasError: state.campaignPostsMetrics.hasError,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsMetrics' does not exist on ... Remove this comment to see the full error message
    loading: state.campaignPostsMetrics.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsMetrics' does not exist on ... Remove this comment to see the full error message
    totals: state.campaignPostsMetrics.totals,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsMetrics' does not exist on ... Remove this comment to see the full error message
    dailyMetrics: state.campaignPostsMetrics.dailyMetrics,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsMetrics' does not exist on ... Remove this comment to see the full error message
    dailyPosts: state.campaignPostsMetrics.dailyPosts,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsMetrics' does not exist on ... Remove this comment to see the full error message
    selectedMetric: state.campaignPostsMetrics.selectedMetric,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsMetrics' does not exist on ... Remove this comment to see the full error message
    postsForModal: state.campaignPostsMetrics.postsForModal,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignSelector' does not exist on type... Remove this comment to see the full error message
    campaign: state.campaignSelector.selectedCampaign,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    selectNewMetric: (metric) => dispatch(actions.selectNewMetric(metric)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
    openModal: (day) => dispatch(actions.openModal(day)),
    closeModal: () => dispatch(actions.closeModal()),
  }),
)(CampaignPostsMetrics)

export { default as Title } from './components/Title'
export { Chart } from './components/CampaignPostsMetrics'

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
