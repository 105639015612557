import styled, { css } from 'styled-components'

const ColoredIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: inline-block;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'large' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.large &&
    css`
      width: 12px;
      height: 12px;
      margin: 0 8px 0 0;
    `}
`

export default ColoredIcon
