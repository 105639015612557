import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'

import reducer, { actions } from './reducer'
import middleware from './middleware'

import CampaignSelector from './components/CampaignSelector'

// default export = container
export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'campaigns' does not exist on type 'Defau... Remove this comment to see the full error message
    campaigns: state.campaigns.campaigns,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignSelector' does not exist on type... Remove this comment to see the full error message
    selectedCampaign: state.campaignSelector.selectedCampaign,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
    onSelect: (campaign) => dispatch(actions.select(campaign)),
  }),
)(CampaignSelector)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('campaignSelector', reducer)
addMiddleware(middleware)
