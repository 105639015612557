import styled from 'styled-components'

export default styled.span`
  display: flex;
  flex: 1 0 auto;
  padding: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'small' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.small ? '.75rem 1rem' : '1.5rem 1.25rem'};
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
`
