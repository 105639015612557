import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReferralIcon } from '~/shared-components'

const lineColorMap = {
  facebook: '#4267B2',
  twitter: '#1DA1F2',
  instagram: '#BD3381',
}

const IconWrapper = styled.span`
  border-radius: 50%;
  background-color: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    lineColorMap[props.name]};
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

const List = styled.ul`
  margin: 16px 0;
  padding: 0;
`

const Item = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`

export const Text = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'bigger' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.bigger ? '16px' : '14px'};
  line-height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'bigger' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.bigger ? '19px' : '16px'};
  color: white;
  margin-left: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'bigger' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.bigger ? '' : '4px'};
`

const TooltipWrapper = styled.div`
  margin: 16px;
`

const Container = styled.div`
  text-align: center;
  padding: 20px 0 0px;
  color: #ffffff;

  opacity: 0.75;
  border-top: 1px dashed rgba(255, 255, 255, 0.5);
`

// @ts-expect-error TS(7031) FIXME: Binding element 'posts' implicitly has an 'any' ty... Remove this comment to see the full error message
export const TimelineTooltip = ({ posts, day }) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'accumulator' implicitly has an 'any' ty... Remove this comment to see the full error message
  const sumOfAllPosts = posts.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.details.length
  }, 0)

  return (
    <TooltipWrapper>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <Text bigger>{sumOfAllPosts} posts</Text>
      <List>
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type. */}
        {posts.map((channel, index) => {
          if (channel.details.length === 0) return
          return (
            <Item key={index}>
              {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
              <IconWrapper name={channel.network}>
                <ReferralIcon
                  item={{
                    source: channel.network,
                  }}
                  size="small"
                  circular
                />
              </IconWrapper>
              <Text>{channel.details.length}</Text>
              <Text>{channel.details.length === 1 ? 'post' : 'posts'}</Text>
            </Item>
          )
        })}
      </List>
      <Container>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <Text normal>Click dot to see posts</Text>
      </Container>
    </TooltipWrapper>
  )
}

TimelineTooltip.propTypes = {
  tooltipAction: PropTypes.func,
}
