import React from 'react'
import reactDOM from 'react-dom/server'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

import ChartTooltip from '../ChartTooltip'

export function truncateNumber() {
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  let number = parseFloat(this.value)

  if (number > 1000000) {
    number = numeral(number).format('0.[00]a')
  } else if (number >= 10000) {
    number = numeral(number).format('0.[0]a')
  } else if (number < 1 && number > 0) {
    number = numeral(number).format('0,0.0')
  } else {
    number = numeral(number).format('0,0')
  }

  return number
}

export function xAxisLabelFormatter() {
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  const date = dayjs(new Date(this.value)).utc()
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  if (!date.isValid()) return this.value
  const isFirstOfMonth = date.date() === 1
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  if (this.isFirst || isFirstOfMonth) {
    return date.format('MMM D')
  }
  return date.format('D')
}

export function tooltipFormatter() {
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  const point = this.points[0].point
  return reactDOM.renderToStaticMarkup(
    <ChartTooltip {...point.metricData} day={point.x} />,
  )
}

export function pointFormatter() {
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  return `${this.series.name}: <b>${this.y}</b><br/>`
}

export const getXAxis = () => ({
  gridLineColor: '#F3F5F7',
  gridLineWidth: 0,
  lineColor: '#F3F5F7',
  maxPadding: 0,
  minPadding: 0,
  minorGridLineColor: '#F3F5F7',
  minorGridLineWidth: 0,
  minorTickWidth: 0,
  minorTickLength: 0,
  tickLength: 0,
  title: { text: null },
  type: 'datetime',
  labels: {
    align: 'center',
    formatter: xAxisLabelFormatter,
    x: 0,
    y: 25,
    style: {
      color: '#636363',
      'font-size': '14px',
      'font-weight': '500',
      'font-family': 'Roboto, sans serif',
      'white-space': 'nowrap',
    },
  },
})

export const getYAxis = () => [
  {
    title: { text: null },
    gridLineWidth: 2,
    max: null,
    min: 0,
    softMin: 0,
    minRange: 8,
    maxPadding: 0.1,
    minPadding: 0.1,
    allowDecimals: false,
    gridLineColor: '#F3F5F7',
    lineColor: '#F3F5F7',
    showLastLabel: true,
    labels: {
      x: -15,
      y: 4,
      align: 'right',
      format: '{value}',
      formatter: truncateNumber,
      style: {
        color: '#636363',
        'font-size': '14px',
        'font-weight': '500',
        'font-family': 'Roboto, sans serif',
      },
    },
  },
]

export const highChartsSeriesPrimaryConfig = {
  type: 'areaspline',
  lineWidth: 2,
  states: {
    hover: {
      lineWidth: 3,
    },
  },
  data: null,
}

export default () => ({
  title: null,
  xAxis: getXAxis(),
  yAxis: getYAxis(),
  chart: {
    spacingLeft: 25,
    spacingRight: 40,
    spacingTop: 20,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 2,
      marker: {
        enabled: false,
        lineWidth: 3,
        radius: 4,
        symbol: 'circle',
      },
      states: {
        hover: {
          radiusPlus: 1.5,
        },
      },
    },
    column: {
      colorByPoint: true,
      stacking: 'normal',
    },
  },
  tooltip: {
    shared: true,
    crosshairs: true,
    formatter: tooltipFormatter,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 7,
    borderWidth: 0,
    pointFormatter,
    shadow: false,
    useHTML: true,
  },
  series: [],
})
