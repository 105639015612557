import React from 'react'
import PropTypes from 'prop-types'
import {
  ChartStateLoading as Loading,
  ChartStateNoData as NoData,
  ChartCard,
  ChartHeader,
  Container,
} from '~/shared-components'
import AddReport from '~/add-report'
import GraphQLWrapper, {
  formatQueryVariables,
} from '~/shared-components/GraphQLWrapper'
import Title from '../Title'
import { TableWithMetrics as Table } from '../Table'
import QUERY, { dataParser } from '../../query'

// @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
const Content = ({ loading, ...props }) => {
  let content = null
  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else if (!props.type || props.type.best === null) {
    content = <NoData chartName="answers-summary" />
  } else {
    // @ts-expect-error TS(2741) FIXME: Property 'metrics' is missing in type '{}' but req... Remove this comment to see the full error message
    content = <Table {...props} />
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title />
        <AddReport chart="answers-summary" />
      </ChartHeader>
      <Container id="js-dom-to-png-answers-summary">{content}</Container>
    </ChartCard>
  )
}

Content.defaultProps = {
  loading: false,
}

Content.propTypes = {
  loading: PropTypes.bool,
  metrics: PropTypes.shape(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Requireable<(InferProps<{ total:... Remove this comment to see the full error message
    PropTypes.arrayOf(
      PropTypes.shape({
        total: PropTypes.number,
        total_by_network: PropTypes.arrayOf(
          PropTypes.shape({
            total: PropTypes.number,
            percentage: PropTypes.string,
          }),
        ),
      }),
    ),
  ).isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const AudienceSummary = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.profile.service !== 'linkedin' && <Content {...props} />}
      {props.profile.service === 'linkedin' && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: Content,
            save: props.save,
            title: 'Answers overview',
          }}
        />
      )}
    </React.Fragment>
  )
}

export default AudienceSummary
