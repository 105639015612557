import { Text } from '@bufferapp/ui'
import React from 'react'
import styled from 'styled-components'

const Body = styled.section`
  margin-top: 0.67rem;
  margin-bottom: 1.25rem;
  max-width: 60ch;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ children }) => (
  <Body>
    {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
    <Text>{children}</Text>
  </Body>
)
