import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled from 'styled-components'

const Text = styled.p`
  margin: 0;

  span {
    margin-left: 8px;
    color: #777575;
    font-weight: 400;
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
const formatDate = (date, dateFormat = 'MM/DD/YY') =>
  moment.unix(date).format(dateFormat)

// @ts-expect-error TS(7031) FIXME: Binding element 'presets' implicitly has an 'any' ... Remove this comment to see the full error message
const Title = ({ presets, loading, startDate, endDate }) => {
  let title
  let range

  if (!loading) {
    const selectedRange =
      // @ts-expect-error TS(7006) FIXME: Parameter 'preset' implicitly has an 'any' type.
      presets.find((preset) => preset.selected) || presets[4]
    const from =
      moment.unix(startDate).year() === moment.unix(endDate).year()
        ? formatDate(startDate, 'MMM D')
        : formatDate(startDate, 'MMM D, YYYY')
    const to =
      moment.unix(startDate).month() === moment.unix(endDate).month()
        ? formatDate(endDate, 'D, YYYY')
        : formatDate(endDate, 'MMM D, YYYY')
    title =
      startDate === null || endDate === null ? 'Custom' : selectedRange.label
    range = startDate === null || endDate === null ? '' : `${from} - ${to}`

    if (startDate && endDate && startDate === endDate) {
      range = formatDate(startDate, 'MMM D, YYYY')
    }
  } else {
    title = 'Loading...'
  }
  return (
    <Text>
      {title} {range && <span>{range}</span>}
    </Text>
  )
}

Title.defaultProps = {
  loading: false,
  startDate: 0,
  endDate: 0,
}

Title.propTypes = {
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  loading: PropTypes.bool,
}

export default Title
