import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import TruncatedNumber from '../../../TruncatedNumber'
import CircleIcon from '../../../CircleIcon'
import Text from '@bufferapp/ui/Text'
import OrganicIcon from '@bufferapp/ui/Icon/Icons/Organic'
import PromotedIcon from '@bufferapp/ui/Icon/Icons/Promoted'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

import { blueLight, gray, white } from '@bufferapp/ui/style/colors'

const organicColor = blueLight
const paidColor = '#4DD9DD'

const StyledToolip = styled.div`
  max-width: 200px;
  padding: 8px;
  color: ${white};
  cursor: default;
  font-family: Roboto, sans serif;
  pointer-events: none;
  display: grid;
  grid-row-gap: 12px;
`

const Date = styled.span`
  display: block;
  font-size: 12px;
  color: ${gray};
`

const TooltipLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: ${white};
  font-size: ${({
    // @ts-expect-error TS(2339) FIXME: Property 'total' does not exist on type 'Omit<Deta... Remove this comment to see the full error message
    total,
  }) => (total ? '16px' : '14px')};
  display: flex;
`

const StyledIcon = styled.span`
  margin-right: 4px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'color' implicitly has an 'any' ty... Remove this comment to see the full error message
const Icon = ({ color, suffix }) => (
  <StyledIcon>
    <CircleIcon color={color}>
      {suffix === 'paid' ? <PromotedIcon /> : <OrganicIcon />}
    </CircleIcon>
  </StyledIcon>
)

// @ts-expect-error TS(7031) FIXME: Binding element 'point' implicitly has an 'any' ty... Remove this comment to see the full error message
const PaidChartTooltip = ({ point, paid }) => {
  const percentageOrganic = (point.y / (point.y + paid.y)) * 100
  return (
    <StyledToolip>
      <Date>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
        <Text>{dayjs.utc(point.x).format('D MMMM')}</Text>
      </Date>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <TooltipLabel total>
        <TruncatedNumber>{point.y + paid.y}</TruncatedNumber> &nbsp; total
      </TooltipLabel>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <TooltipLabel organic>
        <Icon color={organicColor} suffix="organic" />
        <TruncatedNumber>{point.y}</TruncatedNumber>&nbsp;(
        <TruncatedNumber showPercentSign>{percentageOrganic}</TruncatedNumber>)
        organic
      </TooltipLabel>
      <TooltipLabel>
        <Icon color={paidColor} suffix="paid" />
        <TruncatedNumber>{paid.y}</TruncatedNumber>&nbsp;(
        <TruncatedNumber showPercentSign>
          {100 - percentageOrganic}
        </TruncatedNumber>
        ) boosted
      </TooltipLabel>
    </StyledToolip>
  )
}

PaidChartTooltip.propTypes = {
  point: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    metricKey: PropTypes.string.isRequired,
    currency: PropTypes.string,
    valueFirst: PropTypes.bool,
  }).isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'point' implicitly has an 'any' ty... Remove this comment to see the full error message
const ChartTooltip = ({ point }) => {
  return (
    <StyledToolip>
      <Date>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
        <Text>{dayjs.utc(point.x).format('D MMMM')}</Text>
      </Date>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <TooltipLabel total>
        <TruncatedNumber>{point.y}</TruncatedNumber> &nbsp; total
      </TooltipLabel>
    </StyledToolip>
  )
}

PaidChartTooltip.propTypes = {
  point: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    metricKey: PropTypes.string.isRequired,
    currency: PropTypes.string,
    valueFirst: PropTypes.bool,
  }).isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export default (props) => {
  if (props.paid && props.paid.y) {
    return <PaidChartTooltip {...props} />
  }

  return <ChartTooltip {...props} />
}
