import React from 'react'
import PropTypes from 'prop-types'
import Text from '@bufferapp/ui/Text'
import getSymbolFromCurrency from 'currency-symbol-map'

import TruncatedNumber from '../../../TruncatedNumber'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Value = ({ children, showPercentSign, smaller, currency, hideValue }) => (
  <Text as="span" type="h3">
    {hideValue && <span>&mdash;</span>}
    {!hideValue && (
      <React.Fragment>
        {currency && getSymbolFromCurrency(currency)}
        <TruncatedNumber showPercentSign={showPercentSign}>
          {children}
        </TruncatedNumber>
      </React.Fragment>
    )}
  </Text>
)
Value.defaultProps = {
  smaller: false,
  showPercentSign: false,
  currency: null,
  hideValue: false,
}

Value.propTypes = {
  children: PropTypes.node.isRequired,
  smaller: PropTypes.bool,
  showPercentSign: PropTypes.bool,
  currency: PropTypes.string,
  hideValue: PropTypes.bool,
}

export default Value
