import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Toolbar = styled.div`
  display: flex;
  justify-content: ${(props) =>
    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
    props.children.length > 1 ? 'space-between' : 'flex-end'};
  width: 100%;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ToolbarComponent = ({ children }) => <Toolbar>{children}</Toolbar>

ToolbarComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ToolbarLeft = styled.div`
  display: flex;
  align-content: center;
  position: relative;
  z-index: 2;
`

export const ToolbarRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
`

export const ToolbarItem = styled.div`
  display: flex;
  align-content: center;
  margin-left: 0.5rem;
`

export default ToolbarComponent
