import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@bufferapp/ui'
import { Add } from '@bufferapp/ui/Icon'
import { Helper } from '~/shared-components'

import Modal from '../Modal'

const ClickCatcher = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

// this is a bit of a hack because the BDS icon button has a negative margin that's mislaigning the icon
const Wrapper = styled.span`
  div > div {
    justify-content: center;
  }
`

class AddReport extends Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.addReport = this.addReport.bind(this)
    this.selectReport = this.selectReport.bind(this)
  }

  toggleMenu() {
    this.setState({
      // @ts-expect-error TS(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      open: !this.state.open,
    })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  addReport(name) {
    this.setState({
      open: false,
    })
    // @ts-expect-error TS(2339) FIXME: Property 'createReport' does not exist on type 'Re... Remove this comment to see the full error message
    this.props.createReport(name)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  selectReport(id) {
    this.setState({
      open: false,
    })
    // @ts-expect-error TS(2339) FIXME: Property 'addToReport' does not exist on type 'Rea... Remove this comment to see the full error message
    this.props.addToReport(id)
  }

  render() {
    return (
      <Wrapper>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; text: string; }' is not... Remove this comment to see the full error message */}
        <Helper text={'Add this chart to a report'}>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; hasIconOnly... Remove this comment to see the full error message */}
          <Button
            label={'Add to Report'}
            hasIconOnly
            icon={<Add />}
            onClick={this.toggleMenu}
            size="small"
          />
        </Helper>
        <Modal
          // @ts-expect-error TS(2339) FIXME: Property 'translations' does not exist on type 'Re... Remove this comment to see the full error message
          translations={this.props.translations}
          // @ts-expect-error TS(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          open={this.state.open}
          toggle={this.toggleMenu}
          addReport={this.addReport}
          selectReport={this.selectReport}
          // @ts-expect-error TS(2339) FIXME: Property 'reports' does not exist on type 'Readonl... Remove this comment to see the full error message
          reports={this.props.reports}
          // @ts-expect-error TS(2339) FIXME: Property 'fetchReportList' does not exist on type ... Remove this comment to see the full error message
          fetchReportList={this.props.fetchReportList}
        />
        {/* @ts-expect-error TS(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
        {this.state.open && <ClickCatcher onClick={this.toggleMenu} />}
      </Wrapper>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
AddReport.defaultProps = {
  reports: [],
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
AddReport.propTypes = {
  translations: PropTypes.shape({
    addReport: PropTypes.string,
  }).isRequired,
  createReport: PropTypes.func.isRequired,
  addToReport: PropTypes.func.isRequired,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      updated_at: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  fetchReportList: PropTypes.func.isRequired,
}

export default AddReport
