import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  GridItem,
  Helper,
  Container,
} from '~/shared-components'

import AddReport from '~/add-report'

import Title from '../Title'
import { LockIfNotAllowed, STORIES } from '~/account'

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
export const Table = ({ metrics }) => (
  <Grid>
    {/* @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type. */}
    {metrics.map((metric, index) => {
      const itemProps = {
        key: metric.label,
        metric,
        gridWidth: '33.33%',
        showPercentSign: metric.label === 'Engagement Rate',
      }
      if (metrics.length === 5) itemProps.gridWidth = '20%'
      return <GridItem {...itemProps} inline={index > 2} />
    })}
  </Grid>
)

Table.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      diff: PropTypes.number,
    }),
  ).isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const StoriesSummaryTable = ({ metrics, loading }) => {
  let content = null
  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else if (metrics.length === 0) {
    content = <NoData chartName="stories-summary" />
  } else {
    content = <Table metrics={metrics} />
  }

  return (
    <ChartCard>
      {/* @ts-expect-error TS(2741) FIXME: Property 'exporting' is missing in type '{ childre... Remove this comment to see the full error message */}
      <LockIfNotAllowed {...{ STORIES }}>
        <ChartHeader>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message */}
          <Helper label="instagram disclaimer">
            {/* @ts-expect-error TS(2741) FIXME: Property 'profileService' is missing in type '{}' ... Remove this comment to see the full error message */}
            <Title />
          </Helper>
          <AddReport chart="stories-summary" />
        </ChartHeader>
        <Container id="js-dom-to-png-stories-summary">{content}</Container>
      </LockIfNotAllowed>
    </ChartCard>
  )
}

StoriesSummaryTable.defaultProps = {
  loading: false,
}

StoriesSummaryTable.propTypes = {
  loading: PropTypes.bool,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      diff: PropTypes.number,
    }),
  ).isRequired,
}

export default StoriesSummaryTable
