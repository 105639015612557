import styled from 'styled-components'
import { gray } from '@bufferapp/ui/style/colors'

export const Content = styled.main`
  padding-left: 2.25rem;
  min-width: 24rem;
  max-width: 24rem;
  line-height: 1.5;
`
export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px 4px 0 0;
  border: 1px solid ${gray};
  overflow: hidden;

  & > * + * {
    margin-left: 1rem;
  }
`

export const CrossSellImage = styled.img`
  align-self: center;
  object-fit: contain;
  object-position: right;
  max-height: 150%;
  max-width: 90%;
`

export const TagsWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`

export const TagsCrossSellImage = styled.img`
  align-self: center;
  object-fit: contain;
  object-position: right;
  max-height: 50%;
  max-width: 90%;
`

export const TextContent = styled.main`
  text-align: center;
  min-width: 27rem;
  max-width: 27rem;
  line-height: 1.5;
`
