import React from 'react'

export const ChannelsContext = React.createContext({
  channels: [],
  selecteChannel: null,
  setSelectedChannel: undefined,
})

export function useChannels() {
  return React.useContext(ChannelsContext)
}
