import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  ChartTitle,
} from '~/shared-components'

import NoData from '../NoData'
import TableRow, { TableHeader } from '../TableRows'
import AddReport from '~/add-report'

import GraphQLWrapper, {
  formatQueryVariables,
} from '~/shared-components/GraphQLWrapper'
import QUERY, { dataParser } from '../../query'

const ChartContainer = styled.div`
  position: relative;
  border-radius: 2px;
  font-size: 12px;
`

const HashtagsTableWrapper = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
`

const GridContainer = styled.div`
  position: relative;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'hashtags' implicitly has an 'any' type.
function sortHashtags(hashtags, metricKey, descending) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
  return hashtags.sort((a, b) => {
    let value = 0
    if (a[metricKey] > b[metricKey]) {
      value = 1
    } else if (a[metricKey] < b[metricKey]) {
      value = -1
    }

    if (descending) {
      value *= -1
    }

    return value
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'hashtags' implicitly has an 'any' type.
function getFilteredMetrics(hashtags, sortBy, isDescending, numToShow) {
  return sortHashtags(hashtags, sortBy, isDescending).slice(0, numToShow)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const Table = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.hashtags && <TableWithHashtags {...props} />}
      {!props.hashtags && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: TableWithHashtags,
          }}
        />
      )}
    </React.Fragment>
  )
}

const TableWithHashtags = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
  forReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleSortBy' implicitly has an '... Remove this comment to see the full error message
  handleSortBy,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hashtags' implicitly has an 'any'... Remove this comment to see the full error message
  hashtags,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDescending' implicitly has an '... Remove this comment to see the full error message
  isDescending,
  // @ts-expect-error TS(7031) FIXME: Binding element 'labels' implicitly has an 'any' t... Remove this comment to see the full error message
  labels,
  // @ts-expect-error TS(7031) FIXME: Binding element 'numToShow' implicitly has an 'any... Remove this comment to see the full error message
  numToShow,
  // @ts-expect-error TS(7031) FIXME: Binding element 'sortBy' implicitly has an 'any' t... Remove this comment to see the full error message
  sortBy,
}) => (
  <ChartContainer>
    <HashtagsTableWrapper>
      <TableHeader
        forReport={forReport}
        labels={labels}
        hideSortButtons={hashtags.length === 0}
        sortBy={sortBy}
        isDescending={isDescending}
        handleSortBy={handleSortBy}
      />
      {hashtags.length === 0 ? (
        <NoData />
      ) : (
        getFilteredMetrics(hashtags, sortBy, isDescending, numToShow).map(
          // @ts-expect-error TS(7006) FIXME: Parameter 'hashtag' implicitly has an 'any' type.
          (hashtag, index) => (
            <TableRow
              key={hashtag.display_name}
              index={index}
              hashtag={hashtag}
            />
          ),
        )
      )}
    </HashtagsTableWrapper>
  </ChartContainer>
)

TableWithHashtags.propTypes = {
  hashtags: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string,
      posts_count: PropTypes.number,
      primary_metric: PropTypes.number,
      secondary_metric: PropTypes.number,
    }),
  ).isRequired,
  labels: PropTypes.shape({
    primary_metric: PropTypes.string,
    secondary_metric: PropTypes.string,
  }).isRequired,
  sortBy: PropTypes.string.isRequired,
  numToShow: PropTypes.number.isRequired,
  isDescending: PropTypes.bool.isRequired,
  handleSortBy: PropTypes.func.isRequired,
  forReport: PropTypes.bool,
}

TableWithHashtags.defaultProps = {
  forReport: false,
  labels: { primary_metric: 'Impressions' },
}

export const Title = () => <ChartTitle>Hashtag performance</ChartTitle>

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Content = (props) => {
  const { hashtags, isDescending, sortBy, numToShow, loading } = props

  let content = null
  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else {
    content = (
      <div id="js-dom-to-png-hashtags">
        <Table {...props} />
      </div>
    )
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title />
        {hashtags.length > 0 && (
          <AddReport
            chart="hashtags-table"
            state={{
              isDescending,
              sortBy,
              numToShow,
            }}
          />
        )}
      </ChartHeader>
      <GridContainer>{content}</GridContainer>
    </ChartCard>
  )
}

Content.defaultProps = {
  loading: false,
  sortBy: 'primary_metric',
  numToShow: 5,
  isDescending: true,
}

Content.propTypes = {
  loading: PropTypes.bool,
  hashtags: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string,
      posts_count: PropTypes.number,
      avg_impressions: PropTypes.number,
      avg_engagement: PropTypes.number,
    }),
  ).isRequired,
  sortBy: PropTypes.string.isRequired,
  numToShow: PropTypes.number.isRequired,
  isDescending: PropTypes.bool.isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const HashtagsTable = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.profile.service !== 'linkedin' && <Content {...props} />}
      {props.profile.service === 'linkedin' && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: Content,
            title: 'Hashtag Performance',
            // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
            save: (data) => props.save(data),
          }}
        />
      )}
    </React.Fragment>
  )
}

export default HashtagsTable
