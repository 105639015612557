import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { DayToPost, TypeOfPost, FrequencyOfPost } from './icons'
import GraphQLWrapper, {
  formatQueryVariables,
} from '~/shared-components/GraphQLWrapper'
import QUERY, { dataParser } from '../../query'

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
`

const GridItemContainer = styled.li`
  width: 33.33%;
`

const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  align-items: center;
`

const GridContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 16px;
    margin-top: 1px;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
const Metric = ({ value, label, children }) => (
  <GridItemContainer>
    <GridItem>
      {children}
      <GridContent>
        <Text type="label" color="grayDark">
          {label}
        </Text>
        <Text as="span" type="h3">
          {value}
        </Text>
      </GridContent>
    </GridItem>
  </GridItemContainer>
)

Metric.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'frequency' implicitly has an 'any' type... Remove this comment to see the full error message
const formatFrequency = (frequency) =>
  parseInt(frequency, 10) === 1
    ? `${frequency} post a day`
    : `${frequency} posts a day`

// @ts-expect-error TS(7006) FIXME: Parameter 'typeOfPost' implicitly has an 'any' typ... Remove this comment to see the full error message
const getBestTypeOfPost = (typeOfPost) => {
  switch (typeOfPost) {
    case 'carousel_album':
      return 'Carousel album'
    case 'RICH':
      return 'Media'
    default:
      return `${
        typeOfPost.charAt(0).toUpperCase() + typeOfPost.slice(1).toLowerCase()
      }`.replace(/_\w+$/, '')
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'day' implicitly has an 'any' type... Remove this comment to see the full error message
export const TableWithMetrics = ({ day, type, frequency }) => (
  <Grid>
    {day.bestDayFromPredictions ? (
      <Metric label="Best time to post" value={day.bestDayFromPredictions}>
        <DayToPost />
      </Metric>
    ) : (
      <Metric label="Best day to post" value={day.best}>
        <DayToPost />
      </Metric>
    )}
    <Metric label="Best type of post" value={getBestTypeOfPost(type.best)}>
      <TypeOfPost />
    </Metric>
    <Metric
      label="Best frequency to post"
      value={formatFrequency(frequency.best)}
    >
      <FrequencyOfPost />
    </Metric>
  </Grid>
)

TableWithMetrics.propTypes = {
  metrics: PropTypes.shape(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Requireable<(InferProps<{ total:... Remove this comment to see the full error message
    PropTypes.arrayOf(
      PropTypes.shape({
        total: PropTypes.number,
        total_by_network: PropTypes.arrayOf(
          PropTypes.shape({
            total: PropTypes.number,
            percentage: PropTypes.string,
          }),
        ),
      }),
    ),
  ).isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const Table = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.profile.service !== 'linkedin' && <TableWithMetrics {...props} />}
      {props.profile.service == 'linkedin' && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: TableWithMetrics,
          }}
        />
      )}
    </React.Fragment>
  )
}

export default Table
