import React from 'react'
import reactDOM from 'react-dom/server'

import ChartTooltip from '../ChartTooltip'

// @ts-expect-error TS(7006) FIXME: Parameter 'chartType' implicitly has an 'any' type... Remove this comment to see the full error message
const chartConfig = (chartType, chartSize) => {
  return {
    title: null,
    chart: {
      type: chartType,
      height: chartSize == 'small' ? 90 : 150,
      spacing: [8, 0, 0, 0],
    },
    xAxis: {
      title: { text: null },
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        enabled: false,
      },
      crosshair: {
        width: 0,
      },
    },
    yAxis: {
      title: { text: null },
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPlacement: 'between',
        pointWidth: null,
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0,
        stacking: 'normal',
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter() {
        // @ts-expect-error TS(2339) FIXME: Property 'points' does not exist on type '{ shared... Remove this comment to see the full error message
        const point = this.points[0].point
        // @ts-expect-error TS(2339) FIXME: Property 'points' does not exist on type '{ shared... Remove this comment to see the full error message
        const paid = this.points[1] ? this.points[1].point : null
        return reactDOM.renderToStaticMarkup(
          <ChartTooltip point={point} paid={paid} />,
        )
      },
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: 7,
      borderWidth: 0,
      hideDelay: 10,
      shadow: false,
      useHTML: true,
      // @ts-expect-error TS(7023) FIXME: 'positioner' implicitly has return type 'any' beca... Remove this comment to see the full error message
      positioner: function (boxWidth, boxHeight, point) {
        // @ts-expect-error TS(7022) FIXME: 'chart' implicitly has type 'any' because it does ... Remove this comment to see the full error message
        // eslint-disable-line
        const chart = this.chart
        const boxOffset = 30
        // @ts-expect-error TS(7022) FIXME: 'origin' implicitly has type 'any' because it does... Remove this comment to see the full error message
        const origin = point.plotX + chart.plotLeft
        // @ts-expect-error TS(7022) FIXME: 'x' implicitly has type 'any' because it does not ... Remove this comment to see the full error message
        let x = origin - (boxWidth - boxOffset)

        if (x < 0) x = 0

        const isOverflowing = x + boxWidth > chart.plotWidth
        if (isOverflowing) {
          const overflowingAmount = x + boxWidth - chart.plotWidth
          x -= overflowingAmount
        }

        return { x, y: 0 }
      },
    },
  }
}

export default chartConfig
