/*
  eslint-disable class-methods-use-this
*/

export class Answer {
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  getTitle(value) {
    return `${value.charAt(0).toUpperCase()}${value
      .slice(1)
      .toLowerCase()}`.replace(/_\w+$/, '')
  }

  getDescription() {
    return 'posts earn the highest engagement'
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  getChartLabel(value) {
    return this.getTitle(value)
  }
}

export class DayAnswer extends Answer {
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  getChartLabel(value) {
    return value.charAt(0).toUpperCase()
  }
}

export class FrequencyAnswer {
  TIMES = {
    // eslint-disable-line no-undef
    1: 'Once',
    2: 'Twice',
    3: 'Three',
    4: 'Four',
    5: 'Five',
    6: 'Six',
    7: 'Seven',
    8: 'Eight',
    9: 'Nine',
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  getTitle(value) {
    let times
    value = Number(value)
    if (value === 1 || value === 2) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      times = this.TIMES[value]
    } else if (value > 2 && value < 10) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      times = `${this.TIMES[value]} times`
    } else {
      times = `${value} times`
    }
    return `${times} daily`
  }

  getDescription() {
    return 'earns you the highest engagement rate'
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  getChartLabel(value) {
    return this.getTitle(value)
  }
}

export default class Answers {
  // @ts-expect-error TS(7006) FIXME: Parameter 'metricType' implicitly has an 'any' typ... Remove this comment to see the full error message
  static forType(metricType) {
    if (metricType === 'frequency') {
      return new FrequencyAnswer()
    } else if (metricType === 'day') {
      return new DayAnswer()
    }
    return new Answer()
  }
}
