import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { gray } from '@bufferapp/ui/style/colors'
import ErrorBoundary from '../ErrorBoundary'

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: #fcfcfc;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border: 1px solid ${gray};
  border-radius: 4px 4px 0 0;
  border-width: 1px 1px 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
`

const LayoutSubtabs = styled.div`
  width: 100%;
  margin: 0 0 1px;
`

const LayoutContents = styled.div`
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ContentCard = ({ children, subtabs, hasError }) => (
  // @ts-expect-error TS(2786) FIXME: 'ErrorBoundary' cannot be used as a JSX component.
  <ErrorBoundary hasError={hasError}>
    <Container>
      {subtabs && <LayoutSubtabs>{subtabs}</LayoutSubtabs>}
      <LayoutContents>{children}</LayoutContents>
    </Container>
  </ErrorBoundary>
)

ContentCard.defaultProps = {
  hasError: false,
  subtabs: null,
}

ContentCard.propTypes = {
  children: PropTypes.node.isRequired,
  subtabs: PropTypes.node,
  hasError: PropTypes.node,
}

export default ContentCard
