export const metricsConfig = {
  twitter: [
    { key: 'impressions', label: 'Impressions' },
    { key: 'engagements', label: 'Engagements' },
    { key: 'favorites', label: 'Favorites' },
    { key: 'replies', label: 'Replies' },
    { key: 'retweets', label: 'Retweets' },
    { key: 'url_clicks', label: 'Clicks' },
    { key: 'engagement_rate', label: 'Engagement Rate' },
  ],
  facebook: [
    { key: 'impressions', label: 'Impressions' },
    { key: 'reach', label: 'Reach' },
    { key: 'reactions', label: 'Reactions' },
    { key: 'likes', label: 'Likes' },
    { key: 'comments', label: 'Comments' },
    { key: 'shares', label: 'Shares' },
    { key: 'clicks', label: 'Clicks' },
    { key: 'engagement_rate', label: 'Engagement Rate' },
  ],
  instagram: [
    { key: 'impressions', label: 'Impressions' },
    { key: 'reach', label: 'Reach' },
    { key: 'engagement', label: 'Engagement' },
    { key: 'likes', label: 'Likes' },
    { key: 'comments', label: 'Comments' },
    { key: 'saves', label: 'Saves' },
    { key: 'engagement_rate', label: 'Engagement Rate' },
  ],
  linkedin: [
    { key: 'impressions', label: 'Impressions' },
    { key: 'engagements', label: 'Engagement' },
    { key: 'likes', label: 'Likes' },
    { key: 'comments', label: 'Comments' },
    { key: 'shares', label: 'Shares' },
    { key: 'clicks', label: 'Clicks' },
    { key: 'engagement_rate', label: 'Engagement Rate' },
  ],
}
