/* eslint quote-props: ["error", "consistent"] */

// ideally we'd have more central and formalised format for metrics across app
// using labels as we don't always get the key from metrics object
export default {
  'reach':
    'The number of unique people who saw the post(s) sent during this period',
  'engagement rate':
    'Organic (Comment + Clicks + Shares + Reactions) divided by Impressions',
  'followers': 'Your total number of followers',
  'impressions': 'The number of times people saw your post(s)',
  'engaged users':
    'The number of unique users that have liked your page, or engaged with your content',
  'post impressions': 'The number of times people saw your post',
  'reactions': 'The number of reactions your post(s) received',
  'post reach': 'The number of unique people who saw any of your post(s)',
  'page & post engagements':
    'The number of times people have engaged with your posts',
  'link clicks': 'The number of link clicks your post(s) received',
  'new fans': 'The number of fans you gained or lost',
  'posts': 'The number of posts you have published and/or boosted',
  'daily average impressions':
    'The average number of times your posts were seen',
  'daily average engagements':
    'The average number of engagements on your posts',
  'daily average clicks': 'The average number of link clicks on your posts',
  'average likes per post': 'The average number of likes for your posts',
  'average comments per post': 'The average number of comments on your posts',
  'average impressions per tweet':
    'The average number of times your tweets were seen',
  'average engagements per tweet':
    'The average number of engagements on your tweets',
  'average clicks per tweet':
    'The average number of link clicks on your tweets',
  'total fans': 'Your total number of fans',
  'shares': 'The number of times your posts were shared',
  'comments': 'The number of comments on your posts',
  'completion rate':
    'The percentage of people that fully watched and didn’t exit from a story',
  'average reach': 'The average number of unique people who saw your stories',
  'exits': 'The number of people who have left a story before completion',
  'tweets': 'The number of tweets you have published',
  'retweets': 'The number of times your tweets have been retweeted',
  'engagements': 'The number of engagements on your content',
  'engagement': 'The number of engagements on your content',
  'replies': 'The number of replies for your content',
  'new followers': 'The number of new followers added since last period',
  'clicks': 'The number of link clicks on your post',
  'total followers': 'Your total number of followers',
  'likes': 'The number of times your posts were liked',
  'eng. rate':
    'Organic (Comment + Clicks + Shares + Reactions) divided by Impressions',
  'total audience':
    'Your total audience added together across all your networks',
  'total impressions':
    'Your total impressions added together across all your networks',
  'total engagement':
    'Your total engagement added together across all your networks',
  'instagram disclaimer':
    'All of the Stories posted to your Instagram account. Note that Stories that have been re-shared to your account will not appear.',
  'avg. order value':
    'The average amount that a customer from this channel has spent for each order.',
  'avg. customer lifetime value':
    'The total amount a customer has spend, on average, across all your products during their lifetime.',
  'customers':
    'The total number of people who placed an order with you in the selected period.',
  'total sales':
    'The total amount generated from customers in the selected period.',
  'units sold':
    'The total number of units sold for this product in the selected period.',
  'avg. engagement rate':
    'The average engagement rate of all your posts in this campaign',
  'stories': 'The number of stories you have published',
}
