import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { GridItem } from '~/shared-components'

const Button = styled.button`
  background: #ffffff;
  border: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'selected' does not exist on type 'Themed... Remove this comment to see the full error message
    props.selected ? '1px solid #2b6af6' : '1px solid #d5e3ef'};
  box-sizing: border-box;
  box-shadow: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'selected' does not exist on type 'Themed... Remove this comment to see the full error message
    props.selected
      ? '0px 0px 4px rgba(43, 106, 246, 0.6),0px 4px 4px rgba(0, 0, 0, 0.04)'
      : '0px 1px 2px rgba(0, 0, 0, 0.05)'};
  border-radius: 3px;
  padding: 14px;
  width: 100%;
  height: 100%;
  text-align: left;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
    props.forReport &&
    css`
      pointer-events: none;
    `}

  :hover {
    border: 1px solid #2b6af6;
    cursor: pointer;
  }

  > div {
    width: 100%;
    margin-bottom: 0;
  }
`

const ListItem = styled.li`
  list-style: none;
  margin: 0 4px;
  width: 20%;
  background: #ffffff;
`

export const SelectorButton = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
  metric,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selected' implicitly has an 'any'... Remove this comment to see the full error message
  selected,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectNewMetric' implicitly has a... Remove this comment to see the full error message
  selectNewMetric,
  // @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
  forReport,
}) => {
  return (
    <ListItem>
      <Button
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        selected={selected}
        onClick={() => selectNewMetric(metric.label)}
        forReport={forReport}
      >
        <GridItem
          metric={metric}
          standalone
          showPercentSign={metric.label === 'Avg. Engagement Rate'}
        />
      </Button>
    </ListItem>
  )
}

SelectorButton.defaultProps = {
  metric: {},
}

SelectorButton.propTypes = {
  metric: PropTypes.shape({}),
}
