import React from 'react'
import Text from '@bufferapp/ui/Text'
import styled from 'styled-components'

const Header = styled.div`
  margin: 0;
  padding: 0;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`
interface TitleProps {
  children:
    | string
    | string[]
    | JSX.Element
    | JSX.Element[]
    | (() => JSX.Element)
}

const Title = ({ children }: TitleProps): JSX.Element => (
  <Header>
    <Text type="h3">{children}</Text>
  </Header>
)

export default Title
