import { connect } from 'react-redux'
import AnswersSummary from './components/AnswersSummary'
import { actions } from './reducer'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'answersSummary' does not exist on type '... Remove this comment to see the full error message
    day: state.answersSummary.day,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
    // @ts-expect-error TS(2339) FIXME: Property 'answersSummary' does not exist on type '... Remove this comment to see the full error message
    frequency: state.answersSummary.frequency,
    // @ts-expect-error TS(2339) FIXME: Property 'answersSummary' does not exist on type '... Remove this comment to see the full error message
    loading: state.answersSummary.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profile: state.profiles.selectedProfile,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'answersSummary' does not exist on type '... Remove this comment to see the full error message
    type: state.answersSummary.type,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
    save: (result) => dispatch(actions.save(result)),
  }),
)(AnswersSummary)

export { default as reducer } from './reducer'
export { default as middleware } from './middleware'
export { default as Table } from './components/Table'
export { default as Title } from './components/Title'
