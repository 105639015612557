import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'

const Container = styled.div`
  display: flex;
  border-radius: 0;
  border: none;
  background: linear-gradient(
    90deg,
    rgba(249, 245, 255, 1) 0%,
    rgba(249, 245, 255, 1) 50%,
    rgba(249, 245, 255, 0) 100%
  );
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px 12px;
  flex-direction: row;
  position: relative;
  margin: 0 -16px 16px;

  h3 {
    color: #7647b7;
    margin: 0;
    padding: 0;
  }

  label {
    color: #a77ce3;
  }

  b {
    color: #7647b7;
  }
`

const IconHolder = styled.div`
  flex: 0;
  margin: 5px 12px 0 0;

  svg {
    color: #7647b7;
  }
`

const TextHolder = styled.div`
  flex: 1;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Suggestions = ({ children }) => (
  <Container>
    <IconHolder>
      <FlashIcon />
    </IconHolder>
    <TextHolder>{children}</TextHolder>
  </Container>
)

Suggestions.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Suggestions
