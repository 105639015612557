import React from 'react'
import { connect } from 'react-redux'
import store from '~/store'
import reducer, { actions } from './reducer'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import middleware from './middleware'
import Notice from '@bufferapp/ui/Notice'
import Text from '@bufferapp/ui/Text'
import Link from '@bufferapp/ui/Link'
import styled from 'styled-components'

const Container = styled.div`
  padding-bottom: 16px;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'cookieKey' implicitly has an 'any' type... Remove this comment to see the full error message
function setCookie(cookieKey, value) {
  document.cookie = `${cookieKey}=${value};`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'cookie' implicitly has an 'any' type.
export function hasDismissedCookie(cookie, cookieKey) {
  const match = cookie.match(new RegExp(`${cookieKey}=dismissed;*`))
  if (match) {
    return true
  }
  return false
}

class BoostedPostsNotice extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    if (hasDismissedCookie(document.cookie, 'boosted-posts-notice')) {
      this.state = {
        dismissed: true,
      }
    } else {
      setCookie('boosted-posts-notice', 'active')
      this.state = {
        dismissed: false,
      }
    }
  }

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'service' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.service == 'facebook' || this.props.service == 'instagram') {
      // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.fetch()
    }
  }

  dismiss() {
    this.setState({ dismissed: true })
    setCookie('boosted-posts-notice', 'dismissed')
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'adsEnabled' does not exist on type 'Read... Remove this comment to see the full error message
    const { adsEnabled, loading, hasError, service } = this.props
    // @ts-expect-error TS(2339) FIXME: Property 'dismissed' does not exist on type 'Reado... Remove this comment to see the full error message
    const { dismissed } = this.state
    const allowedServices = service === 'facebook' || service === 'instagram'
    if (loading || hasError || !allowedServices) {
      return null
    }
    return (
      <React.Fragment>
        {!adsEnabled && !dismissed && (
          <Container>
            {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ children: ... Remove this comment to see the full error message */}
            <Notice type="warning" dismiss={() => this.dismiss()}>
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
              <Text>
                If you would like to see insights for your boosted posts,{' '}
                <Link newTab href="https://account.buffer.com/channels">
                  refresh this channel
                </Link>{' '}
                and allow Buffer to access your ad data.
              </Text>
            </Notice>
          </Container>
        )}
      </React.Fragment>
    )
  }
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'boostedPostsNotice' does not exist on ty... Remove this comment to see the full error message
    loading: state.boostedPostsNotice.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'boostedPostsNotice' does not exist on ty... Remove this comment to see the full error message
    hasError: state.boostedPostsNotice.hasError,
    // @ts-expect-error TS(2339) FIXME: Property 'boostedPostsNotice' does not exist on ty... Remove this comment to see the full error message
    adsEnabled: state.boostedPostsNotice.adsEnabled,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    service: state.profiles.selectedProfile.service,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
  }),
)(BoostedPostsNotice)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('boostedPostsNotice', reducer)
addMiddleware(middleware)
