import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import PostOrderSwitch from './components/PostOrderSwitch'
import Searchbox from '../Searchbox/index'
import Select from '@bufferapp/ui/Select'
import Text from '@bufferapp/ui/Text'
import ChevronDown from '@bufferapp/ui/Icon/Icons/ChevronDown'
import { white, grayDark, grayDarker } from '@bufferapp/ui/style/colors'
import { fontFamily, lineHeight } from '@bufferapp/ui/style/fonts'

const Container = styled.div`
  padding: 12px 16px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const AlignLabel = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'middle' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.middle &&
    css`
      min-width: 140px;
      margin: 0 16px;
    `}

  &:first-child {
    flex: 1;
  }

  label {
    margin-bottom: 8px;
  }
`

const ButtonContainer = styled.div`
  height: 40px;
  background: ${white};
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  width: 100%;

  :hover {
    cursor: pointer;
    border-color: ${grayDark};
  }
`

const IconContainer = styled.span`
  height: 100%;
  padding: 12px 12px 12px 0;

  svg {
    position: absolute;
    right: 16px;
  }
`

const Title = styled.span`
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: ${lineHeight};
  color: #596269;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;

  ${ButtonContainer}:hover & {
    color: ${grayDarker};
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
const renderButton = (onButtonClick, label) => {
  return (
    <ButtonContainer onClick={onButtonClick}>
      <Title>{label}</Title>
      <IconContainer>
        <ChevronDown />
      </IconContainer>
    </ButtonContainer>
  )
}

const TopPostsHeader = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
  metrics,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedMetric' implicitly has an... Remove this comment to see the full error message
  selectedMetric,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDescendingSelected' implicitly ... Remove this comment to see the full error message
  isDescendingSelected,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectMetric' implicitly has an '... Remove this comment to see the full error message
  selectMetric,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handlePostsSortClick' implicitly ... Remove this comment to see the full error message
  handlePostsSortClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'search' implicitly has an 'any' t... Remove this comment to see the full error message
  search,
  // @ts-expect-error TS(7031) FIXME: Binding element 'searchTerms' implicitly has an 'a... Remove this comment to see the full error message
  searchTerms,
}) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  const expandedMetrics = metrics.map((metric, index) => {
    return {
      id: index,
      title: metric.label,
      onItemClick: () => selectMetric({ metric }),
      selected: selectedMetric.label === metric.label,
      apiKey: metric.apiKey,
    }
  })

  return (
    <Container>
      <AlignLabel>
        <Text htmlFor="" type="label">
          Filter by text
        </Text>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ search: any; searchTerms: any; }' is not a... Remove this comment to see the full error message */}
        <Searchbox search={search} searchTerms={searchTerms} />
      </AlignLabel>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <AlignLabel middle>
        <Text htmlFor="" type="label">
          Sort by
        </Text>
        <Select
          // @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
          customButton={(onButtonClick) =>
            renderButton(onButtonClick, selectedMetric.label)
          }
          items={expandedMetrics}
          hideSearch
          // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
          onSelectClick={(selectedItem) => selectedItem.onItemClick()}
          label={selectedMetric.label}
          fullWidth
        />
      </AlignLabel>
      <PostOrderSwitch
        handleClick={handlePostsSortClick}
        isDescendingSelected={isDescendingSelected}
      />
    </Container>
  )
}
TopPostsHeader.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedMetric: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  isDescendingSelected: PropTypes.bool.isRequired,
  selectMetric: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  handlePostsCountClick: PropTypes.func.isRequired,
  handlePostsSortClick: PropTypes.func.isRequired,
  activePostsCount: PropTypes.number.isRequired,
  postsCounts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
}

TopPostsHeader.defaultProps = {
  postsCounts: undefined,
}

export default TopPostsHeader
