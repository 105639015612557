import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'

const Container = styled.span`
  display: block;
  margin: 0 0 0.2rem;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'tooltip' implicitly has an 'any' ... Remove this comment to see the full error message
const Label = ({ tooltip, children }) => (
  <Container>
    <span data-tip={tooltip}>
      <Text type="label" color="grayDark">
        {children}
      </Text>
    </span>
  </Container>
)

Label.defaultProps = {
  tooltip: null,
}

Label.propTypes = {
  tooltip: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Label
