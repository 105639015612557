import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  GridItem,
  Container,
} from '~/shared-components'
import GraphQLWrapper, {
  formatQueryVariables,
} from '~/shared-components/GraphQLWrapper'
import QUERY, { dataParser } from '../../query'
import AddReport from '~/add-report'
import Title from '../Title'

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 0;
  margin: 0 auto;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Grid = (props) => {
  return (
    <List>
      {props.metrics &&
        // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
        props.metrics.map((metric, index) => {
          const itemProps = {
            key: metric.label,
            metric,
            gridWidth: '33.33%',
            showPercentSign: metric.label === 'Engagement Rate',
          }
          if (props.metrics.length === 5) itemProps.gridWidth = '20%'
          if (props.metrics.length === 7) itemProps.gridWidth = '25%'
          return <GridItem {...itemProps} inline={index > 2} /> // this inline is so the first row has a bottom margin but the second won't
        })}
    </List>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const Table = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.metrics && <Grid {...props} />}
      {!props.metrics && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            save: props.save,
            variables,
            content: Grid,
            title: 'Post Summary',
          }}
        />
      )}
    </React.Fragment>
  )
}

Table.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      diff: PropTypes.number,
      paid: PropTypes.number,
    }),
  ),
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const Content = ({ metrics, loading, profile, save }) => {
  let content = null

  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else if (metrics.length === 0) {
    content = <NoData chartName="posts-summary" />
  } else {
    // @ts-expect-error TS(2322) FIXME: Type '{ metrics: any; profile: any; save: any; }' ... Remove this comment to see the full error message
    content = <Table metrics={metrics} profile={profile.service} save={save} />
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title profileService={profile.service} />
        <AddReport chart="posts-summary" />
      </ChartHeader>
      <Container id="js-dom-to-png-posts-summary">{content}</Container>
    </ChartCard>
  )
}

Content.defaultProps = {
  loading: false,
}

Content.propTypes = {
  loading: PropTypes.bool,
  profile: PropTypes.shape({}).isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      diff: PropTypes.number,
      paid: PropTypes.number,
    }),
  ).isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const PostsSummaryTable = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.profile.service !== 'linkedin' && <Content {...props} />}
      {props.profile.service === 'linkedin' && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            save: props.save,
            variables,
            content: Content,
            title: 'Post Summary',
          }}
        />
      )}
    </React.Fragment>
  )
}

export default PostsSummaryTable
