import React from 'react'
import { ChartTitle } from '~/shared-components'

interface TitleProps {
  profileService: string
}

const Title = ({ profileService }: TitleProps): JSX.Element => (
  <ChartTitle>
    {profileService === 'twitter' ? 'Tweet ' : 'Post '}
    summary
  </ChartTitle>
)

export default Title
