import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled, { css, keyframes } from 'styled-components'
import Text from '@bufferapp/ui/Text'
import helpers from './helperDictionary'

const TOOLTIP_MAXWIDTH = 550
const TOOLTIP_OFFSET_X_LEFT = 20
const TOOLTIP_OFFSET_X_RIGHT = 30
const TOOLTIP_OFFSET_Y_TOP = 10
const TOOLTIP_OFFSET_Y_BOTTOM = 20
const TOOLTIP_CLIPPING_BUFFER = 10

const animationTooltip = keyframes`
  0% { opacity: 0; }
  25% { opacity: 0; }
  100% { opacity: 1; }
`

const Container = styled.div`
  display: inline-block;
`

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    cursor: pointer;
  }
`

const Tooltip = styled.div`
  display: none;
  opacity: 0;
  position: absolute;
  top: auto; /* calculated later */
  left: auto; /* calculated later */
  background: rgba(0, 0, 0, 0.8);
  padding: 6px;
  border-radius: 3px;
  z-index: 9999999;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'show' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.show &&
    css`
      display: block;
      animation: ${animationTooltip} 300ms ease-out;
      opacity: 1;
    `}

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'wrap' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.wrap &&
    css`
      width: ${TOOLTIP_MAXWIDTH}px;

      span {
        white-space: normal;
      }
    `}

  span {
    font-weight: 400;
  }

  span b {
    font-weight: 800 !important;
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function normalizeLabel(label) {
  // this is to ensure we strip any erronous capital letters from label
  return label.toLowerCase()
}

class Helper extends Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      left: 0,
      top: 0,
      wrap: false,
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'mouseEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
  showTooltip = (mouseEvent) => {
    const position = this.getTooltipPositions(mouseEvent)
    const left = position.x
    const top = position.y
    const wrap = this.shouldWrap()

    this.setState({ show: true, left, top, wrap })

    mouseEvent.stopPropagation()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'mouseEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
  hideTooltip = (mouseEvent) => {
    this.setState({ show: false, left: 0, top: 0, wrap: false })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'mouseEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
  getTooltipPositions = (mouseEvent) => {
    const bounder = mouseEvent.target
      .closest('[data-helper]')
      .getBoundingClientRect() // find the helper parent

    let offset = TOOLTIP_OFFSET_X_LEFT
    const clipping = this.isTooltipClippingX()
    if (clipping) offset = this.getTooltipWidth() - TOOLTIP_OFFSET_X_RIGHT // sensible offset

    const bounderX = bounder.left
    const mouseX = mouseEvent.clientX

    let positionX = mouseX - bounderX
    if (positionX < 0) positionX = 0
    positionX = positionX - offset

    const isClippingAbove = this.isTooltipClippingY(mouseEvent)

    const bounderY = bounder.top
    const mouseY = mouseEvent.clientY

    let positionY = mouseY - bounderY
    if (positionY < 0) positionY = 0
    if (isClippingAbove) positionY = positionY + TOOLTIP_OFFSET_Y_BOTTOM
    else positionY = positionY - this.getTooltipHeight() - TOOLTIP_OFFSET_Y_TOP

    return {
      x: positionX,
      y: positionY,
    }
  }

  getTooltipWidth = () => {
    // get the tooltip width so we can later workout offset if aligning right
    // @ts-expect-error TS(2339) FIXME: Property 'tooltipEl' does not exist on type 'Helpe... Remove this comment to see the full error message
    const tooltipEl = ReactDOM.findDOMNode(this.tooltipEl)
    if (tooltipEl) {
      // @ts-expect-error TS(2339) FIXME: Property 'getBoundingClientRect' does not exist on... Remove this comment to see the full error message
      const tooltipRect = tooltipEl.getBoundingClientRect()
      return tooltipRect.width
    }
    return false
  }

  getTooltipHeight = () => {
    // get the tooltip height so we can later workout if tooltip is going too high
    // @ts-expect-error TS(2339) FIXME: Property 'tooltipEl' does not exist on type 'Helpe... Remove this comment to see the full error message
    const tooltipEl = ReactDOM.findDOMNode(this.tooltipEl)
    if (tooltipEl) {
      // @ts-expect-error TS(2339) FIXME: Property 'getBoundingClientRect' does not exist on... Remove this comment to see the full error message
      const tooltipRect = tooltipEl.getBoundingClientRect()
      return tooltipRect.height
    }
    return false
  }

  shouldWrap = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'tooltipEl' does not exist on type 'Helpe... Remove this comment to see the full error message
    const tooltipEl = ReactDOM.findDOMNode(this.tooltipEl)
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const tooltipRect = tooltipEl.getBoundingClientRect()
    return tooltipRect.width > TOOLTIP_MAXWIDTH
  }

  isTooltipClippingX = () => {
    // this is required to find boundary so to left or right align tooltip to avoid cutoff
    // @ts-expect-error TS(2339) FIXME: Property 'tooltipEl' does not exist on type 'Helpe... Remove this comment to see the full error message
    const tooltipEl = ReactDOM.findDOMNode(this.tooltipEl)
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const boundaryEl = tooltipEl.closest('[data-chart]') //  // find the chart parent
    if (boundaryEl) {
      // @ts-expect-error TS(2339) FIXME: Property 'iconEl' does not exist on type 'Helper'.
      const iconEl = ReactDOM.findDOMNode(this.iconEl)
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const iconRect = iconEl.getBoundingClientRect()
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const tooltipRect = tooltipEl.getBoundingClientRect()
      const boundaryRect = boundaryEl.getBoundingClientRect()
      const toolbarMaxPoint = iconRect.left + tooltipRect.width
      const boundaryMaxPoint = boundaryRect.left + boundaryRect.width
      if (toolbarMaxPoint + TOOLTIP_CLIPPING_BUFFER > boundaryMaxPoint) {
        return true
      }
    }
    return false
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'mouseEvent' implicitly has an 'any' typ... Remove this comment to see the full error message
  isTooltipClippingY = (mouseEvent) => {
    const targetEl = ReactDOM.findDOMNode(mouseEvent.target)
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const pageEl = ReactDOM.findDOMNode(this.tooltipEl).closest(
      '[data-chart]',
    ).parentNode // find the chart parent, page is the parent of that

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const targetRect = targetEl.getBoundingClientRect()
    const pageRect = pageEl.getBoundingClientRect()

    if (
      targetRect.top -
        this.getTooltipHeight() -
        TOOLTIP_OFFSET_Y_TOP -
        TOOLTIP_CLIPPING_BUFFER <
      pageRect.top
    ) {
      return true
    }
    return false
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { children, label, text } = this.props
    // @ts-expect-error TS(2339) FIXME: Property 'show' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { show, left, top, wrap } = this.state
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const content = label ? helpers[normalizeLabel(label)] : text

    // we return the children only if helper has no content to show
    if (!content) return <span>{children}</span>

    // dangerouslySetInnerHTML is used for basic formatting within the tooltip
    return (
      // @ts-expect-error TS(2339) FIXME: Property 'rootEl' does not exist on type 'Helper'.
      <Container ref={(element) => (this.rootEl = element)}>
        <Inner
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          tabIndex="0"
          // @ts-expect-error TS(2339) FIXME: Property 'iconEl' does not exist on type 'Helper'.
          ref={(element) => (this.iconEl = element)}
          onMouseEnter={this.showTooltip}
          onMouseMove={this.showTooltip}
          onMouseLeave={this.hideTooltip}
          onFocus={this.showTooltip}
          onBlur={this.hideTooltip}
          data-helper
        >
          {children}
          <Tooltip
            // @ts-expect-error TS(2339) FIXME: Property 'tooltipEl' does not exist on type 'Helpe... Remove this comment to see the full error message
            ref={(element) => (this.tooltipEl = element)}
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            show={show}
            style={{ left, top }}
            wrap={wrap}
            onMouseEnter={this.hideTooltip}
          >
            <Text type="label" color="white" as="span">
              <span dangerouslySetInnerHTML={{ __html: content }} />
            </Text>
          </Tooltip>
        </Inner>
      </Container>
    )
  }
}

export default Helper
