import JSZip from 'jszip'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'file... Remove this comment to see the full error message
import FileSaver from 'file-saver'

// @ts-expect-error TS(7006) FIXME: Parameter 'filename' implicitly has an 'any' type.
export default (filename, files) => {
  const zip = new JSZip()
  const zipFolder = zip.folder(`${filename}-images`)
  // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
  files.forEach((file) => zipFolder.file(file.filename, file.image))
  return zip
    .generateAsync({
      type: 'blob',
    })
    .then((content) => FileSaver.saveAs(content, `${filename}.zip`))
}
