export const instagram = {}

// @ts-expect-error TS(2339) FIXME: Property 'commentsMetric' does not exist on type '... Remove this comment to see the full error message
instagram.commentsMetric = {
  label: 'Comments',
  key: 'comments',
  apiKey: 'comments',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'dateMetric' does not exist on type '{}'.
instagram.dateMetric = {
  label: 'Date',
  key: 'date',
  apiKey: 'sent_at',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
instagram.likesMetric = {
  label: 'Likes',
  key: 'likes',
  apiKey: 'likes',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
instagram.engagementRateMetric = {
  label: 'Eng. Rate',
  key: 'engagement_rate',
  apiKey: 'engagement_rate',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
instagram.impressionsMetric = {
  label: 'Impressions',
  key: 'impressions',
  apiKey: 'impressions',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'reachMetric' does not exist on type '{}'... Remove this comment to see the full error message
instagram.reachMetric = {
  label: 'Reach',
  key: 'reach',
  apiKey: 'reach',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'spendMetric' does not exist on type '{}'... Remove this comment to see the full error message
instagram.spendMetric = {
  label: 'Spend',
  key: 'spend',
  apiKey: 'spend',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'postMetrics' does not exist on type '{}'... Remove this comment to see the full error message
instagram.postMetrics = [
  // @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
  instagram.likesMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'commentsMetric' does not exist on type '... Remove this comment to see the full error message
  instagram.commentsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
  instagram.impressionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'reachMetric' does not exist on type '{}'... Remove this comment to see the full error message
  instagram.reachMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
  instagram.engagementRateMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'spendMetric' does not exist on type '{}'... Remove this comment to see the full error message
  instagram.spendMetric,
]

// @ts-expect-error TS(2339) FIXME: Property 'sortMetrics' does not exist on type '{}'... Remove this comment to see the full error message
instagram.sortMetrics = [
  // @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
  instagram.likesMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'commentsMetric' does not exist on type '... Remove this comment to see the full error message
  instagram.commentsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
  instagram.impressionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'reachMetric' does not exist on type '{}'... Remove this comment to see the full error message
  instagram.reachMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
  instagram.engagementRateMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'dateMetric' does not exist on type '{}'.
  instagram.dateMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'spendMetric' does not exist on type '{}'... Remove this comment to see the full error message
  instagram.spendMetric,
]

export const twitter = {}

// @ts-expect-error TS(2339) FIXME: Property 'dateMetric' does not exist on type '{}'.
twitter.dateMetric = {
  label: 'Date',
  key: 'date',
  apiKey: 'sent_at',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'retweetsMetric' does not exist on type '... Remove this comment to see the full error message
twitter.retweetsMetric = {
  label: 'Retweets',
  key: 'retweets',
  apiKey: 'retweets',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'clicksMetric' does not exist on type '{}... Remove this comment to see the full error message
twitter.clicksMetric = {
  label: 'Clicks',
  key: 'url_clicks',
  apiKey: 'url_clicks',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
twitter.impressionsMetric = {
  label: 'Impressions',
  key: 'impressions',
  apiKey: 'impressions',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
twitter.likesMetric = {
  label: 'Likes',
  key: 'favorites',
  apiKey: 'favorites',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
twitter.engagementRateMetric = {
  label: 'Eng. Rate',
  key: 'engagement_rate',
  apiKey: 'engagement_rate',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'postMetrics' does not exist on type '{}'... Remove this comment to see the full error message
twitter.postMetrics = [
  // @ts-expect-error TS(2339) FIXME: Property 'clicksMetric' does not exist on type '{}... Remove this comment to see the full error message
  twitter.clicksMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'retweetsMetric' does not exist on type '... Remove this comment to see the full error message
  twitter.retweetsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
  twitter.likesMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
  twitter.impressionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
  twitter.engagementRateMetric,
]

// @ts-expect-error TS(2339) FIXME: Property 'sortMetrics' does not exist on type '{}'... Remove this comment to see the full error message
twitter.sortMetrics = [
  // @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
  twitter.impressionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
  twitter.engagementRateMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'clicksMetric' does not exist on type '{}... Remove this comment to see the full error message
  twitter.clicksMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'retweetsMetric' does not exist on type '... Remove this comment to see the full error message
  twitter.retweetsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
  twitter.likesMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'dateMetric' does not exist on type '{}'.
  twitter.dateMetric,
]

// Facebook Metrics Configuration
export const facebook = {}

// @ts-expect-error TS(2339) FIXME: Property 'dateMetric' does not exist on type '{}'.
facebook.dateMetric = {
  label: 'Date',
  key: 'date',
  apiKey: 'sent_at',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'commentsMetric' does not exist on type '... Remove this comment to see the full error message
facebook.commentsMetric = {
  label: 'Comments',
  key: 'comments',
  apiKey: 'comments',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'postClicksMetric' does not exist on type... Remove this comment to see the full error message
facebook.postClicksMetric = {
  label: 'Post Clicks',
  key: 'clicks',
  apiKey: 'clicks',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
facebook.impressionsMetric = {
  label: 'Impressions',
  key: 'impressions',
  apiKey: 'impressions',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'postReachMetric' does not exist on type ... Remove this comment to see the full error message
facebook.postReachMetric = {
  label: 'Reach',
  key: 'reach',
  apiKey: 'reach',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'reactionsMetric' does not exist on type ... Remove this comment to see the full error message
facebook.reactionsMetric = {
  label: 'Reactions',
  key: 'reactions',
  apiKey: 'reactions',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'sharesMetric' does not exist on type '{}... Remove this comment to see the full error message
facebook.sharesMetric = {
  label: 'Shares',
  key: 'shares',
  apiKey: 'shares',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
facebook.engagementRateMetric = {
  label: 'Eng. Rate',
  key: 'engagement_rate',
  apiKey: 'engagement_rate',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'spendMetric' does not exist on type '{}'... Remove this comment to see the full error message
facebook.spendMetric = {
  label: 'Spend',
  key: 'spend',
  apiKey: 'spend',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'postMetrics' does not exist on type '{}'... Remove this comment to see the full error message
facebook.postMetrics = [
  // @ts-expect-error TS(2339) FIXME: Property 'postClicksMetric' does not exist on type... Remove this comment to see the full error message
  facebook.postClicksMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'reactionsMetric' does not exist on type ... Remove this comment to see the full error message
  facebook.reactionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
  facebook.impressionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'postReachMetric' does not exist on type ... Remove this comment to see the full error message
  facebook.postReachMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
  facebook.engagementRateMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'spendMetric' does not exist on type '{}'... Remove this comment to see the full error message
  facebook.spendMetric,
]

// @ts-expect-error TS(2339) FIXME: Property 'sortMetrics' does not exist on type '{}'... Remove this comment to see the full error message
facebook.sortMetrics = [
  // @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
  facebook.impressionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'postReachMetric' does not exist on type ... Remove this comment to see the full error message
  facebook.postReachMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'postClicksMetric' does not exist on type... Remove this comment to see the full error message
  facebook.postClicksMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'reactionsMetric' does not exist on type ... Remove this comment to see the full error message
  facebook.reactionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
  facebook.engagementRateMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'dateMetric' does not exist on type '{}'.
  facebook.dateMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'spendMetric' does not exist on type '{}'... Remove this comment to see the full error message
  facebook.spendMetric,
]

export const linkedin = {}

// @ts-expect-error TS(2339) FIXME: Property 'commentsMetric' does not exist on type '... Remove this comment to see the full error message
linkedin.commentsMetric = {
  label: 'Comments',
  key: 'comments',
  apiKey: 'comments',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'clicksMetric' does not exist on type '{}... Remove this comment to see the full error message
linkedin.clicksMetric = {
  label: 'Clicks',
  key: 'clicks',
  apiKey: 'clicks',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
linkedin.impressionsMetric = {
  label: 'Impressions',
  key: 'impressions',
  apiKey: 'impressions',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
linkedin.likesMetric = {
  label: 'Likes',
  key: 'likes',
  apiKey: 'likes',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
linkedin.engagementRateMetric = {
  label: 'Eng. Rate',
  key: 'engagement_rate',
  apiKey: 'engagement_rate',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'dateMetric' does not exist on type '{}'.
linkedin.dateMetric = {
  label: 'Date',
  key: 'sent_at',
  apiKey: 'sent_at',
  value: 0,
}

// @ts-expect-error TS(2339) FIXME: Property 'postMetrics' does not exist on type '{}'... Remove this comment to see the full error message
linkedin.postMetrics = [
  // @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
  linkedin.impressionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
  linkedin.likesMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'commentsMetric' does not exist on type '... Remove this comment to see the full error message
  linkedin.commentsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'clicksMetric' does not exist on type '{}... Remove this comment to see the full error message
  linkedin.clicksMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
  linkedin.engagementRateMetric,
]

// @ts-expect-error TS(2339) FIXME: Property 'sortMetrics' does not exist on type '{}'... Remove this comment to see the full error message
linkedin.sortMetrics = [
  // @ts-expect-error TS(2339) FIXME: Property 'impressionsMetric' does not exist on typ... Remove this comment to see the full error message
  linkedin.impressionsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'likesMetric' does not exist on type '{}'... Remove this comment to see the full error message
  linkedin.likesMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'commentsMetric' does not exist on type '... Remove this comment to see the full error message
  linkedin.commentsMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'clicksMetric' does not exist on type '{}... Remove this comment to see the full error message
  linkedin.clicksMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'engagementRateMetric' does not exist on ... Remove this comment to see the full error message
  linkedin.engagementRateMetric,
  // @ts-expect-error TS(2339) FIXME: Property 'dateMetric' does not exist on type '{}'.
  linkedin.dateMetric,
]

// Exports
export const metricsConfig = {
  twitter,
  facebook,
  instagram,
  linkedin,
}
