export const CAMPAIGNS = 'campaigns'
export const SHARED_CHANNELS = 'sharedChannels'
export const GRANT_ANALYZE_ACCESS = 'grantAnalyzeAccess'

export const STORIES = 'stories'
export const LOGO_UPLOAD = 'logo upload'
export const EXPORT = 'export'
export const BEST_TIME = 'best_time'

export const PRO_PLAN = 'pro'
export const PREMIUM_PLAN = 'premium'

export const OB_FREE = 'free'
export const OB_TEAM_PLAN = 'team'
export const OB_ESSENTIALS_PLAN = 'essentials'
export const OB_AGENCY_PLAN = 'agency'

// FIXME: Use entitlements instead.
// This is not easy to maintain and prone to errors,
// as we change the entitlements or we introduce new plans.
export const PERMISSIONS_BY_PLAN = {
  [PRO_PLAN]: [EXPORT, BEST_TIME],
  [PREMIUM_PLAN]: [STORIES, LOGO_UPLOAD, EXPORT, BEST_TIME],
  [OB_TEAM_PLAN]: [STORIES, LOGO_UPLOAD, EXPORT, CAMPAIGNS, BEST_TIME],
  [OB_AGENCY_PLAN]: [STORIES, LOGO_UPLOAD, EXPORT, CAMPAIGNS, BEST_TIME],
  [OB_ESSENTIALS_PLAN]: [STORIES, LOGO_UPLOAD, EXPORT, CAMPAIGNS, BEST_TIME],
  [OB_FREE]: [],
}

const CAMPAIGNS_FLAG = 'campaignsRollOut'
const SHARED_CHANNELS_FLAG = 'sharedChannels'
export const PERMISSIONS_BY_FEATURE_FLAG = {
  [CAMPAIGNS_FLAG]: CAMPAIGNS,
  [SHARED_CHANNELS_FLAG]: SHARED_CHANNELS,
  [GRANT_ANALYZE_ACCESS]: [STORIES, LOGO_UPLOAD, EXPORT, BEST_TIME],
}

export const PERMISSIONS_TO_REMOVE_FOR_TRIALIST = [BEST_TIME]

export default {
  STORIES,
  LOGO_UPLOAD,
  CAMPAIGNS,
}
