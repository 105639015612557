import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Title from '../Title'
import { SelectorButton } from '../SelectorButton'
import { StackedChart } from '../StackedChart'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  PostsTimeline,
  CHANNEL_TYPES,
  ContentModal,
} from '~/shared-components'
import AddReport from '~/add-report'

const List = styled.ul`
  display: flex;
  margin: 0 0 8px 0;
  padding: 0;
  background: #ffffff;

  li:first-child {
    margin-left: 0;
  }
  li:last-child {
    margin-right: 0;
  }
`

const Wrapper = styled.div`
  padding: 0 16px 24px;
`

export class Chart extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'selectedMetric' implicitly has an 'any'... Remove this comment to see the full error message
  uglifySelectedMetric(selectedMetric) {
    switch (selectedMetric) {
      case 'Impressions':
        return 'impressions'
      case 'Comments':
        return 'comments'
      case 'Engagements':
        return 'engagement'
      case 'Total Followers':
        return 'followers'
      case 'Avg. Engagement Rate':
        return 'average_engagement_rate'
    }
  }

  sortTotals() {
    // @ts-expect-error TS(2339) FIXME: Property 'totals' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { totals } = this.props

    const guide = [
      { key: 'followers', label: 'Total Followers', value: '' },
      { key: 'impressions', label: 'Impressions', value: '' },
      { key: 'engagement', label: 'Engagements', value: '' },
      { key: 'comments', label: 'Comments', value: '' },
      {
        key: 'average_engagement_rate',
        label: 'Avg. Engagement Rate',
        value: '',
      },
    ]

    // @ts-expect-error TS(7006) FIXME: Parameter 'metricTotals' implicitly has an 'any' t... Remove this comment to see the full error message
    totals.map((metricTotals) => {
      const indexInGuide = guide.findIndex(
        (metricGuide) => metricGuide.key === metricTotals.label,
      )
      guide[indexInGuide].value = metricTotals.value
    })

    return guide
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'selectedMetric' does not exist on type '... Remove this comment to see the full error message
    const { selectedMetric, dailyMetrics, selectNewMetric, forReport } =
      this.props

    const sortedTotals = this.sortTotals()

    return (
      <React.Fragment>
        <List>
          {sortedTotals.map((metric) => (
            <SelectorButton
              metric={metric}
              key={metric.label}
              selected={metric.label === selectedMetric}
              selectNewMetric={selectNewMetric}
              forReport={forReport}
            />
          ))}
        </List>
        <StackedChart
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          type={
            this.uglifySelectedMetric(selectedMetric) === 'followers'
              ? 'areaspline'
              : 'column'
          }
          selectedMetric={this.uglifySelectedMetric(selectedMetric)}
          dailyData={dailyMetrics}
        />
      </React.Fragment>
    )
  }
}

class CampaignPostsMetrics extends React.PureComponent {
  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'totals' does not exist on type 'Readonly... Remove this comment to see the full error message
      totals,
      // @ts-expect-error TS(2339) FIXME: Property 'dailyMetrics' does not exist on type 'Re... Remove this comment to see the full error message
      dailyMetrics,
      // @ts-expect-error TS(2339) FIXME: Property 'dailyPosts' does not exist on type 'Read... Remove this comment to see the full error message
      dailyPosts,
      // @ts-expect-error TS(2339) FIXME: Property 'selectedMetric' does not exist on type '... Remove this comment to see the full error message
      selectedMetric,
      // @ts-expect-error TS(2339) FIXME: Property 'selectNewMetric' does not exist on type ... Remove this comment to see the full error message
      selectNewMetric,
      // @ts-expect-error TS(2339) FIXME: Property 'postsForModal' does not exist on type 'R... Remove this comment to see the full error message
      postsForModal,
      // @ts-expect-error TS(2339) FIXME: Property 'openModal' does not exist on type 'Reado... Remove this comment to see the full error message
      openModal,
      // @ts-expect-error TS(2339) FIXME: Property 'closeModal' does not exist on type 'Read... Remove this comment to see the full error message
      closeModal,
      // @ts-expect-error TS(2339) FIXME: Property 'hasError' does not exist on type 'Readon... Remove this comment to see the full error message
      hasError,
      // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      loading,
      // @ts-expect-error TS(2339) FIXME: Property 'campaign' does not exist on type 'Readon... Remove this comment to see the full error message
      campaign,
      // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Reado... Remove this comment to see the full error message
      forReport,
    } = this.props

    let content = null

    if (hasError) {
      content = null
    } else if (loading) {
      // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
      content = <Loading active noBorder />
    } else if (totals.length == 0) {
      content = (
        <NoData
          chartName="campaign-posts-metrics"
          channelType={CHANNEL_TYPES.aggregates}
        />
      )
    } else {
      content = (
        <Wrapper>
          <div id="js-dom-to-png-campaign-posts-metrics">
            <Chart
              key="campaign-posts-metrics-chart"
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              totals={totals}
              selectedMetric={selectedMetric}
              dailyMetrics={dailyMetrics}
              selectNewMetric={selectNewMetric}
              forReport={forReport}
            />
          </div>
          <PostsTimeline
            key="campaign-posts-metrics-timeline"
            // @ts-expect-error TS(2322) FIXME: Type '{ key: string; postInfo: any; selectedMetric... Remove this comment to see the full error message
            postInfo={dailyPosts}
            selectedMetric={selectedMetric}
            // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
            tooltipAction={(day) => {
              openModal(day)
            }}
          />
          {postsForModal && (
            <ContentModal posts={postsForModal.posts} closeModal={closeModal} />
          )}
        </Wrapper>
      )
    }
    return (
      <ChartCard hasError={hasError}>
        <ChartHeader>
          <Title />
          <AddReport
            chart="campaign-posts-metrics"
            state={{
              selectedMetric,
              campaignId: campaign.id,
            }}
          />
        </ChartHeader>
        {content}
      </ChartCard>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
CampaignPostsMetrics.defaultProps = {
  totals: [],
  dailyMetrics: [],
  dailyPosts: [],
  selectedMetric: '',
  postsForModal: null,
  campaign: {},
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
CampaignPostsMetrics.propTypes = {
  totals: PropTypes.array,
  dailyMetrics: PropTypes.array,
  dailyPosts: PropTypes.array,
  selectedMetric: PropTypes.string,
  postsForModal: PropTypes.array,
  hasError: PropTypes.bool,
  loading: PropTypes.bool,
  campaign: PropTypes.shape({}),
}

export default CampaignPostsMetrics
