import { connect } from 'react-redux'
import AudienceCountriesChart from './components/AudienceCountriesChart'
import { actions } from './reducer'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'demographic' does not exist on type 'Def... Remove this comment to see the full error message
    loading: state.demographic.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'demographic' does not exist on type 'Def... Remove this comment to see the full error message
    metrics: state.demographic.metrics,
    // @ts-expect-error TS(2339) FIXME: Property 'demographicCountries' does not exist on ... Remove this comment to see the full error message
    selectedGroup: state.demographicCountries.selectedGroup,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
    selectMetricsGroup: (key) => dispatch(actions.selectMetricsGroup(key)),
  }),
)(AudienceCountriesChart)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { default as Chart } from './components/Chart'
export { default as Title } from './components/Title'
