import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from '@bufferapp/ui/Select'
import { Can, Cant, EXPORT } from '~/account'
import { Button } from '~/shared-components'
import UpgradeToPro from '~/upgrade-to-pro'

// @ts-expect-error TS(7031) FIXME: Binding element 'exporting' implicitly has an 'any... Remove this comment to see the full error message
function ExportPicker({ exporting, exportToCSV, exportToPNG, filename }) {
  const [showUpgrade, setShowUpgrade] = useState(false)

  const exportOptions = [
    {
      id: 'csv',
      title: 'Export as CSV',
      onItemClick: () => exportToCSV(filename),
    },
    {
      id: 'png',
      title: 'Export as Images',
      onItemClick: () => exportToPNG(filename),
    },
  ]

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2745) FIXME: This JSX tag's 'children' prop expects type 'never... Remove this comment to see the full error message */}
      <Can {...{ EXPORT }}>
        <Select
          // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
          onSelectClick={(selectedItem) => selectedItem.onItemClick()}
          label={exporting ? 'Exporting...' : 'Export as...'}
          items={exportOptions}
          disabled={exporting}
          hideSearch
          xPosition="right"
        />
      </Can>
      {/* @ts-expect-error TS(2745) FIXME: This JSX tag's 'children' prop expects type 'never... Remove this comment to see the full error message */}
      <Cant {...{ EXPORT }}>
        <Button
          onClick={() => {
            setShowUpgrade(true)
          }}
        >
          Export as...
        </Button>
      </Cant>
      {showUpgrade && (
        <UpgradeToPro
          hide={() => {
            setShowUpgrade(false)
          }}
          title="Unlock Charts Export"
          description="Upgrade to get the most out of Buffers Analytics, and unlock PNG and CSV charts export!"
          cta="unlockChartsExport-exportPicker-upgrade-1"
        />
      )}
    </React.Fragment>
  )
}

ExportPicker.propTypes = {
  exporting: PropTypes.bool.isRequired,
  filename: PropTypes.node.isRequired,
}

export default ExportPicker
