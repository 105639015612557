import React from 'react'
import PropTypes from 'prop-types'
import { GridItem } from '~/shared-components'

import { MetricsContainer } from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const Metrics = ({ metrics, wide }) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  const metricsList = metrics.map((metric) => (
    <GridItem
      metric={metric}
      hideDiff
      key={`${metric.label}_${metric.value}_metric`}
      showPercentSign={metric.label === 'Conversions'}
      inline
    />
  ))

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  return <MetricsContainer wide={wide}>{metricsList}</MetricsContainer>
}

Metrics.defaultProps = {
  metrics: [],
  wide: false,
}

Metrics.propTypes = {
  metrics: PropTypes.arrayOf,
  // @ts-expect-error TS(2339) FIXME: Property 'boolean' does not exist on type 'typeof ... Remove this comment to see the full error message
  wide: PropTypes.boolean,
}

export default Metrics
