import PropTypes from 'prop-types'
import React from 'react'
import Text from '@bufferapp/ui/Text'
import styled from 'styled-components'
import { TruncatedNumber, CircleIcon } from '~/shared-components'
import { blueLight, blueDarker, white, gray } from '@bufferapp/ui/style/colors'
import OrganicIcon from '@bufferapp/ui/Icon/Icons/Organic'
import PromotedIcon from '@bufferapp/ui/Icon/Icons/Promoted'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function isEngagementRateMetric(label) {
  return Boolean(label.match(/^(engagement rate)$/i))
}

const organicColor = blueLight
const previousPeriodColor = blueDarker
const paidColor = '#00C8CF'
const previousPeriodPaidColor = '#008387'

const Date = styled.span`
  display: block;
  font-size: 12px;
  color: ${gray};

  &:before {
    content: '';
    display: ${({
      // @ts-expect-error TS(2339) FIXME: Property 'isPreviousPeriod' does not exist on type... Remove this comment to see the full error message
      isPreviousPeriod,
    }) => (isPreviousPeriod ? 'block' : 'none')};
    width: 80px;
    height: 2px;
    margin: 0px 0 8px 0;
    background: ${white};
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'day' implicitly has an 'any' type... Remove this comment to see the full error message
const Header = ({ day, isPreviousPeriod }) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <Date isPreviousPeriod={isPreviousPeriod}>
    {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
    <Text>
      {isPreviousPeriod ? 'Previous period of ' : ''}{' '}
      {dayjs.utc(day).format('D MMMM')}
    </Text>
  </Date>
)

const StyledIcon = styled.span`
  > div {
    margin-left: 0px;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'color' implicitly has an 'any' ty... Remove this comment to see the full error message
const Icon = ({ color, suffix }) => (
  <StyledIcon>
    <CircleIcon color={color}>
      {suffix === 'paid' ? <PromotedIcon /> : <OrganicIcon />}
    </CircleIcon>
  </StyledIcon>
)

Header.propTypes = {
  day: PropTypes.number.isRequired,
}

Header.defaultProps = {
  visualizePreviousPeriod: false,
}

const StyledMetric = styled.span`
  display: flex;
  font-size: ${({
    // @ts-expect-error TS(2339) FIXME: Property 'hideIcon' does not exist on type 'Omit<D... Remove this comment to see the full error message
    hideIcon,
  }) => (hideIcon ? '16px' : '14px')};
  font-weight: bold;
  > span {
    margin-right: 4px;
  }
`

const Percentage = styled.span`
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'color' implicitly has an 'any' ty... Remove this comment to see the full error message
const Metric = ({ color, value, label, suffix, hideIcon, percentage }) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <StyledMetric hideIcon={hideIcon} color={color}>
    {!hideIcon && (
      <React.Fragment>
        <Icon color={color} suffix={suffix} />{' '}
      </React.Fragment>
    )}
    <TruncatedNumber showPercentSign={isEngagementRateMetric(label)}>
      {value}
    </TruncatedNumber>
    {percentage > 0 && (
      <Percentage>
        <TruncatedNumber showPercentSign>{percentage}</TruncatedNumber>
      </Percentage>
    )}
    {suffix}
  </StyledMetric>
)

Metric.propTypes = {
  value: PropTypes.number,
  percentage: PropTypes.number,
  color: PropTypes.string,
  label: PropTypes.string,
  hideIcon: PropTypes.bool,
  suffix: PropTypes.string,
}

Metric.defaultProps = {
  value: null,
  percentage: null,
  color: white,
  label: '',
  hideIcon: false,
  suffix: '',
}

const StyledToolip = styled.div`
  max-width: 200px;
  padding: 8px;
  color: #fff;
  cursor: default;
  font-family: Roboto, sans serif;
  pointer-events: none;
  display: grid;
  grid-row-gap: 12px;
`

const PaidChartTooltip = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'day' implicitly has an 'any' type... Remove this comment to see the full error message
  day,
  // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error TS(7031) FIXME: Binding element 'previousPeriodDay' implicitly has... Remove this comment to see the full error message
  previousPeriodDay,
  // @ts-expect-error TS(7031) FIXME: Binding element 'previousValue' implicitly has an ... Remove this comment to see the full error message
  previousValue,
  // @ts-expect-error TS(7031) FIXME: Binding element 'visualizePreviousPeriod' implicit... Remove this comment to see the full error message
  visualizePreviousPeriod,
  // @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
  value,
  // @ts-expect-error TS(7031) FIXME: Binding element 'paid' implicitly has an 'any' typ... Remove this comment to see the full error message
  paid,
}) => (
  <StyledToolip>
    {/* @ts-expect-error TS(2741) FIXME: Property 'isPreviousPeriod' is missing in type '{ ... Remove this comment to see the full error message */}
    <Header day={day} />
    <Metric
      label={label}
      value={value + paid.value}
      color={white}
      suffix="total"
      hideIcon
    />
    <Metric
      value={value}
      label={label}
      color={organicColor}
      suffix={paid ? 'organic' : 'total'}
      percentage={paid ? (value / (value + paid.value)) * 100 : null}
    />
    {paid && (
      <Metric
        label={label}
        value={paid.value}
        color={paidColor}
        suffix="boosted"
        percentage={(paid.value / (value + paid.value)) * 100}
      />
    )}
    {visualizePreviousPeriod && (
      <React.Fragment>
        <Header day={previousPeriodDay} isPreviousPeriod />
        {paid && (
          <Metric
            label={label}
            value={previousValue + paid.previousValue}
            color={white}
            suffix="total"
            hideIcon
          />
        )}
        <Metric
          label={label}
          value={previousValue}
          // @ts-expect-error TS(2322) FIXME: Type '{ label: any; value: any; isPreviousPeriod: ... Remove this comment to see the full error message
          isPreviousPeriod
          color={previousPeriodColor}
          suffix={paid ? 'organic' : 'total'}
          percentage={
            paid
              ? (previousValue / (previousValue + paid.previousValue)) * 100
              : null
          }
        />
        {paid && (
          <Metric
            label={label}
            value={paid.previousValue}
            color={previousPeriodPaidColor}
            // @ts-expect-error TS(2322) FIXME: Type '{ label: any; value: any; color: string; isP... Remove this comment to see the full error message
            isPreviousPeriod
            suffix="boosted"
            percentage={
              (paid.previousValue / (previousValue + paid.previousValue)) * 100
            }
          />
        )}
      </React.Fragment>
    )}
  </StyledToolip>
)

PaidChartTooltip.propTypes = {
  day: PropTypes.number.isRequired,
  previousPeriodDay: PropTypes.number.isRequired,
  label: PropTypes.string,
  visualizePreviousPeriod: PropTypes.bool,
  previousValue: PropTypes.number,
  paid: PropTypes.shape,
  value: PropTypes.number,
}

PaidChartTooltip.defaultProps = {
  label: '',
  visualizePreviousPeriod: false,
  value: null,
  previousValue: null,
  paid: null,
}

const ChartTooltip = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'day' implicitly has an 'any' type... Remove this comment to see the full error message
  day,
  // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error TS(7031) FIXME: Binding element 'previousPeriodDay' implicitly has... Remove this comment to see the full error message
  previousPeriodDay,
  // @ts-expect-error TS(7031) FIXME: Binding element 'previousValue' implicitly has an ... Remove this comment to see the full error message
  previousValue,
  // @ts-expect-error TS(7031) FIXME: Binding element 'visualizePreviousPeriod' implicit... Remove this comment to see the full error message
  visualizePreviousPeriod,
  // @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
  value,
}) => (
  <StyledToolip>
    {/* @ts-expect-error TS(2741) FIXME: Property 'isPreviousPeriod' is missing in type '{ ... Remove this comment to see the full error message */}
    <Header day={day} />
    <Metric label={label} value={value} color={white} suffix="total" hideIcon />
    {visualizePreviousPeriod && (
      <React.Fragment>
        <Header day={previousPeriodDay} isPreviousPeriod />
        <Metric
          label={label}
          value={previousValue}
          color={white}
          // @ts-expect-error TS(2322) FIXME: Type '{ label: any; value: any; color: any; isPrev... Remove this comment to see the full error message
          isPreviousPeriod
          suffix="total"
          hideIcon
        />
      </React.Fragment>
    )}
  </StyledToolip>
)

ChartTooltip.propTypes = {
  day: PropTypes.number.isRequired,
  previousPeriodDay: PropTypes.number.isRequired,
  label: PropTypes.string,
  visualizePreviousPeriod: PropTypes.bool,
  previousValue: PropTypes.number,
  value: PropTypes.number,
}

ChartTooltip.defaultProps = {
  label: '',
  visualizePreviousPeriod: false,
  value: null,
  previousValue: null,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export default (props) => (
  <React.Fragment>
    {props.paid && <PaidChartTooltip {...props} />}
    {!props.paid && <ChartTooltip {...props} />}
  </React.Fragment>
)
