// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('PDF_EXPORT', {
  EXPORT_TO_PDF: 'EXPORT_TO_PDF',
})

export const actions = {
  exportToPDF: () => ({
    type: actionTypes.EXPORT_TO_PDF,
  }),
}
