import { gql } from '@apollo/client'
import { calculateDifference, snakeCase } from '~/shared-components/utils'

const LABELS = {
  posts_count: 'Posts',
  impressions: 'Impressions',
  likes: 'Likes',
  comments: 'Comments',
  shares: 'Shares',
  clicks: 'Clicks',
  engagement_rate: 'Engagement Rate',
}

const GET_POST_SUMMARY = gql`
  query AnalyticsPostsTotals(
    $serviceId: String!
    $serviceType: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    analyticsPostsTotals(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
      getPreviousPeriod: true
    ) {
      organic {
        postsCount
        clicks
        comments
        mentions
        engagements
        engagementRate
        impressions
        likes
        shares
      }
      previousOrganic {
        postsCount
        clicks
        comments
        mentions
        engagements
        engagementRate
        impressions
        likes
        shares
      }
    }
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const dataParser = (data) => {
  const organic = snakeCase(data.analyticsPostsTotals.organic)
  const previous_organic = snakeCase(data.analyticsPostsTotals.previousOrganic)
  // @ts-expect-error TS(7034) FIXME: Variable 'metrics' implicitly has type 'any[]' in ... Remove this comment to see the full error message
  const metrics = []

  Object.keys(LABELS).forEach((key) =>
    metrics.push({
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      value: organic[key],
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      diff: calculateDifference(organic[key], previous_organic[key]),
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      label: LABELS[key],
    }),
  )

  // @ts-expect-error TS(7005) FIXME: Variable 'metrics' implicitly has an 'any[]' type.
  return { metrics }
}

export default GET_POST_SUMMARY
