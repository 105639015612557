import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
`

const TypeIcon = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VideoIcon = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.52 4.16V1.92819C11.52 0.865872 10.6567 0 9.59181 0L1.92819 0C0.865872 0 0 0.86328 0 1.92819V9.59181C0 10.6541 0.86328 11.52 1.92819 11.52H9.59181C10.6541 11.52 11.52 10.6567 11.52 9.59181V7.36L13.8916 9.33634C14.6993 10.0094 15.36 9.70055 15.36 8.64037V2.87963C15.36 1.81194 14.7026 1.50785 13.8916 2.18366L11.52 4.16Z"
      fill="black"
    />
  </svg>
)

const CarouselIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.895433 0 2V9.52C0 10.6246 0.895433 11.52 2 11.52H10.52C11.0723 11.52 11.52 11.0723 11.52 10.52V2C11.52 0.895431 10.6246 0 9.52 0H2ZM5.23512 14.7536C4.25568 14.7536 3.44067 14.0496 3.26859 13.12H12.1193C12.6716 13.12 13.1193 12.6723 13.1193 12.12V3.26673C14.05 3.43797 14.7551 4.25347 14.7551 5.23365V12.7536C14.7551 13.8582 13.8597 14.7536 12.7551 14.7536H5.23512Z"
      fill="black"
    />
  </svg>
)

// @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
export default ({ type }) => (
  <Container>
    {type === 'video' && (
      <TypeIcon>
        <VideoIcon />
      </TypeIcon>
    )}
    {type === 'carousel_album' && (
      <TypeIcon>
        <CarouselIcon />
      </TypeIcon>
    )}
  </Container>
)
