import React from 'react'
import styled from 'styled-components'
import { blueLight, white } from '@bufferapp/ui/style/colors'
import { Day, Post, Frequency } from '@bufferapp/ui/Icon'

const IconContainer = styled.div`
  display: flex;
  width: 52px;
  height: 52px;
  background: ${blueLight};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;

  svg {
    fill: ${white};
  }
`

export const DayToPost = () => (
  <IconContainer>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 48 48"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Day size="small" />
    </svg>
  </IconContainer>
)

export const TypeOfPost = () => (
  <IconContainer>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 48 48"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Post size="small" />
    </svg>
  </IconContainer>
)

export const FrequencyOfPost = () => (
  <IconContainer>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 48 48"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Frequency size="small" />
    </svg>
  </IconContainer>
)
