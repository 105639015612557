import React from 'react'
import PropTypes from 'prop-types'
import Text from '@bufferapp/ui/Text'
import { ArrowDown, ArrowUp } from '@bufferapp/ui/Icon'
import Button from '@bufferapp/ui/Button'
import styled, { css } from 'styled-components'
import { AlignLabel } from '../../index'

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  list-style: none;
  border-radius: 4px;

  /* these give it the pill appearance */
  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'descending' does not exist on type 'Them... Remove this comment to see the full error message
    props.descending &&
    css`
      div {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    `}
  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'ascending' does not exist on type 'Theme... Remove this comment to see the full error message
    props.ascending &&
    css`
      div {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    `}

  div {
    background-color: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
      props.active ? '#F8F8F8' : 'initial'};
    width: 38px;
    box-sizing: content-box;

    button {
      padding: 0 8px;
      justify-content: center;

      &::hover {
        svg {
          fill: 'red';
        }
      }
    }
  }

  svg {
    fill: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
      props.active ? 'initial' : '#B8B8B8'};
  }

  :hover {
    svg {
      fill: initial;
    }
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'isDescendingSelected' implicitly ... Remove this comment to see the full error message
const PostOrderSwitch = ({ isDescendingSelected, handleClick }) => {
  return (
    <AlignLabel>
      <Text htmlFor="" type="label">
        Order
      </Text>
      <List>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <ListItem key="descending" descending active={isDescendingSelected}>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; icon... Remove this comment to see the full error message */}
          <Button
            type="secondary"
            icon={<ArrowDown />}
            hasIconOnly
            onClick={() => handleClick({ isDescendingSelected: true })}
            label="Sort descending"
          />
        </ListItem>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <ListItem key="ascending" ascending active={!isDescendingSelected}>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; icon... Remove this comment to see the full error message */}
          <Button
            type="secondary"
            icon={<ArrowUp />}
            hasIconOnly
            onClick={() => handleClick({ isDescendingSelected: false })}
            label="Sort ascending"
          />
        </ListItem>
      </List>
    </AlignLabel>
  )
}

PostOrderSwitch.propTypes = {
  isDescendingSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default PostOrderSwitch
