import React from 'react'
import PropTypes from 'prop-types'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'

const TruncatedNumber = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'absoluteValue' implicitly has an ... Remove this comment to see the full error message
  absoluteValue,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'roundDecimals' implicitly has an ... Remove this comment to see the full error message
  roundDecimals,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shorterOption' implicitly has an ... Remove this comment to see the full error message
  shorterOption,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showPercentSign' implicitly has a... Remove this comment to see the full error message
  showPercentSign,
}) => {
  let number = children

  if (number === '-') {
    return <span>-</span>
  }

  if (number < 0 && absoluteValue) {
    number *= -1
  }

  let formattedNumber = number

  if (roundDecimals) {
    formattedNumber = numeral(number).format('0')
  } else if (showPercentSign && number % 1 == 0) {
    formattedNumber = numeral(number).format('0')
  } else if (showPercentSign) {
    formattedNumber = numeral(number).format('0.00')
  } else if (number > 1000000) {
    formattedNumber = numeral(number).format('0.[00]a')
  } else if (shorterOption && number >= 100000) {
    formattedNumber = numeral(number).format('0a')
  } else if (number < 10 && number > 0 && showPercentSign) {
    formattedNumber = numeral(number).format('0.0')
  } else if (number < 1 && number > 0) {
    formattedNumber = numeral(number).format('0,0.0')
  } else {
    formattedNumber = numeral(number).format('0,0')
  }

  return (
    <span>
      {formattedNumber}
      {showPercentSign && '%'}
    </span>
  )
}

TruncatedNumber.defaultProps = {
  roundDecimals: false,
  absoluteValue: false,
  shorterOption: false,
  children: 0,
  showPercentSign: false,
}

TruncatedNumber.propTypes = {
  absoluteValue: PropTypes.bool,
  children: PropTypes.node,
  roundDecimals: PropTypes.bool,
  shorterOption: PropTypes.bool,
  showPercentSign: PropTypes.bool,
}

export default TruncatedNumber
