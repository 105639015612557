import React from 'react'
import PropTypes from 'prop-types'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  TotalsGrid,
  Container,
} from '~/shared-components'
import AddReport from '~/add-report'
import Title from '../Title'

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
function normalizeLabelsForChart(metrics) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  return metrics.map((metric) => ({
    ...metric,
    key: metric.label.toLowerCase(),
  }))
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
export const ShopifyTotalsGrid = ({ metrics }) => {
  return <TotalsGrid metrics={metrics} />
}

class ShopifyTotals extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.fetch()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    if (
      // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'Reado... Remove this comment to see the full error message
      prevProps.startDate !== this.props.startDate ||
      // @ts-expect-error TS(2339) FIXME: Property 'endDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      prevProps.endDate !== this.props.endDate
    ) {
      // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.fetch()
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'metrics' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { metrics, loading, hasError, profiles } = this.props

    let content = null

    if (hasError) {
      content = null
    } else if (loading) {
      // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
      content = <Loading active noBorder />
    } else if (metrics.length === 0 || metrics[0].value === null) {
      content = <NoData chartName="shopify-totals" />
    } else {
      content = <ShopifyTotalsGrid metrics={normalizeLabelsForChart(metrics)} />
    }
    return (
      <ChartCard hasError={hasError}>
        <ChartHeader>
          <Title />
          <AddReport chart="shopify-totals" />
        </ChartHeader>
        <Container id="js-dom-to-png-shopify-totals">{content}</Container>
      </ChartCard>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ShopifyTotals.defaultProps = {
  metrics: [],
  profiles: [],
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ShopifyTotals.propTypes = {
  metrics: PropTypes.array,
  profiles: PropTypes.array,
}

export default ShopifyTotals
