/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled, { css } from 'styled-components'

import Button from './Button'
import Dropdown from './Dropdown'
import Form from './Form'

const Container = styled.div`
  position: relative;
  width: 16rem;
`

const ClickCatcher = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'isOpen' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.isOpen &&
    css`
      display: block;
    `}
`

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const DatePicker = (props) => {
  const {
    isOpen,
    loading,
    startDate,
    endDate,
    presets,
    // Actions
    open,
    close,
  } = props

  return (
    <Container>
      <Button
        isOpen={isOpen}
        loading={loading}
        startDate={startDate}
        endDate={endDate}
        presets={presets}
        handleClick={() => (isOpen ? close() : open())}
      />
      <Dropdown isOpen={isOpen}>
        <Form {...props} />
      </Dropdown>

      <ClickCatcher
        isOpen={isOpen}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        tabIndex="0"
        role="button"
        onClick={close}
      />
    </Container>
  )
}

DatePicker.defaultProps = {
  customStartDate: -1,
  customEndDate: -1,
  customMonth: moment().startOf('month').unix(),
  startDate: null,
  endDate: null,
  loading: false,
}

DatePicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  startDate: PropTypes.number,
  endDate: PropTypes.number,

  // Actions
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default DatePicker
