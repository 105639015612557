import { connect } from 'react-redux'
import React from 'react'
import States from '@bufferapp/ui/States'
import { ChartStateLoading as Loading } from '~/shared-components'
import { isBackfilling } from '~/shared-components/utils'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  height: calc(100vh - 74px);
  background: white;
  margin: -16px;
  border-radius: 4px;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
const getUpdatedProfile = (profiles, selectedProfile) => {
  if (profiles.length === 0 || !selectedProfile) {
    return null
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  return profiles.find((profile) => profile.id === selectedProfile.id)
}

export default connect((state) => ({
  // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
  lastUpdated: state.profiles.lastUpdated,
  // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
  selectedProfile: state.profiles.selectedProfile,
  // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
  updatedProfiles: state.profiles.profilesWithFreshData,
  // @ts-expect-error TS(2339) FIXME: Property 'account' does not exist on type 'Default... Remove this comment to see the full error message
  loading: state.account.loading,
}))(({ lastUpdated, selectedProfile, updatedProfiles, loading, children }) => {
  if (!isBackfilling(lastUpdated, selectedProfile)) {
    return children
  }

  const recentlyUpdatedProfile = getUpdatedProfile(
    updatedProfiles,
    selectedProfile,
  )

  const showEmptyState =
    isBackfilling(lastUpdated, selectedProfile) && !recentlyUpdatedProfile

  return (
    <React.Fragment>
      {loading && (
        <Container>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; transparent:... Remove this comment to see the full error message */}
          <Loading active noBorder transparent />
        </Container>
      )}
      {!loading && showEmptyState && (
        <Container>
          <States>
            <States.Image
              src="https://buffer-analyze.s3.amazonaws.com/images/illustration-walking.gif"
              alt="a colourful illustration of a buffer character"
            />
            <States.Header>Your data will be with you shortly</States.Header>
            <States.Description>
              Don't worry, we have your account connected and everything is
              looking great, we just need a few minutes to get everything set
              up.
            </States.Description>
          </States>
        </Container>
      )}
      {!loading && !showEmptyState && children}
    </React.Fragment>
  )
})
