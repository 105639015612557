import React from 'react'
import Text from '@bufferapp/ui/Text'
import { Info } from '@bufferapp/ui/Icon'
import { grayDark } from '@bufferapp/ui/style/colors'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helper } from '~/shared-components'

const Container = styled.div`
  margin: 0 16px 0 0;

  label {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      padding: 0 6px 0 0;
      width: 12px;
      height: 12px;
      color: ${grayDark};
    }
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const ChartHelper = ({ text }) => (
  <Container>
    {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; text: any; }' is not as... Remove this comment to see the full error message */}
    <Helper text={text}>
      <Text type="label" color="grayDark">
        <Info /> How is this calculated?
      </Text>
    </Helper>
  </Container>
)

ChartHelper.propTypes = {
  text: PropTypes.string.isRequired,
}

export default ChartHelper
