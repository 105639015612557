import React from 'react'
import dayjs from 'dayjs'
import ChevronDown from '@bufferapp/ui/Icon/Icons/ChevronDown'
import styled from 'styled-components'
import { white, grayDark, grayDarker } from '@bufferapp/ui/style/colors'
import { fontFamily, lineHeight } from '@bufferapp/ui/style/fonts'

// @ts-expect-error TS(7006) FIXME: Parameter 'string' implicitly has an 'any' type.
export function isStringEmpty(string) {
  if (!string) {
    return false
  }
  return string.length === 0
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dateRange' implicitly has an 'any' type... Remove this comment to see the full error message
export function getNumOfDaysForDateRange(dateRange) {
  const start = dayjs(dateRange.startDate, 'MM/DD/YYYY')
  const end = dayjs(dateRange.endDate, 'MM/DD/YYYY')
  return end.diff(start, 'days') + 1
}

// @ts-expect-error TS(7006) FIXME: Parameter 'router' implicitly has an 'any' type.
export function getRouteElements(router) {
  if (router) {
    const elements = router.location.pathname.split('/')
    return {
      channel: elements[1],
      name: elements[2],
    }
  }

  return {
    channel: null,
    name: null,
  }
}

const sizeMap = {
  normal: '40px',
  small: '32px',
}

const paddingMap = {
  normal: '12px 12px 12px 0',
  small: '8px 12px 12px 0',
}

const ButtonContainer = styled.div`
  height: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    sizeMap[props.size]};
  background: ${white};
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  width: 100%;

  :hover {
    cursor: pointer;
    border-color: ${grayDark};
  }
`

const IconContainer = styled.span`
  height: 100%;
  padding: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    paddingMap[props.size]};

  svg {
    position: absolute;
    right: 16px;
  }
`

const Title = styled.span`
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: ${lineHeight};
  color: #596269;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;

  ${ButtonContainer}:hover & {
    color: ${grayDarker};
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
export const renderButton = (onButtonClick, label, size = 'normal') => {
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <ButtonContainer onClick={onButtonClick} size={size}>
      <Title>{label}</Title>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <IconContainer size={size}>
        <ChevronDown />
      </IconContainer>
    </ButtonContainer>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export const calculateDifference = (value, previousValue) => {
  if (previousValue === 0 && value === 0) return 0
  const difference = value - previousValue
  if (previousValue === 0) previousValue = 1 // can't divide by 0
  if (previousValue < 0) Math.abs(previousValue) // don't carry it's negative sign
  return Math.ceil((difference / previousValue) * 100)
}

export const prettyPrintedMetrics = {
  posts_count: 'Posts',
  clicks: 'Clicks',
  comments: 'Comments',
  engagements: 'Engagements',
  engagement_rate: 'Eng. Rate',
  impressions: 'Impressions',
  reach: 'Reach',
  likes: 'Likes',
  shares: 'Shares',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'str' implicitly has an 'any' type.
const camelToSnakeCase = (str) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'letter' implicitly has an 'any' type.
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const snakeCase = (data) => {
  const object = data || {}
  const snaked = {}
  Object.keys(object).forEach((key) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    snaked[camelToSnakeCase(key)] = object[key]
  })
  return snaked
}

// @ts-expect-error TS(7006) FIXME: Parameter 'lastUpdated' implicitly has an 'any' ty... Remove this comment to see the full error message
export const isBackfilling = (lastUpdated, selectedProfile) => {
  let dateString
  const selectedServiceId = selectedProfile ? selectedProfile.serviceId : null

  // if there's no selectedProfile and there's more than 1 channel in lastUpdated,
  // we can assume that the user has finished onboarding and there's a problem with backfill
  // so return false so we don't lock them out of the homepage
  if (Object.keys(lastUpdated).length > 1 && !selectedServiceId) {
    return false
  }
  // if there's no selectedServiceId and there's less than 1 channel, we assume
  // the user is onboarding on home
  else if (!selectedServiceId) {
    dateString = lastUpdated
      ? lastUpdated[Object.keys(lastUpdated)[0]]
      : new Date()
  }
  // if there's a selectedServiceId we can assume the user is in a channel tab
  else {
    dateString =
      lastUpdated && lastUpdated[selectedServiceId]
        ? lastUpdated[selectedServiceId]
        : new Date()
  }

  const timeOfLastUpdate = Date.parse(dateString)
  const FortyEightHoursAgo = Date.parse(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
    new Date(new Date().getTime() - 1000 * 60 * 60 * 48),
  )
  const FortyNineHoursAgo = Date.parse(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
    new Date(new Date().getTime() - 1000 * 60 * 60 * 49),
  )

  return (
    timeOfLastUpdate < FortyEightHoursAgo &&
    timeOfLastUpdate > FortyNineHoursAgo
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'obj' implicitly has an 'any' type.
export const isEmpty = (obj) => obj && Object.keys(obj).length === 0

const MIN_FOLLOWERS_COUNT = 100
const SERVICE_TO_FILTER = ['instagram', 'facebook']

// @ts-expect-error TS(7006) FIXME: Parameter 'loading' implicitly has an 'any' type.
export function getHasEnoughFollowers(loading, data, selectedProfile) {
  if (selectedProfile && !loading) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    const followersMetric = data.totals.find((e) => e.key === 'followers')
    if (followersMetric) {
      if (SERVICE_TO_FILTER.includes(selectedProfile.service)) {
        return !(
          followersMetric.value > 1 &&
          followersMetric.value < MIN_FOLLOWERS_COUNT
        )
      }
    }
  }

  return true
}
