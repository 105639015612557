import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  ChartStateLoading as Loading,
  ChartStateNoData as NoData,
  ChartCard,
  ChartHeader,
  ChartHelper,
  Suggestions,
  Container,
} from '~/shared-components'
import AddReport from '~/add-report'
import Text from '@bufferapp/ui/Text'
import GraphQLWrapper, {
  formatQueryVariables,
} from '~/shared-components/GraphQLWrapper'
import Title, { getTitle } from '../Title'
import Chart from '../Chart'
import Answers from '../../Answers'
import QUERY, { dataParser } from '../../query'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Content = (props) => {
  const best = props[props.metricType].best
  const answer = Answers.forType(props.metricType)
  const starter = [
    'According to our calculations, it looks like',
    "We've consulted our boffins, and they've said",
    "When crunching the numbers, we've worked out that",
    "Exciting stuff, we've figured out that",
    'Fantastic news! It seems that',
  ]

  return (
    <div>
      <Suggestions>
        <Text type="h3">{answer.getTitle(best)}</Text>
        <Text type="label">
          {starter[Math.floor(Math.random() * starter.length)]} this is the best
          way to earn the highest engagement
        </Text>
      </Suggestions>
      <Chart answers={props} metricType={props.metricType} />
    </div>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const ContentWrapper = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.service !== 'linkedin' && <Content {...props} />}
      {props.service == 'linkedin' && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: Content,
            title: getTitle(props.metricType),
          }}
        />
      )}
    </React.Fragment>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'startDate' implicitly has an 'any' type... Remove this comment to see the full error message
export const getBetweenText = (startDate, endDate) => {
  const startDateFormatted = dayjs(new Date(startDate)).format('MMMM D')
  const endDateFormatted = dayjs(new Date(endDate)).format('MMMM D')

  return startDate && endDate
    ? `between ${startDateFormatted} and ${endDateFormatted}`
    : ''
}

// @ts-expect-error TS(7006) FIXME: Parameter 'metricType' implicitly has an 'any' typ... Remove this comment to see the full error message
export const getHelper = (metricType, startDate, endDate) => {
  let text = `We take your posts ${getBetweenText(startDate, endDate)} and `

  switch (metricType) {
    case 'day':
      text +=
        'average the engagement rate within that time frame and map that across each day of the week.'
      break
    case 'type':
      text +=
        'average the engagement rate for each of the post types within that time frame.'
      break
    case 'frequency':
      text +=
        'average the engagement rate for the frequency of posts made across a day within that time frame.'
      break
    default:
      break
  }
  return text
}

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
const isEmpty = (metrics) =>
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  Object.values(metrics).filter((value) => value > 0).length === 0

// @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
const AnswersChart = ({ loading, startDate, endDate, ...props }) => {
  let content = null

  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else if (isEmpty(props.answers[props.metricType].metrics)) {
    content = <NoData chartName={`answers-${props.metricType}`} />
  } else {
    content = <Content {...props.answers} metricType={props.metricType} />
  }

  const helperText = getHelper(props.metricType, startDate, endDate)

  return (
    <ChartCard>
      <ChartHeader>
        <Title metricType={props.metricType} />
        <ChartHelper text={helperText} />
        <AddReport
          chart="answers-chart"
          state={{ metricType: props.metricType }}
        />
      </ChartHeader>
      <Container id={`js-dom-to-png-answers-chart-${props.metricType}`}>
        {content}
      </Container>
    </ChartCard>
  )
}

AnswersChart.defaultProps = {
  loading: false,
  startDate: null,
  endDate: null,
}

AnswersChart.propTypes = {
  loading: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const AnswersWrapper = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.profile.service !== 'linkedin' && <AnswersChart {...props} />}
      {props.profile.service === 'linkedin' && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: AnswersChart,
            save: props.save,
            title: getTitle(props.metricType),
          }}
        />
      )}
    </React.Fragment>
  )
}

export default AnswersWrapper
