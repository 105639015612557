// export reducer, actions and action types
import { connect } from 'react-redux'
import usePermissions from './usePermissions'

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export * from './constants'
export { default as LockIfNotAllowed } from './LockIfNotAllowed'
export { default as CanAccess } from './CanAccess'

// TODO: Do not use the state and read the account information from the user context
export const Can = connect((state) => ({
  // default to export since it's available for free users
  // @ts-expect-error TS(2339) FIXME: Property 'account' does not exist on type 'Default... Remove this comment to see the full error message
  permissions: state.account?.permissions || ['export'],
  // @ts-expect-error TS(2700) FIXME: Rest types may only be created from object types.
}))(({ permissions, children, dispatch, ...otherProps }) => {
  const allPermissions = usePermissions(permissions)
  if (!allPermissions) {
    return children
  }

  const hasPermissions = Object.values(otherProps).every((permission) =>
    allPermissions.includes(permission),
  )
  return hasPermissions ? children : null
})

export const Cant = connect((state) => ({
  // default to export since it's available for free users
  // @ts-expect-error TS(2339) FIXME: Property 'account' does not exist on type 'Default... Remove this comment to see the full error message
  permissions: state.account?.permissions || ['export'],
  // @ts-expect-error TS(2700) FIXME: Rest types may only be created from object types.
}))(({ permissions, children, dispatch, ...otherProps }) => {
  const allPermissions = usePermissions(permissions)
  const permissionsToCheck = Object.values(otherProps)
  if (permissionsToCheck.length && allPermissions) {
    const doesNotHavePermissions = permissionsToCheck.every(
      (permission) => !allPermissions.includes(permission),
    )
    return doesNotHavePermissions ? children : null
  }
  return null
})

export { default as UserIsAdmin } from './UserIsAdmin'
export { default as redirect, channelsRedirectUrl } from './redirect'
export { AccountContext, useAccount, GET_ACCOUNT } from './accountContext'
