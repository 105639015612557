import {
  actionTypes as exportActionTypes,
  actions as exportActions,
} from '~/png-export'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
export const formatDataForCSVExport = ({ metrics }) => {
  if (!metrics.length) {
    return null
  }

  const data = {
    group: [],
    country: [],
    count: [],
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  metrics.forEach((group) => {
    group.metrics
      // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
      .find((metric) => metric.key === 'country')
      // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
      .values.forEach((metric) => {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        data.group.push(group.label)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        data.country.push(metric.label)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        data.count.push(metric.value)
      })
  })
  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  switch (action.type) {
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-audience-countries',
          'demographic-countries',
        ),
      )
      break
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      dispatch(
        exportCSVActions.exportChart(
          'demographic-countries',
          formatDataForCSVExport(getState().demographic),
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
