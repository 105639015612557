import React from 'react'
import { connect } from 'react-redux'
import Table from './components/Table'
import { actions } from './reducer'

class ReduxWrapper extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'profileService' does not exist on type '... Remove this comment to see the full error message
    if (this.props.profileService !== 'linkedin') {
      // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.fetch()
    }
  }

  render() {
    return (
      <React.Fragment>
        <Table {...this.props} />
      </React.Fragment>
    )
  }
}

export const ReduxComponent = connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Defaul... Remove this comment to see the full error message
    loading: state.hashtags.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Defaul... Remove this comment to see the full error message
    hashtags: state.hashtags.hashtags,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Defaul... Remove this comment to see the full error message
    labels: state.hashtags.labels,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Defaul... Remove this comment to see the full error message
    sortBy: state.hashtags.sortBy,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Defaul... Remove this comment to see the full error message
    isDescending: state.hashtags.isDescending,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Defaul... Remove this comment to see the full error message
    numToShow: state.hashtags.numToShow,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
  }),
)(ReduxWrapper)

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profile: state.profiles.selectedProfile,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Defaul... Remove this comment to see the full error message
    sortBy: state.hashtags.sortBy,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Defaul... Remove this comment to see the full error message
    isDescending: state.hashtags.isDescending,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
    save: (result) => dispatch(actions.save(result)),
    // @ts-expect-error TS(7031) FIXME: Binding element 'metricKey' implicitly has an 'any... Remove this comment to see the full error message
    handleSortBy: ({ metricKey, isDescending }) =>
      dispatch(actions.sortBy(metricKey, isDescending)),
  }),
)((props) => (
  <React.Fragment>
    {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
    {props.profile && (
      <React.Fragment>
        {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
        {props.profile.service === 'linkedin' && (
          // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          <Table {...props} metrics={[]} loading />
        )}
        {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
        {props.profile.service !== 'linkedin' && <ReduxComponent {...props} />}
      </React.Fragment>
    )}
  </React.Fragment>
))

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { Table, Title } from './components/Table'
