import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useAccount } from '~/account'
import { ChartStateLoading as Loading, EmptyState } from '~/shared-components'
import Report from '../Report'

const List = styled.ol`
  margin: 0 auto;
  padding: 0;
`

const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ReportList = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'reports' implicitly has an 'any' ... Remove this comment to see the full error message
  reports,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectReport' implicitly has an '... Remove this comment to see the full error message
  selectReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'removeReport' implicitly has an '... Remove this comment to see the full error message
  removeReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'small' implicitly has an 'any' ty... Remove this comment to see the full error message
  small,
  // @ts-expect-error TS(7031) FIXME: Binding element 'fetch' implicitly has an 'any' ty... Remove this comment to see the full error message
  fetch,
}) => {
  const account = useAccount()
  useEffect(
    function fetchReports() {
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
      if (account?.currentOrganization?.id) {
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
        fetch(account?.currentOrganization?.id)
      }
    },
    [account],
  )

  return loading ? (
    <Centered>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; transparent:... Remove this comment to see the full error message */}
      <Loading active noBorder transparent />
    </Centered>
  ) : reports.length > 0 ? (
    <List>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'report' implicitly has an 'any' type. */}
      {reports.map((report) => (
        <Report
          key={report._id}
          {...report}
          small={small}
          selectReport={selectReport}
          removeReport={removeReport}
        />
      ))}
    </List>
  ) : (
    <Centered>
      <EmptyState
        header="Aw, shucks! There are currently no reports"
        description={
          !small
            ? "Create one with the 'Add to Report' button on any graph"
            : null
        }
      />
    </Centered>
  )
}

ReportList.defaultProps = {
  reports: [],
  loading: false,
  small: false,
  removeReport: null,
}

ReportList.propTypes = {
  loading: PropTypes.bool,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      updated_at: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  selectReport: PropTypes.func.isRequired,
  removeReport: PropTypes.func,
  small: PropTypes.bool,
  fetch: PropTypes.func,
}

export default ReportList
