import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import ReportList from './components/ReportList'
import { actions } from './reducer'

// default export = container
export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'reportList' does not exist on type 'Defa... Remove this comment to see the full error message
    ...state.reportList,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    selectReport: (id) => dispatch(push(`/reports/${id}`)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    removeReport: (id) => dispatch(actions.removeReport(id)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    fetch: (id) => dispatch(actions.fetch(id)),
  }),
)(ReportList)

export { default as ReportList } from './components/ReportList'

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
