const metrics = [
  {
    label: 'Impressions',
    key: 'impressions',
  },
  {
    label: 'Engagements',
    key: 'engagement',
  },
  {
    label: 'Comments',
    key: 'comments',
  },
  {
    label: 'Eng. Rate',
    key: 'engagement_rate',
  },
]

export default {
  sortMetrics: [
    ...metrics,
    {
      label: 'Date',
      key: 'date',
    },
  ],
  postMetrics: metrics,
}
