import { connect } from 'react-redux'
import ShopifySources from './components/ShopifySources'
import store from '~/store'
import reducer, { actions, LABELS } from './reducer'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import middleware from './middleware'

export default connect(
  (state) => ({
    // add state here
    // @ts-expect-error TS(2339) FIXME: Property 'shopifySources' does not exist on type '... Remove this comment to see the full error message
    loading: state.shopifySources.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'shopifySources' does not exist on type '... Remove this comment to see the full error message
    hasError: state.shopifySources.hasError,
    // @ts-expect-error TS(2339) FIXME: Property 'shopifySources' does not exist on type '... Remove this comment to see the full error message
    metrics: state.shopifySources.metrics,
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    selectedMetric: LABELS[state.shopifySources.selectedMetric],
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    onSelectMetric: (metric) => dispatch(actions.onSelectMetric(metric)),
  }),
)(ShopifySources)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('shopifySources', reducer)
addMiddleware(middleware)

export { default as Title } from './components/Title'
export { ShopifySourcesTable as Table } from './components/ShopifySources'
