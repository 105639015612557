import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  ChartHelper,
  GridItem,
  Container,
  CHANNEL_TYPES,
} from '~/shared-components'
import GraphQLWrapper, {
  formatQueryVariables,
} from '~/shared-components/GraphQLWrapper'
import AddReport from '~/add-report'
import Title from '../Title'
import QUERY, { dataParser } from '../../query'

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 0;
  margin: 0 auto;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const TableWithMetrics = ({ metrics }) => (
  <Grid>
    {/* @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type. */}
    {metrics.map((metric, index) => {
      const inline = () => {
        switch (metrics.length) {
          case 5:
            return true
          case 8:
            return index > 3
          case 6:
            return index > 2
          default:
            return false
        }
      }

      const itemProps = {
        key: metric.label,
        metric,
        showPercentSign: metric.label === 'Engagement Rate',
      }
      // @ts-expect-error TS(2339) FIXME: Property 'gridWidth' does not exist on type '{ key... Remove this comment to see the full error message
      if (metrics.length === 5) itemProps.gridWidth = '20%'
      // @ts-expect-error TS(2339) FIXME: Property 'gridWidth' does not exist on type '{ key... Remove this comment to see the full error message
      if (metrics.length === 6) itemProps.gridWidth = '33%'
      return <GridItem {...itemProps} inline={inline()} /> // this inline is so the first row has a bottom margin but the second won't
    })}
  </Grid>
)

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const Table = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.metrics && <TableWithMetrics {...props} />}
      {!props.metrics && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: TableWithMetrics,
          }}
        />
      )}
    </React.Fragment>
  )
}

const Content = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
  metrics,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'startDate' implicitly has an 'any... Remove this comment to see the full error message
  startDate,
  // @ts-expect-error TS(7031) FIXME: Binding element 'endDate' implicitly has an 'any' ... Remove this comment to see the full error message
  endDate,
  // @ts-expect-error TS(7031) FIXME: Binding element 'service' implicitly has an 'any' ... Remove this comment to see the full error message
  service,
  // @ts-expect-error TS(7031) FIXME: Binding element 'adsEnabled' implicitly has an 'an... Remove this comment to see the full error message
  adsEnabled,
}) => {
  let content = null
  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else if (metrics.length === 0) {
    content = (
      <NoData chartName="recent-posts" channelType={CHANNEL_TYPES.aggregates} />
    )
  } else {
    content = <TableWithMetrics metrics={metrics} />
  }

  let helper = null
  if (!loading) {
    if (service === 'facebook' || service === 'instagram') {
      helper = (
        <ChartHelper
          text={`These metrics include all engagements received between ${dayjs(
            startDate,
          ).format('MMMM D')} and ${dayjs(endDate).format(
            'MMMM D',
          )}, <b>regardless of when the posts were published.</b>${
            adsEnabled
              ? ' They also include engagements on any boosted posts that were active during this period.'
              : ''
          }`}
        />
      )
    }
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title />
        {helper}
        <AddReport chart="summary-table" />
      </ChartHeader>
      <Container id="js-dom-to-png-summary">{content}</Container>
    </ChartCard>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function SummaryTable(props) {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.profile.service !== 'linkedin' && <Content {...props} />}
      {props.profile.service === 'linkedin' && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: Content,
            save: props.save,
            title: 'Performance',
          }}
        />
      )}
    </React.Fragment>
  )
}

export default SummaryTable
