import { useEffect, useState } from 'react'
import { useAccount } from '~/account'
import {
  PERMISSIONS_BY_PLAN,
  PERMISSIONS_BY_FEATURE_FLAG,
  PERMISSIONS_TO_REMOVE_FOR_TRIALIST,
} from './constants'

// @ts-expect-error TS(7006) FIXME: Parameter 'permissions' implicitly has an 'any' ty... Remove this comment to see the full error message
function usePermissions(permissions) {
  const [allPermissions, setPermissions] = useState(permissions)
  const account = useAccount()

  // @ts-expect-error TS(7006) FIXME: Parameter 'permissionsByPlan' implicitly has an 'a... Remove this comment to see the full error message
  function updatePermissions(permissionsByPlan) {
    const isOnTrial =
      // @ts-expect-error TS(2339) FIXME: Property 'billing' does not exist on type '{}'.
      account?.currentOrganization?.billing?.subscription?.trial?.isActive
    if (isOnTrial) {
      permissionsByPlan = permissionsByPlan.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
        (p) => !PERMISSIONS_TO_REMOVE_FOR_TRIALIST.includes(p),
      )
    }

    setPermissions(permissionsByPlan)
  }

  useEffect(() => {
    if (account?.currentOrganization) {
      // For OB organizations
      // @ts-expect-error TS(2339) FIXME: Property 'isOneBufferOrganization' does not exist ... Remove this comment to see the full error message
      if (account?.currentOrganization.isOneBufferOrganization) {
        const plan =
          // @ts-expect-error TS(2339) FIXME: Property 'billing' does not exist on type '{}'.
          account?.currentOrganization?.billing?.subscription?.plan?.id
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        updatePermissions(PERMISSIONS_BY_PLAN[plan].filter(Boolean))
        // @ts-expect-error TS(2339) FIXME: Property 'billing' does not exist on type '{}'.
      } else if (account?.currentOrganization.billing) {
        // For MP organizations
        const subscription =
          // @ts-expect-error TS(2339) FIXME: Property 'billing' does not exist on type '{}'.
          account?.currentOrganization.billing.subscriptions.filter(
            // @ts-expect-error TS(7006) FIXME: Parameter 'sub' implicitly has an 'any' type.
            (sub) => sub.product === 'analyze',
          )
        const plan = subscription.length > 0 ? subscription[0].plan : ''
        // look for feature flip permissions
        // @ts-expect-error TS(2339) FIXME: Property 'featureFlips' does not exist on type '{}... Remove this comment to see the full error message
        const featureFlipPermissions = account?.currentOrganization.featureFlips
          // @ts-expect-error TS(7006) FIXME: Parameter 'flag' implicitly has an 'any' type.
          .map((flag) => PERMISSIONS_BY_FEATURE_FLAG[flag])
          .flat()

        updatePermissions(
          permissions
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            .concat(PERMISSIONS_BY_PLAN[plan], featureFlipPermissions)
            .filter(Boolean),
        )
      }
    }
  }, [account, permissions])

  return allPermissions
}

export default usePermissions
