import React from 'react'
import PropTypes from 'prop-types'
import Item from './Item'

function capitalizeChannels(name: string): string {
  if (name === 'linkedin') {
    return 'LinkedIn'
  }

  if (name === 'twitter') {
    return 'Twitter / X'
  }

  return `${name.charAt(0).toUpperCase()}${name.slice(1)}`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'route' implicitly has an 'any' type.
function getTabFromRoute(route, channel) {
  const match = route.match(/^.*\/(.*)\/.+$/)
  if (match && match[1]) {
    const currentTab = match[1]
    if (channel.supportedTabs.includes(currentTab)) {
      return currentTab
    }
  }

  return channel.supportedTabs[0]
}

// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
function findConnection(service, channels) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
  return channels.find((channel) => service === channel.service)
}

// @ts-expect-error TS(7031) FIXME: Binding element 'service' implicitly has an 'any' ... Remove this comment to see the full error message
const SingleChannel = ({ service, channels, ...props }) => {
  const activeChannel = findConnection(service, channels)
  if (activeChannel) {
    return (
      // @ts-expect-error TS(2739) FIXME: Type '{ children: string; href: string; }' is miss... Remove this comment to see the full error message
      <Item
        href={`/${activeChannel.service}/${getTabFromRoute(
          props.route,
          activeChannel,
        )}/${activeChannel.id}`}
        service={service}
        {...props}
      >
        {capitalizeChannels(activeChannel.service)}
      </Item>
    )
  }
  return (
    // @ts-expect-error TS(2739) FIXME: Type '{ children: string; href: string; }' is miss... Remove this comment to see the full error message
    <Item href={`/connect/${service}`} service={service} {...props}>
      {capitalizeChannels(service)}
    </Item>
  )
}

SingleChannel.defaultProps = {
  channels: [],
  service: '',
}

SingleChannel.propTypes = {
  channels: PropTypes.array,
  service: PropTypes.string,
}

export default SingleChannel
