import { connect } from 'react-redux'
import CompareChart from './components/CompareChart'
import { actions } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'daily' implicitly has an 'any' type.
function isMissingPreviousPeriodData(daily) {
  if (daily && daily.length > 0) {
    return !daily[0].previousPeriodDay
  }

  return true
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function mapStateToProps(state) {
  return {
    daily: state.compare.metrics.daily,
    totalPeriodDaily: state.compare.metrics.totalPeriodDaily,
    dailyMode: state.compare.dailyMode,
    loading: state.compare.loading,
    profileService: state.profiles.selectedProfileService,
    profile: state.profiles.selectedProfile,
    selectedMetricLabel: state.compare.selectedMetricLabel,
    timezone: state.profiles.selectedProfile
      ? state.profiles.selectedProfile.timezone
      : '',
    totals: state.compare.metrics.totals,
    visualizePreviousPeriod: state.compare.visualizePreviousPeriod,
    missingPreviousPeriod: isMissingPreviousPeriodData(
      state.compare.metrics.daily,
    ),
    startDate: state.date.startDate,
    endDate: state.date.endDate,
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
  selectMetric: (label) => dispatch(actions.selectMetric(label)),
  togglePreviousPeriod: () => dispatch(actions.togglePreviousPeriod()),
  // @ts-expect-error TS(7006) FIXME: Parameter 'mode' implicitly has an 'any' type.
  selectDailyMode: (mode) => dispatch(actions.selectDailyMode(mode)),
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  save: (data) => dispatch(actions.save(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompareChart)

export { default as Title } from './components/Title'
export { MainChart as Chart } from './components/CompareChart'
// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
