// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('ANALYTICS', {
  PAGE_CHANGE: 'PAGE_CHANGE',
  TRACK_EVENT: 'TRACK_EVENT',
})

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'eventName' implicitly has an 'any' type... Remove this comment to see the full error message
  trackEvent(eventName, payload) {
    return {
      type: actionTypes.TRACK_EVENT,
      eventName,
      payload,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  pageChange(payload) {
    return {
      type: actionTypes.PAGE_CHANGE,
      payload,
    }
  },
}

export default actions
