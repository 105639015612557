import { actions as analyticsActions } from '~/analytics-middleware'
import {
  isStringEmpty,
  getNumOfDaysForDateRange,
} from '~/shared-components/utils'
import { actionTypes } from './actions'

// @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
const getExportURL = (name, trackingProperties) => {
  const { origin, pathname } = window.location
  const { reportId, organizationId, userId } = trackingProperties
  const exportURL = encodeURIComponent(`${origin}/export${pathname}`)
  return `${origin}/report_to_pdf?name=${encodeURIComponent(
    name,
  )}&url=${exportURL}&organizationId=${organizationId}&reportId=${reportId}&userId=${userId}`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { report = {}, profiles, appSidebar } = store.getState()

  const trackingProperties = {
    organizationId: profiles.organizationId || '',
    reportId: report.id || '',
    userId: appSidebar.user._id || '',
  }

  switch (action.type) {
    case actionTypes.EXPORT_TO_PDF:
      store.dispatch(
        analyticsActions.trackEvent('reportExported', {
          reportId: report.id,
          days: getNumOfDaysForDateRange(report.dateRange),
          hasLogo: !isStringEmpty(report.logoUrl),
          description: report.description,
          numberOfModules: report.charts.length,
        }),
      )

      window.open(getExportURL(report.name, trackingProperties), '_blank')
      break
    default:
      break
  }
  return next(action)
}
