import tooltipFormatter from './Tooltip'

const gradients = {
  day: [
    [0, 'rgba(107,129,255,1)'],
    [1, 'rgba(107,129,255,0.75)'],
  ],
  type: [
    [0, 'rgba(107,129,255,1)'],
    [1, 'rgba(107,129,255,0.75)'],
  ],
  frequency: [
    [0, 'rgba(107,129,255,1)'],
    [1, 'rgba(107,129,255,0.75)'],
  ],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
export default (metrics, type, formatter) => ({
  title: null,
  chart: {
    type: 'bar',
    height: 240,
    spacingLeft: 10,
    spacingRight: 35,
  },
  xAxis: {
    gridLineWidth: 0,
    title: { text: null },
    lineWidth: 2,
    tickLength: 0,
    minorGridLineWidth: 0,
    lineColor: '#EEE',
    labels: {
      formatter,
      x: 0,
      y: 5,
      useHTML: true,
      style: {
        'font-size': '0.875rem',
        'font-weight': '500',
        'font-family': 'Roboto, sans serif',
        color: '#676767',
        padding: '0 8px',
      },
    },
    categories: [
      ...Object.keys(metrics).map(
        (metric) => metric.charAt(0).toUpperCase() + metric.slice(1),
      ),
    ],
  },
  yAxis: {
    title: { text: null },
    gridLineWidth: 2,
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    max: Object.values(metrics).reduce((a, b) => Math.max(a, b)),
    min: 0,
    softMin: 0,
    minRange: 8,
    gridLineColor: '#EEE',
    lineColor: '#EEE',
    showLastLabel: true,
    labels: {
      x: -15,
      y: 25,
      format: '{value}%',
      useHTML: true,
      style: {
        'font-size': '0.875rem',
        'font-weight': '500',
        'font-family': 'Roboto, sans serif',
        color: '#676767',
        padding: '0 8px',
      },
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointWidth: 33,
      borderRadius: 3,
    },
  },
  series: [
    {
      data: Object.values(metrics).map((value) => value),
      color: {
        linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        stops: gradients[type],
      },
      metricType: type,
    },
  ],
  tooltip: {
    shared: true,
    crosshairs: true,
    formatter: tooltipFormatter,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 7,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
  },
})
