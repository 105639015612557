import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Header = ({ children }) => <Container>{children}</Container>

Header.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Header
