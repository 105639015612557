import { connect } from 'react-redux'
import store from '~/store'
import reducer, { actions } from './reducer'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import middleware from './middleware'
import ShopifyTotals from './components/ShopifyTotals'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'shopifyTotals' does not exist on type 'D... Remove this comment to see the full error message
    loading: state.shopifyTotals.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'shopifyTotals' does not exist on type 'D... Remove this comment to see the full error message
    hasError: state.shopifyTotals.hasError,
    // @ts-expect-error TS(2339) FIXME: Property 'shopifyTotals' does not exist on type 'D... Remove this comment to see the full error message
    metrics: state.shopifyTotals.metrics,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
  }),
)(ShopifyTotals)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('shopifyTotals', reducer)
addMiddleware(middleware)

export { default as Title } from './components/Title'
export { ShopifyTotalsGrid as Grid } from './components/ShopifyTotals'
