import { connect } from 'react-redux'
import { actions as csvActions } from '~/csv-export'
import { actions as pngActions } from '~/png-export'
import ExportPicker from './components/ExportPicker'

// default export = container
export default connect(
  (state, ownProps) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'exportToCSV' does not exist on type 'Def... Remove this comment to see the full error message
    exporting: state.exportToCSV.exporting || state.exportToPNG.exporting,
    // @ts-expect-error TS(2339) FIXME: Property 'filename' does not exist on type '{}'.
    filename: ownProps.filename,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'filename' implicitly has an 'any' type.
    exportToCSV: (filename) => dispatch(csvActions.exportToCSV(filename)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'filename' implicitly has an 'any' type.
    exportToPNG: (filename) => dispatch(pngActions.exportToPNG(filename)),
  }),
)(ExportPicker)
