import React from 'react'
import PropTypes from 'prop-types'

import Product from './components/Product'
import Source from './components/Source'

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const GridList = ({ metrics, type, forReport }) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const gridLines = metrics.map((item, index) => (
    <React.Fragment>
      {type === 'source' && (
        <Source
          item={item}
          key={`source_item_${item.value}_${item.label}`}
          number={index + 1}
          forReport={forReport}
        />
      )}
      {type === 'product' && (
        <Product
          item={item}
          key={`product_item_${item.value}_${item.label}`}
          number={index + 1}
          forReport={forReport}
        />
      )}
    </React.Fragment>
  ))

  return <div style={{ width: '100%' }}>{gridLines}</div>
}

GridList.defaultProps = {
  metrics: [],
  type: 'source',
  forReport: false,
}

GridList.propTypes = {
  metrics: PropTypes.arrayOf,
  type: PropTypes.string,
  forReport: PropTypes.bool,
}

export default GridList
