export const library = {
  home: {
    headline: 'Looking to succeed at social?',
    textLeft:
      'You’re all set up but how to take it further? Cycle through useful articles we’ve provided or find more at ',
    resources: [
      {
        title:
          'Your Social Media Content Calendar and Ideas List for Every Official (and Non-Official) Holiday of 2024',
        url: 'https://buffer.com/resources/social-media-calendar-2024/',
        image:
          'https://buffer-analyze.s3.amazonaws.com/images/library/home-1.jpg',
      },
      {
        title:
          'A System for Capturing Your Ideas and Using Them To Create Content',
        url: 'https://buffer.com/resources/content-creation-system/',
        image:
          'https://buffer-analyze.s3.amazonaws.com/images/library/home-2.jpg',
      },
      {
        title: 'Ask Buffer: How Can You Batch Content for Social Media?',
        url: 'https://buffer.com/resources/content-batching/',
        image:
          'https://buffer-analyze.s3.amazonaws.com/images/library/home-3.png',
      },
      {
        title: 'Social Media for Small Business: A Guide for 2023 and Beyond',
        url: 'https://buffer.com/library/social-media-content-strategy/',
        image:
          'https://buffer-analyze.s3.amazonaws.com/images/library/home-4.jpg',
      },
    ],
  },
  answers: {
    headline: 'We’re here to provide even more answers',
    textLeft:
      'Social media is hard. We get it and we want to help you find success faster. Read our articles here or over at ',
    resources: {
      facebook: [
        {
          title: 'Best Time to Post on Facebook in 2023: A Complete Guide',
          url: 'https://buffer.com/library/best-time-to-post-on-facebook/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/facebook-answers-1.jpg',
        },
      ],
      twitter: [
        {
          title: 'How to Schedule Tweets to Maximize Reach and Engagement',
          url: 'https://buffer.com/library/how-to-schedule-tweets/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/twitter-answers-1.jpg',
        },
        {
          title: 'When’s The Best Time to Post on Twitter?',
          url: 'https://buffer.com/library/best-time-to-post-on-twitter/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/twitter-answers-2.jpg',
        },
      ],
      linkedin: [
        {
          title: '4 High-Impact Tactics to Bolster Your LinkedIn Strategy',
          url: 'https://buffer.com/library/linkedin-strategy/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/linkedin-answers-1.png',
        },
        {
          title:
            'The Quick Guide to LinkedIn Marketing Strategy: 9 Best Practices',
          url: 'https://buffer.com/library/linkedin-marketing/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/linkedin-answers-2.jpg',
        },
        {
          title: 'When’s The Best Time to Post on LinkedIn?',
          url: 'https://buffer.com/library/best-time-to-post-on-linkedin/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/linkedin-answers-3.jpg',
        },
      ],
      instagram: [
        {
          title:
            'The Ultimate Guide to Instagram Analytics: Metrics, Insights, Tools, and Tips',
          url: 'https://buffer.com/library/instagram-analytics/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/instagram-answers-1.jpg',
        },
        {
          title:
            'Instagram for Business: 30 Tips to Grow Your Audience and Stand Out on Instagram',
          url: 'https://buffer.com/library/instagram-for-business-tips/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/instagram-answers-2.jpg',
        },
        {
          title: 'Instagram Stories: The Complete Guide to Using Stories',
          url: 'https://buffer.com/library/instagram-stories/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/instagram-answers-3.jpg',
        },
        {
          title: 'When’s The Best Time to Post on Instagram?',
          url: 'https://buffer.com/library/when-is-the-best-time-to-post-on-instagram/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/instagram-answers-4.jpg',
        },
      ],
    },
  },
  overview: {
    headline: 'With more followers we can show you more metrics',
    textLeft: {
      facebook:
        'Once your page has 100 likes or more we can show you even more. Read how to grow your audience at ',
      instagram:
        'Once your account is 100 followers or more we can show you even more. Read how to grow your audience at ',
      linkedin:
        'Once your account is 100 followers or more we can show you even more. Read how to grow your audience at ',
      twitter:
        'Once your account is 100 followers or more we can show you even more. Read how to grow your audience at ',
      shopify:
        'Once your account is 100 followers or more we can show you even more. Read how to grow your audience at ',
    },
    resources: {
      facebook: [
        {
          title:
            'How to Create the Perfect Facebook Business Page to Promote Your Small Business',
          url: 'https://buffer.com/library/how-to-create-manage-facebook-business-page/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/facebook-overview-1.jpg',
        },
        {
          title:
            'Facebook Marketing for Small Business: How to Grow in 2023 and Beyond',
          url: 'https://buffer.com/library/facebook-marketing-for-small-business/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/facebook-overview-2.jpg',
        },
      ],
      instagram: [
        {
          title:
            'Instagram Reels: What’s The True Impact They Can Have for Small Businesses?',
          url: 'https://buffer.com/resources/instagram-reels-small-business/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/instagram-overview-1.jpg',
        },
        {
          title: 'Instagram for Small Business: How to Grow in 2023 and Beyond',
          url: 'https://buffer.com/library/instagram-small-business/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/instagram-overview-2.jpg',
        },
        {
          title:
            'Instagram for Business: 30 Tips to Grow Your Audience and Stand Out on Instagram',
          url: 'https://buffer.com/library/instagram-for-business-tips/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/instagram-overview-3.jpg',
        },
      ],
      linkedin: [
        {
          title:
            '3 Steps to Use LinkedIn to Make Connections and Grow Your Personal Brand',
          url: 'https://buffer.com/resources/linkedin-personal-brand/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/linkedin-overview-1.jpg',
        },
        {
          title:
            'All of LinkedIn’s Latest Features and How Creators are Using Them',
          url: 'https://buffer.com/resources/linkedins-latest-features/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/linkedin-overview-2.jpg',
        },
      ],
      twitter: [
        {
          title: 'How to Write a Great Twitter Thread',
          url: 'https://buffer.com/library/twitter-threads/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/twitter-overview-1.jpg',
        },
        {
          title:
            'Twitter for Small Businesses: How to Grow in 2023 and Beyond ',
          url: 'https://buffer.com/library/twitter-small-business/',
          image:
            'https://buffer-analyze.s3.amazonaws.com/images/library/twitter-overview-2.jpg',
        },
      ],
    },
  },
}
