import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import * as icons from '@bufferapp/ui/Icon'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import {
  red,
  white,
  facebook,
  instagram,
  twitter,
  pinterest,
  linkedin,
  tiktok,
  googlebusiness,
} from '@bufferapp/ui/style/colors'

const knownSources = [
  'Buffer',
  'Facebook',
  'Google',
  'Instagram',
  'LinkedIn',
  'Pinterest',
  'Shopify',
  'Twitter',
  'Youtube',
  'Tiktok',
]

// @ts-expect-error TS(7006) FIXME: Parameter 'source' implicitly has an 'any' type.
function getSource(source) {
  let match = null
  knownSources.forEach((key) => {
    if (source.toLowerCase().match(key.toLowerCase())) {
      match = key
    }

    if (source === 'googlebusiness') {
      match = 'Gbp'
    }
  })

  return match || 'Website'
}

// @ts-expect-error TS(7006) FIXME: Parameter 'source' implicitly has an 'any' type.
function getBackgroundColor(source) {
  switch (getSource(source)) {
    case 'Google':
      return '#1DA463'
    case 'Facebook':
      return facebook
    case 'Pinterest':
      return pinterest
    case 'Twitter':
      return twitter
    case 'Instagram':
      return instagram
    case 'LinkedIn':
      return linkedin
    case 'Tiktok':
      return tiktok
    case 'Gbp':
      return googlebusiness
    default:
      return '#636363'
  }
}

const SIZES = {
  small: {
    icon: '10px',
    inactive: '8px',
    margin: '8px',
    placeholder: '16px',
  },
  medium: {
    icon: '10px',
    inactive: '10px',
    margin: '10px',
    placeholder: '20px',
  },
  extraLarge: {
    icon: '32px',
    inactive: '32px',
    margin: '16px',
    placeholder: '56px',
  },
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'inactive' does not exist on type 'Themed... Remove this comment to see the full error message
    props.inactive ? `calc(${SIZES[props.size].margin} / 4)` : '0px'};
`

const InactiveFlag = styled.div`
  background: ${white};
  border-radius: 50%;
  position: absolute;
  left: calc(
    ${(props) =>
        // @ts-expect-error TS(2339) FIXME: Property 'inline' does not exist on type 'ThemedSt... Remove this comment to see the full error message
        props.inline
          ? null
          : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            `${SIZES[props.size].margin} +`}
      ${(props) =>
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        SIZES[props.size].placeholder} -
      calc(
        ${(props) =>
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            SIZES[props.size].inactive} / 2
      )
  );
  top: calc(
    calc(
        ${(props) =>
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            SIZES[props.size].placeholder} / 2
      ) -
      calc(
        ${(props) =>
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            SIZES[props.size].inactive} / 2
      )
  );
  z-index: 1;

  height: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    SIZES[props.size].inactive};
  width: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    SIZES[props.size].inactive};

  svg {
    width: ${(props) =>
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      SIZES[props.size].inactive};
    height: ${(props) =>
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      SIZES[props.size].inactive};
    position: absolute;
  }
`

const PlaceholderIcon = styled.div`
  width: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    SIZES[props.size].placeholder};
  height: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    SIZES[props.size].placeholder};
  background: ${(props) => props.color};
  border-radius: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'circular' does not exist on type 'Themed... Remove this comment to see the full error message
    props.circular ? '50%' : '4px'};
  margin: 0
    ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'inline' does not exist on type 'ThemedSt... Remove this comment to see the full error message
      props.inline
        ? '4px'
        : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          SIZES[props.size].margin}
    0
    ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'inline' does not exist on type 'ThemedSt... Remove this comment to see the full error message
      props.inline
        ? 0
        : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          SIZES[props.size].margin};
  opacity: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'inactive' does not exist on type 'Themed... Remove this comment to see the full error message
    props.inactive ? '60%' : '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    fill: white;
    width: ${(props) =>
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      SIZES[props.size].icon};
    height: ${(props) =>
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      SIZES[props.size].icon};
  }

  &:hover {
    opacity: 100%;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
const ReferralIcon = ({ item, size, circular, inactive, inline }) => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expression of type 'string'
  const Icon = icons[getSource(item.source)]
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Wrapper size={size} inactive={inactive}>
      {inactive && (
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <InactiveFlag size={size} inline={inline}>
          <WarningIcon color={red} />
        </InactiveFlag>
      )}
      <PlaceholderIcon
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        inactive={inactive}
        inline={inline}
        circular={circular}
        size={size}
        color={getBackgroundColor(item.source)}
      >
        <Icon size="extraLarge" />
      </PlaceholderIcon>
    </Wrapper>
  )
}

ReferralIcon.propTypes = {
  item: PropTypes.shape({
    source: PropTypes.string,
  }).isRequired,
  size: PropTypes.string,
  circular: PropTypes.bool,
  inactive: PropTypes.bool,
  inline: PropTypes.bool,
}

ReferralIcon.defaultProps = {
  size: 'extraLarge',
  circular: false,
  inactive: false,
  inline: false,
}

export default ReferralIcon
