import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import reducer, { actions } from './reducer'
import middleware from './middleware'
import Campaigns from './components/Campaigns'

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('campaigns', reducer)
addMiddleware(middleware)

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'campaigns' does not exist on type 'Defau... Remove this comment to see the full error message
    ...state.campaigns,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignSelector' does not exist on type... Remove this comment to see the full error message
    selectedCampaign: state.campaignSelector.selectedCampaign,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profiles: state.profiles.profiles,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    fetchCampaigns: (id) => dispatch(actions.fetchCampaigns(id)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'tagsEnabled' implicitly has an 'any' ty... Remove this comment to see the full error message
    setTagsStatus: (tagsEnabled) =>
      dispatch(actions.setTagsStatus(tagsEnabled)),
  }),
)(Campaigns)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { CampaignDate } from './components/Header'
