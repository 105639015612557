import JSZip from 'jszip'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'file... Remove this comment to see the full error message
import FileSaver from 'file-saver'

// @ts-expect-error TS(7006) FIXME: Parameter 'filename' implicitly has an 'any' type.
export default (filename, files) => {
  const zip = new JSZip()
  const zipFolder = zip.folder(`${filename}-csv`)
  files
    // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
    .filter((file) => file.csv !== null)
    // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
    .forEach((file) => zipFolder.file(file.filename, file.csv))
  return zip
    .generateAsync({
      type: 'blob',
    })
    .then((content) => FileSaver.saveAs(content, `${filename}.zip`))
}
