import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import getSymbolFromCurrency from 'currency-symbol-map'

import { GridItem } from '~/shared-components'

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
`

const VALUE_FIRST_KEYS = ['avg. customer lifetime value']

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyData' implicitly has an 'any' type... Remove this comment to see the full error message
function formatDailyData(dailyData, { label, currency, key }) {
  return Object.keys(dailyData).map((date) => {
    return {
      day: new Date(date).getTime().toString(),
      metrics: [
        {
          label,
          value: dailyData[date],
          metricKey: key,
          currency: currency ? getSymbolFromCurrency(currency) : '',
          valueFirst: !VALUE_FIRST_KEYS.indexOf(key),
        },
      ],
    }
  })
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const TotalsGrid = ({ metrics }) => {
  const gridWidth = metrics.length > 3 ? 100 / metrics.length : 100 / 3
  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const listItems = metrics.map((item, index) => {
    return (
      <GridItem
        metric={item}
        gridWidth={`${gridWidth}%`}
        dailyData={formatDailyData(item.daily_totals, item)}
        chartType={'areaspline'}
        chartSize={'small'}
        key={index}
        controlMinMax={item.controlMinMax}
        inline
      />
    )
  })

  return <Grid>{listItems}</Grid>
}

TotalsGrid.defaultProps = {
  metrics: [],
}

TotalsGrid.propTypes = {
  metrics: PropTypes.array,
}

export default TotalsGrid
