import React from 'react'

import {
  facebook,
  instagram,
  twitter,
  linkedin,
} from '@bufferapp/ui/style/colors'

import XIcon from '@bufferapp/ui/Icon/Icons/X'
import FacebookIcon from '@bufferapp/ui/Icon/Icons/Facebook'
import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import LinkedInIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'

interface SocialIconProps {
  service: string
  socialIconSize: number
  avatarSize?: number
  withBorder?: boolean
  inline?: boolean
}

const SocialIcon = ({
  service,
  socialIconSize,
  avatarSize = 0,
  withBorder = false,
  inline = false,
}: SocialIconProps): JSX.Element => {
  let icon
  switch (service) {
    case 'twitter':
      icon = <XIcon color={twitter} />
      break
    case 'facebook':
      icon = <FacebookIcon color={facebook} />
      break
    case 'instagram':
      icon = <InstagramIcon color={instagram} />
      break
    case 'linkedin':
      icon = <LinkedInIcon color={linkedin} />
      break
    default:
      icon = null
      break
  }

  return (
    <div
      style={{
        background: '#fff',
        position: !inline ? 'absolute' : undefined,
        display: `${inline ? 'inline-block' : ''}`,
        marginRight: `${inline ? '0.25rem' : ''}`,
        width: `${socialIconSize}px`,
        height: `${socialIconSize}px`,
        top: `${avatarSize - socialIconSize}px`,
        left: `${avatarSize - (socialIconSize - 1)}px`,
        borderRadius: '50%',
        border: `rgba(255, 255, 255, 0.5) ${withBorder ? '1px' : '0'} solid`,
      }}
    >
      {icon}
    </div>
  )
}

export default SocialIcon
