// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('REPORT', {
  CREATE_REPORT: 'CREATE_REPORT',
  ADD_TO_REPORT: 'ADD_TO_REPORT',
})

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  createReport(name, chartId, state = {}) {
    return {
      type: actionTypes.CREATE_REPORT,
      name,
      state,
      chart_id: chartId,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  addToReport(id, chartId, state = {}) {
    return {
      type: actionTypes.ADD_TO_REPORT,
      state,
      chart_id: chartId,
      reportId: id,
    }
  },
}
