import html2canvas from 'html2canvas'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'data... Remove this comment to see the full error message
import dataURLToBlob from 'dataurl-to-blob'
import dayjs from 'dayjs'

// @ts-expect-error TS(7006) FIXME: Parameter 'canvas' implicitly has an 'any' type.
const convertCanvasToPNG = (canvas) => {
  const dataURL = canvas.toDataURL('image/png')
  return dataURLToBlob(dataURL)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
const createChartFilename = (chart, date) => {
  const { startDate, endDate } = date
  const fileDateFormat = 'YYYYMMDD'
  const startDateFormatted = dayjs(startDate, 'MM/DD/YYYY').format(
    fileDateFormat,
  )
  const endDateFormatted = dayjs(endDate, 'MM/DD/YYYY').format(fileDateFormat)

  return `${chart.filename}-${startDateFormatted}-to-${endDateFormatted}.png`
}
// @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
const generatePNG = (chart, date) => {
  const node = document.getElementById(chart.id)
  if (!node) return null
  return html2canvas(node, {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ background: string; useCORS: t... Remove this comment to see the full error message
    background: '#ffffff',
    useCORS: true,
    scale: 2,
    width: 1024,
    windowWidth: 1024,
  }).then((canvas) => ({
    image: convertCanvasToPNG(canvas),
    filename: createChartFilename(chart, date),
  }))
}

// @ts-expect-error TS(7006) FIXME: Parameter 'charts' implicitly has an 'any' type.
export default (charts, date) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
  Promise.all(charts.map((chart) => generatePNG(chart, date))).then((pngs) =>
    pngs.filter((png) => png !== null),
  )
