import { gql } from '@apollo/client'
import { calculateDifference, snakeCase } from '~/shared-components/utils'

const LABELS = {
  followers: 'Followers',
  followers_gain_organic: 'New Followers',
  posts_count: 'Posts',
  impressions: 'Impressions',
  clicks: 'Clicks',
  engagement_rate: 'Engagement Rate',
}

// @ts-expect-error TS(7031) FIXME: Binding element 'totals' implicitly has an 'any' t... Remove this comment to see the full error message
export function dataParser({ analyticsTotals: totals }) {
  // @ts-expect-error TS(7034) FIXME: Variable 'metrics' implicitly has type 'any[]' in ... Remove this comment to see the full error message
  const metrics = []
  const organic = snakeCase(totals.organic)
  const previous_organic = snakeCase(totals.previousOrganic)

  Object.keys(LABELS).forEach((key) => {
    metrics.push({
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      value: organic[key],
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      diff: calculateDifference(organic[key], previous_organic[key]),
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      label: LABELS[key],
      paid: 0,
    })
  })

  return {
    // @ts-expect-error TS(7005) FIXME: Variable 'metrics' implicitly has an 'any[]' type.
    metrics,
  }
}

const GET_SUMMARY = gql`
  query AnalyticsSummary(
    $serviceId: String!
    $serviceType: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    analyticsTotals(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
      getPreviousPeriod: true
    ) {
      organic {
        clicks
        engagementRate
        followers
        followersGainOrganic
        impressions
        postsCount
      }
      previousOrganic {
        clicks
        engagementRate
        followers
        followersGainOrganic
        impressions
        postsCount
      }
    }
  }
`

export default GET_SUMMARY
