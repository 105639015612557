import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { grayDark, gray, blueDarker } from '@bufferapp/ui/style/colors'

const Container = styled.span`
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
`

const Toggle = styled.span`
  position: relative;
  cursor: pointer;
  width: 45px;
`

const Pill = styled.span`
  position: absolute;
  top: -7px;
  right: 0;
  background: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.active ? blueDarker : '#B8B8B8'};
  border: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.active ? `1px solid ${blueDarker}` : '1px solid #b8b8b8'};
  border-radius: 40px;
  width: 33px;
  height: 12px;
  display: inline-block;
  transition: background 200ms ease-out;
`

const Knob = styled.span`
  position: absolute;
  top: -8px;
  right: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.active ? '0px' : '19px'};
  background: #ffffff;
  border: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.active ? `1px solid ${blueDarker}` : '1px solid #b8b8b8'};
  border-radius: 100%;
  width: 14px;
  height: 14px;
  display: inline-block;
  transition: right 200ms ease-out;
`

const InvisibleButton = styled.button`
  background: 0;
  border: 0;
  padding: 0;
  margin: 0;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'active' implicitly has an 'any' t... Remove this comment to see the full error message
const PeriodToggle = ({ active, handleClick, disabled }) => {
  return (
    <span>
      <InvisibleButton disabled={disabled} onClick={handleClick}>
        <Container>
          <Text type="p" color={disabled ? gray : grayDark}>
            Previous Period
          </Text>
          <Toggle>
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <Pill active={active} />
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <Knob active={active} />
          </Toggle>
        </Container>
      </InvisibleButton>
    </span>
  )
}

PeriodToggle.defaultProps = {
  active: false,
  disabled: false,
}

PeriodToggle.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
}

export default PeriodToggle
