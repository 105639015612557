import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ImageIcon from '@bufferapp/ui/Icon/Icons/Image'
import { gray } from '@bufferapp/ui/style/colors'

import Attachment from './components/Attachment'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #cfd7df;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
const PostMedia = ({ post }) => {
  const hasThumbnail = post.media && post.media.thumbnail
  return (
    <Container>
      {hasThumbnail && <Attachment type={post.type} media={post.media} />}
      {!hasThumbnail && <ImageIcon size="large" color={gray} />}
    </Container>
  )
}

PostMedia.propTypes = {
  post: PropTypes.shape({
    media: PropTypes.shape({
      // @ts-expect-error TS(2551) FIXME: Property 'String' does not exist on type 'typeof i... Remove this comment to see the full error message
      thumbnail: PropTypes.String,
      // @ts-expect-error TS(2551) FIXME: Property 'String' does not exist on type 'typeof i... Remove this comment to see the full error message
      picture: PropTypes.String,
    }),
    text: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
}

export default PostMedia
