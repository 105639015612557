import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PercentageUp, PercentageDown } from '@bufferapp/ui/Icon'

const Container = styled.div`
  > svg {
    display: block;
    fill: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'fillColor' does not exist on type 'Theme... Remove this comment to see the full error message
      props.fillColor};
    height: 12px;
    width: 12px;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'diff' implicitly has an 'any' typ... Remove this comment to see the full error message
const ArrowIcon = ({ diff, upward, downward, color }) => {
  if (diff > 0 || upward) {
    const fillColor = color || '#18DB65'
    return (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <Container fillColor={fillColor}>
        <PercentageUp size="medium" />
      </Container>
    )
  } else if (diff === 0) {
    const fillColor = color || '#CBCBCB'
    return (
      <Container>
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="11" height="11" fill="white" />
          <path
            d="M4.06699 0.75C4.25944 0.416667 4.74056 0.416667 4.93301 0.75L7.9641 6C8.15655 6.33333 7.91599 6.75 7.53109 6.75L1.46891 6.75C1.08401 6.75 0.843448 6.33333 1.0359 6L4.06699 0.75Z"
            transform="translate(9 10) scale(-1 1) rotate(-90)"
            fill={fillColor}
          />
        </svg>
      </Container>
    )
  } else if (diff < 0 || downward) {
    const fillColor = color || '#E0364F'
    return (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <Container fillColor={fillColor}>
        <PercentageDown size="medium" />
      </Container>
    )
  }
  return null
}

ArrowIcon.propTypes = {
  diff: PropTypes.number,
  color: PropTypes.string,
  downward: PropTypes.bool,
  upward: PropTypes.bool,
}

ArrowIcon.defaultProps = {
  diff: null,
  color: null,
  downward: false,
  upward: false,
}

export default ArrowIcon
