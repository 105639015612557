import { gql } from '@apollo/client'

// @ts-expect-error TS(7031) FIXME: Binding element 'answers' implicitly has an 'any' ... Remove this comment to see the full error message
export function dataParser({ analyticsAnswers: answers }) {
  return {
    ...answers,
  }
}

const GET_ANSWERS_SUMMARY = gql`
  query AnalyticsAnswers(
    $serviceId: String!
    $serviceType: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    analyticsAnswers(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
    ) {
      day {
        best
      }
      type {
        best
      }
      frequency {
        best
      }
    }
  }
`

export default GET_ANSWERS_SUMMARY
