import { connect } from 'react-redux'
import ProfileLoader from './components/ProfileLoader'
import { actions } from './reducer'

// default export = container
export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'profileLoader' does not exist on type 'D... Remove this comment to see the full error message
    loading: state.profileLoader.loading,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'channels' implicitly has an 'any' type.
    storeChannel: (channels, accountChannels) =>
      dispatch(actions.storeChannel(channels, accountChannels)),
    failedToFetchChannels: () => dispatch(actions.failedToFetchChannels()),
    fetchingChannels: () => dispatch(actions.fetchingChannels()),
  }),
)(ProfileLoader)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { useChannels } from './ChannelsContext'
