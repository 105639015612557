import styled from 'styled-components'
import { gray } from '@bufferapp/ui/style/colors'

export default styled.hr`
  border: none;
  height: 0.063rem;
  background: ${gray};
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
`
