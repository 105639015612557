import React from 'react'
import styled from 'styled-components'
import { ReferralIcon, TruncatedNumber } from '~/shared-components'
import {
  facebook,
  instagram,
  twitter,
  linkedin,
} from '@bufferapp/ui/style/colors'

const lineColorMap = {
  facebook,
  twitter,
  instagram,
  linkedin,
}

const IconWrapper = styled.span`
  border-radius: 50%;
  background-color: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    lineColorMap[props.name]};
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`

const List = styled.ul`
  margin: 24px 0 0 0;
  padding: 0;
`

const Item = styled.li`
  list-style: none;
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'bigger' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.bigger ? '16px' : '14px'};
  line-height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'bigger' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.bigger ? '19px' : '16px'};
  color: white;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
const formatMetricLabel = (metric) => {
  switch (metric) {
    case 'engagement':
      return 'engagements'
    case 'average_engagement_rate':
      return 'average engagement rate'
    default:
      return metric
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyValue' implicitly has an 'any' typ... Remove this comment to see the full error message
const calculatePercentageOfTotal = (dailyValue, totalValue) => {
  return (
    <TruncatedNumber showPercentSign>
      {(dailyValue / totalValue) * 100}
    </TruncatedNumber>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
export const ChartTooltip = ({ data, selectedMetric }) => {
  selectedMetric = formatMetricLabel(selectedMetric)

  const totalDaily = data[0].total

  return (
    <div style={{ padding: '16px', zIndex: '999' }}>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <Text bigger>
        <TruncatedNumber>{totalDaily}</TruncatedNumber>&nbsp;total
      </Text>
      <List>
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type. */}
        {data.map((x, index) => (
          <Item key={index}>
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <IconWrapper name={x.point.channel}>
              <ReferralIcon
                item={{
                  source: x.point.channel,
                }}
                size="small"
                circular
              />
            </IconWrapper>
            <TextWrapper>
              <span>
                <Text>
                  <TruncatedNumber
                    showPercentSign={
                      selectedMetric === 'average engagement rate'
                    }
                  >
                    {x.y}
                  </TruncatedNumber>{' '}
                  ({calculatePercentageOfTotal(x.y, totalDaily)})
                </Text>
              </span>
            </TextWrapper>
          </Item>
        ))}
      </List>
    </div>
  )
}
