import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PlaceholderIcon = styled.div`
  width: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.width};
  height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.height};
  background: ${(props) => props.color};
  border-radius: 50%;
  margin: 0
    ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'margin' does not exist on type 'ThemedSt... Remove this comment to see the full error message
      props.margin};
  opacity: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    fill: white;
    width: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'iconWidth' does not exist on type 'Theme... Remove this comment to see the full error message
      props.iconWidth};
    height: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'iconHeight' does not exist on type 'Them... Remove this comment to see the full error message
      props.iconHeight};
  }

  &:hover {
    opacity: 100%;
  }
`

const CircleIcon = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'width' implicitly has an 'any' ty... Remove this comment to see the full error message
  width,
  // @ts-expect-error TS(7031) FIXME: Binding element 'height' implicitly has an 'any' t... Remove this comment to see the full error message
  height,
  // @ts-expect-error TS(7031) FIXME: Binding element 'margin' implicitly has an 'any' t... Remove this comment to see the full error message
  margin,
  // @ts-expect-error TS(7031) FIXME: Binding element 'color' implicitly has an 'any' ty... Remove this comment to see the full error message
  color,
  // @ts-expect-error TS(7031) FIXME: Binding element 'iconWidth' implicitly has an 'any... Remove this comment to see the full error message
  iconWidth,
  // @ts-expect-error TS(7031) FIXME: Binding element 'iconHeight' implicitly has an 'an... Remove this comment to see the full error message
  iconHeight,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
}) => {
  return (
    <PlaceholderIcon
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      width={width}
      height={height}
      margin={margin}
      color={color}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
    >
      {children}
    </PlaceholderIcon>
  )
}

CircleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  color: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
}

CircleIcon.defaultProps = {
  width: '16px',
  height: '16px',
  margin: 0,
  iconWidth: '10px',
  iconHeight: '10px',
}

export default CircleIcon
