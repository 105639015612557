import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import { calculateDifference, snakeCase } from '~/shared-components/utils'

const LABELS = {
  impressions: 'Average Impressions Per Post',
  clicks: 'Average Clicks Per Post',
  engagement_rate: 'Average Engagement Rate Per Post',
}

export function dataParser({
  // @ts-expect-error TS(7031) FIXME: Binding element 'dailies' implicitly has an 'any' ... Remove this comment to see the full error message
  analyticsDailies: dailies,
  // @ts-expect-error TS(7031) FIXME: Binding element 'totals' implicitly has an 'any' t... Remove this comment to see the full error message
  analyticsTotals: totals,
}) {
  // @ts-expect-error TS(7034) FIXME: Variable 'dailyData' implicitly has type 'any[]' i... Remove this comment to see the full error message
  const dailyData = []
  // @ts-expect-error TS(7034) FIXME: Variable 'totalsData' implicitly has type 'any[]' ... Remove this comment to see the full error message
  const totalsData = []

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  dailies.forEach((item) => {
    const daily = snakeCase(item)
    // @ts-expect-error TS(2339) FIXME: Property 'day' does not exist on type '{}'.
    const day = dayjs(daily.day, 'YYYY-MM-DD').valueOf()
    // @ts-expect-error TS(7034) FIXME: Variable 'metrics' implicitly has type 'any[]' in ... Remove this comment to see the full error message
    const metrics = []

    Object.keys(LABELS).forEach((key) => {
      // @ts-expect-error TS(2339) FIXME: Property 'organic' does not exist on type '{}'.
      const organic = snakeCase(daily.organic)
      // @ts-expect-error TS(2339) FIXME: Property 'posts_count' does not exist on type '{}'... Remove this comment to see the full error message
      const postCount = organic.posts_count === 0 ? 1 : organic.posts_count
      metrics.push({
        key,
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        label: LABELS[key],
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        value: organic[key] / postCount,
      })
    })

    dailyData.push({
      day,
      // @ts-expect-error TS(7005) FIXME: Variable 'metrics' implicitly has an 'any[]' type.
      metrics,
    })
  })

  const organic = snakeCase(totals.organic)
  const previous_organic = snakeCase(totals.previousOrganic)
  Object.keys(LABELS).forEach((key) => {
    // @ts-expect-error TS(2339) FIXME: Property 'posts_count' does not exist on type '{}'... Remove this comment to see the full error message
    const postCount = organic.posts_count === 0 ? 1 : organic.posts_count
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const value = organic[key] / postCount
    const previousValue = previous_organic
      ? // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        previous_organic[key] / postCount
      : 0
    totalsData.push({
      diff: calculateDifference(value, previousValue),
      key,
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      label: LABELS[key],
      value,
    })
  })

  return {
    // @ts-expect-error TS(7005) FIXME: Variable 'dailyData' implicitly has an 'any[]' typ... Remove this comment to see the full error message
    daily: dailyData,
    // @ts-expect-error TS(7005) FIXME: Variable 'totalsData' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    totals: totalsData,
  }
}

const GET_AVERAGES = gql`
  query AnalyticsDailies(
    $serviceId: String!
    $serviceType: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    analyticsDailies(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
    ) {
      day
      previousDay
      organic {
        clicks
        engagementRate
        impressions
        postsCount
      }
    }
    analyticsTotals(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
      getPreviousPeriod: true
    ) {
      organic {
        clicks
        engagementRate
        impressions
        postsCount
      }
      previousOrganic {
        clicks
        engagementRate
        impressions
        postsCount
      }
    }
  }
`

export default GET_AVERAGES
