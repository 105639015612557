import moment from 'moment-timezone'
import { connect } from 'react-redux'
import DatePicker from './components/DatePicker'
import { actions } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
export function convertDateToTimestamp(date) {
  return date ? moment(date, 'MM/DD/YYYY').unix() : null
}

// default export = container
export default connect(
  (state, ownProps) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'staticData' does not exist on type '{}'.
    loading: ownProps.staticData ? false : state.date.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: convertDateToTimestamp(state.date.startDate),
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: convertDateToTimestamp(state.date.endDate),
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    isOpen: state.date.open,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    calendarOpen: state.date.calendarOpen,
    // @ts-expect-error TS(2339) FIXME: Property 'staticData' does not exist on type '{}'.
    minDate: ownProps.staticData ? null : state.date.minDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    maxDate: state.date.maxDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    month: state.date.month,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    presets: state.date.presets,
    // add state here
  }),
  (dispatch) => ({
    open: () => dispatch(actions.open()),
    close: () => dispatch(actions.close()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
    setMonth: (timestamp) => dispatch(actions.setMonth(timestamp)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
    setStartDate: (date) => dispatch(actions.setStartDate(date)),
    clearStartDate: () => dispatch(actions.clearStartDate()),
    clearEndDate: () => dispatch(actions.clearEndDate()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
    setEndDate: (date) => dispatch(actions.setEndDate(date)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
    setDateRange: (start, end) => dispatch(actions.setDateRange(start, end)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'preset' implicitly has an 'any' type.
    selectPreset: (preset) => {
      if (preset.range !== Infinity) {
        dispatch(actions.setDatePreset(preset))
        dispatch(actions.close())
      } else {
        dispatch(actions.openCalendar())
      }
    },
  }),
)(DatePicker)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes, presets } from './reducer'
export { default as middleware } from './middleware'
