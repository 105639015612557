// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('UPGRADE_TO_PRO', {
  UPGRADE: 'UPGRADE',
})

export const actions = {
  upgradeToPro: () => ({
    type: actionTypes.UPGRADE,
  }),
}
