import React from 'react'
import { Button } from '@bufferapp/ui'
import styled from 'styled-components'
import Label from '../Label'
import Title from '../Title'
import Body from '../Body'
import Reasons from '../Reasons'
import { UserIsAdmin } from '~/account'

const Content = styled.main`
  padding-left: 4.5rem;
`

const Page = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`

const CrossSellImage = styled.img`
  align-self: center;
  object-fit: contain;
  object-position: right;
  max-height: 130%;
  margin-top: 1rem;
`

const CrossSell = () => (
  <Page>
    <UserIsAdmin hasPageContent={true}>
      <Content>
        <img
          alt="Buffer Analyze"
          width="150"
          src="https://s3.amazonaws.com/static.buffer.com/images/analyze/analyze-logo.png"
        />
        <Title>Measure, report, and optimize your social media campaigns</Title>
        <Body>
          Great work creating your campaigns in Publish. Start a free Analyze
          trial to see your campaign reports.
        </Body>
        <Reasons />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ size: string; type: string... Remove this comment to see the full error message */}
        <Button
          size="large"
          type="primary"
          label="Start a free 14-day trial"
          onClick={() => {
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            window.location =
              'https://login.buffer.com/signup?product=analyze&plan=premium&cycle=month'
          }}
        />
        <Label />
      </Content>
      <CrossSellImage src="https://s3.amazonaws.com/static.buffer.com/images/analyze/campaigns.png" />
    </UserIsAdmin>
  </Page>
)

export default CrossSell
