import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AddReport from '~/add-report'
import { PostsTable } from '~/shared-components'
import { Table } from '~/shared-components/PostsTable'
import Title from './components/Title'
import GraphQLWrapper, {
  formatQueryVariables,
} from '~/shared-components/GraphQLWrapper'
import QUERY, { dataParser } from './query'

import { metricsConfig } from './metricsConfig'
import { actions } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Content = (props) => {
  if (props.profile.service == 'linkedin') {
    props = {
      ...props,
      metrics: injectProfileInPosts(props.metrics, props.profile),
    }
  }

  return (
    <div id="js-dom-to-png-posts">
      <PostsTable
        {...props}
        postsCounts={[
          { value: '5' },
          { value: '10' },
          { value: '25' },
          { value: '50' },
          { value: 'All' },
        ]}
        addToReportButton={
          <AddReport
            chart="posts"
            state={{
              descending: props.isDescendingSelected,
              sortBy: props.selectedMetric?.apiKey,
              selectedMetric: props.selectedMetric,
              limit: props.activePostsCount,
              searchTerms: props.searchTerms,
            }}
          />
        }
      />
    </div>
  )
}

export class PostsTableWrapper extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'profileService' does not exist on type '... Remove this comment to see the full error message
    if (this.props.profileService !== 'linkedin') {
      // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.fetch()
    }
  }

  render() {
    return <Content {...this.props} />
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PostsTableWrapper.defaultProps = {
  loading: false,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PostsTableWrapper.propTypes = {
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  config: PropTypes.arrayOf().isRequired,
  isDescendingSelected: PropTypes.bool.isRequired,
  selectedMetric: PropTypes.shape({
    key: PropTypes.string,
    apiKey: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  activePostsCount: PropTypes.number.isRequired,
  searchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function getService(state) {
  return state.profiles.selectedProfile
    ? state.profiles.selectedProfile.service
    : ''
}

// @ts-expect-error TS(7006) FIXME: Parameter 'posts' implicitly has an 'any' type.
function injectProfileInPosts(posts, profile) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
  return posts.map((post) => ({
    ...post,
    profile,
  }))
}

// default export = container
export const ReduxComponent = connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'posts' does not exist on type 'DefaultRo... Remove this comment to see the full error message
    loading: state.posts.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    timezone: state.profiles.selectedProfile
      ? // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
        state.profiles.selectedProfile.timezone
      : '',
    metrics: injectProfileInPosts(
      // @ts-expect-error TS(2339) FIXME: Property 'posts' does not exist on type 'DefaultRo... Remove this comment to see the full error message
      state.posts.posts,
      // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
      state.profiles.selectedProfile,
    ),
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    hide: !state.profiles.selectedProfile,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
  }),
)(PostsTableWrapper)

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function TableWithConfig(props) {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
    orderBy: {
      field: props.selectedMetric.key.toUpperCase(),
      order: props.isDescendingSelected ? 'DESC' : 'ASC',
    },
    searchTerms: props.searchTerms,
    limit: props.activePostsCount ? props.activePostsCount : 1000,
  })

  props = {
    ...props,
    config: metricsConfig,
  }

  return (
    <React.Fragment>
      {props.metrics && <Table {...props} />}
      {!props.metrics && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: Table,
          }}
        />
      )}
    </React.Fragment>
  )
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profile: state.profiles.selectedProfile,
    // @ts-expect-error TS(2339) FIXME: Property 'posts' does not exist on type 'DefaultRo... Remove this comment to see the full error message
    selectedMetric: state.posts.selectedMetric,
    config: metricsConfig,
    service: getService(state),
    // @ts-expect-error TS(2339) FIXME: Property 'posts' does not exist on type 'DefaultRo... Remove this comment to see the full error message
    searchTerms: state.posts.searchTerms,
    // @ts-expect-error TS(2339) FIXME: Property 'posts' does not exist on type 'DefaultRo... Remove this comment to see the full error message
    isDescendingSelected: state.posts.isDescendingSelected,
    title: <Title service={getService(state)} />,
    // @ts-expect-error TS(2339) FIXME: Property 'posts' does not exist on type 'DefaultRo... Remove this comment to see the full error message
    searching: state.posts.searching,
    // @ts-expect-error TS(2339) FIXME: Property 'posts' does not exist on type 'DefaultRo... Remove this comment to see the full error message
    activePostsCount: state.posts.activePostsCount,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
    save: (result) => dispatch(actions.save(result)),
    // @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
    selectMetric: ({ metric, descending }) =>
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
      dispatch(actions.selectMetric(metric, descending)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'tags' implicitly has an 'any' type.
    search: (tags) => dispatch(actions.search(tags)),
    // @ts-expect-error TS(7031) FIXME: Binding element 'postsCount' implicitly has an 'an... Remove this comment to see the full error message
    handlePostsCountClick: ({ postsCount }) =>
      dispatch(actions.handlePostsCountClick(postsCount)),
    // @ts-expect-error TS(7031) FIXME: Binding element 'isDescendingSelected' implicitly ... Remove this comment to see the full error message
    handlePostsSortClick: ({ isDescendingSelected }) =>
      dispatch(actions.handlePostsSortClick(isDescendingSelected)),
  }),
)((props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'.
  const variables = formatQueryVariables(props.profile, {
    // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'never... Remove this comment to see the full error message
    startDate: props.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'endDate' does not exist on type 'never'.
    endDate: props.endDate,
    orderBy: {
      // @ts-expect-error TS(2339) FIXME: Property 'selectedMetric' does not exist on type '... Remove this comment to see the full error message
      field: props.selectedMetric.key.toUpperCase(),
      // @ts-expect-error TS(2339) FIXME: Property 'isDescendingSelected' does not exist on ... Remove this comment to see the full error message
      order: props.isDescendingSelected ? 'DESC' : 'ASC',
    },
    // @ts-expect-error TS(2339) FIXME: Property 'searchTerms' does not exist on type 'nev... Remove this comment to see the full error message
    searchTerms: props.searchTerms,
    // @ts-expect-error TS(2339) FIXME: Property 'activePostsCount' does not exist on type... Remove this comment to see the full error message
    limit: props.activePostsCount ? props.activePostsCount : 1000,
  })
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
      {props.profile && (
        <React.Fragment>
          {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
          {props.profile.service === 'linkedin' && (
            <GraphQLWrapper
              // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              {...props}
              // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'.
              timezone={props.profile.timezone}
              graphQlProps={{
                dataParser,
                query: QUERY,
                variables,
                content: Content,
                title: 'Posts Insights',
                // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
                save: (data) => props.save(data),
              }}
            />
          )}
          {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
          {props.profile.service !== 'linkedin' && (
            // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            <ReduxComponent {...props} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
})

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { TableWithConfig as Table }
export { default as Title } from './components/Title'
