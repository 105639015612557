import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import moment from 'moment-timezone'
import styled from 'styled-components'

import Day from './Day'

const Week = styled.div`
  padding: 0;
  margin: 1rem;
  display: flex;
  justify-content: center;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
const getFirstDayOfWeek = (timestamp) => {
  const startOfMonth = moment.unix(timestamp).startOf('month')
  const dayOfWeek = startOfMonth.day()

  if (dayOfWeek !== 1) {
    const daysFromMonday = dayOfWeek - 1
    startOfMonth.subtract(daysFromMonday, 'days')
  }

  return startOfMonth.unix()
}

// @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
const isInValidRange = (start, end, timestamp) => {
  if (start === null) {
    return true
  }
  const date = moment.unix(timestamp)

  // start date should always be inclusive
  const maxStartDate = moment(start).subtract(1, 'days')
  const maxEndDate = moment(end)

  return date.isBetween(maxStartDate, maxEndDate)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
const isInRange = (start, end, timestamp) => {
  const startDate = moment.unix(start)
  const endDate = moment.unix(end)
  const date = moment.unix(timestamp)

  if (startDate.isSame(date, 'day')) {
    return -1
  } else if (
    date.isBetween(startDate, endDate) &&
    start !== null &&
    end !== null
  ) {
    return 0
  } else if (endDate.isSame(date, 'day')) {
    return 1
  }

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
const isSelectedBetween = (start, end, timestamp) =>
  isInRange(start, end, timestamp) === 0
// @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
const isSelectedStart = (start, end, timestamp) =>
  isInRange(start, end, timestamp) === -1
// @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
const isSelectedEnd = (start, end, timestamp) =>
  isInRange(start, end, timestamp) === 1

class Month extends Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'nextProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(nextProps) {
    // @ts-expect-error TS(2339) FIXME: Property 'currentMonth' does not exist on type 'Re... Remove this comment to see the full error message
    if (this.props.currentMonth !== nextProps.currentMonth) {
      ReactTooltip.rebuild()
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'weekList' implicitly has an 'any' type.
  getDays(weekList) {
    // @ts-expect-error TS(2339) FIXME: Property 'handleDayClick' does not exist on type '... Remove this comment to see the full error message
    const handleDayClick = this.props.handleDayClick

    // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
    return weekList.map((day) => (
      <Day {...day} key={day.timestamp} handleClick={handleDayClick} />
    ))
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'unixTimestamp' implicitly has an 'any' ... Remove this comment to see the full error message
  getMonth(unixTimestamp) {
    // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'Reado... Remove this comment to see the full error message
    const { startDate, endDate, maxStartDate, maxEndDate } = this.props

    const monthGrid = this.formatMonth(
      unixTimestamp,
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 5.
      startDate,
      endDate,
      maxStartDate,
      maxEndDate,
    )

    return monthGrid.map((weekList) => {
      const days = this.getDays(weekList)
      // @ts-expect-error TS(2339) FIXME: Property 'timestamp' does not exist on type 'never... Remove this comment to see the full error message
      return <Week key={weekList[0].timestamp}>{days}</Week>
    })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'unixTimestamp' implicitly has an 'any' ... Remove this comment to see the full error message
  formatMonth(unixTimestamp) {
    const month = moment.unix(unixTimestamp).startOf('month').month()
    const year = moment.unix(unixTimestamp).year()
    const m = moment.unix(getFirstDayOfWeek(unixTimestamp))
    const grid = [[]]

    // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'Reado... Remove this comment to see the full error message
    const { startDate, endDate, maxStartDate, maxEndDate } = this.props

    let endOfMonthGrid = false
    let endOfWeek = false
    let endOfMonth = false

    const mMaxStartDate = moment(maxStartDate)

    while (!endOfMonthGrid) {
      const weekIndex = grid.length - 1

      const showTooltip = m.isBefore(mMaxStartDate)

      // @ts-expect-error TS(7006) FIXME: TS2345: Argument of type '{ timestamp: number; day: number; today: boolean; inMonth: boolean; selectedBetween: boolean; selectedStart: boolean; selectedEnd: boolean; isDisabled: boolean; disabledText: string; startDate: any; endDate: any; }' is not assignable to parameter of type 'never'.
      grid[weekIndex].push({
        timestamp: m.unix(),
        day: m.date(),
        today: m.isSame(moment(), 'day'),
        inMonth: m.month() === month,
        selectedBetween: isSelectedBetween(startDate, endDate, m.unix()),
        selectedStart: isSelectedStart(startDate, endDate, m.unix()),
        selectedEnd: isSelectedEnd(startDate, endDate, m.unix()),
        isDisabled: !isInValidRange(maxStartDate, maxEndDate, m.unix()),
        disabledText: showTooltip
          ? `We don't have data prior to ${mMaxStartDate.format('MM/DD/YY')}`
          : '',
        startDate,
        endDate,
      })

      endOfWeek = m.day() === 0
      endOfMonth = m.month() > month || m.year() > year

      if (endOfWeek && endOfMonth) {
        endOfMonthGrid = true
      } else if (endOfWeek) {
        grid.push([])
      }

      m.add(1, 'day')
    }

    return grid
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'currentMonth' does not exist on type 'Re... Remove this comment to see the full error message
    const { currentMonth } = this.props

    return <div>{this.getMonth(currentMonth)}</div>
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Month.defaultProps = {
  startDate: null,
  endDate: null,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Month.propTypes = {
  currentMonth: PropTypes.number.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  maxStartDate: PropTypes.number.isRequired,
  maxEndDate: PropTypes.number.isRequired,
  // Actions
  handleDayClick: PropTypes.func.isRequired,
}

export default Month
