import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import { calculateDifference, snakeCase } from '~/shared-components/utils'

const LABELS = {
  followers: 'Total Followers',
  clicks: 'Clicks',
  comments: 'Comments',
  engagements: 'Engagements',
  engagement_rate: 'Engagement Rate',
  impressions: 'Impressions',
  likes: 'Likes',
  followers_gain_organic: 'New Followers',
  page_views: 'Page Views',
  posts_count: 'Posts',
  shares: 'Shares',
}

export function dataParser({
  // @ts-expect-error TS(7031) FIXME: Binding element 'dailies' implicitly has an 'any' ... Remove this comment to see the full error message
  analyticsDailies: dailies,
  // @ts-expect-error TS(7031) FIXME: Binding element 'totals' implicitly has an 'any' t... Remove this comment to see the full error message
  analyticsTotals: totals,
}) {
  // @ts-expect-error TS(7034) FIXME: Variable 'dailyData' implicitly has type 'any[]' i... Remove this comment to see the full error message
  const dailyData = []
  // @ts-expect-error TS(7034) FIXME: Variable 'totalsData' implicitly has type 'any[]' ... Remove this comment to see the full error message
  const totalsData = []

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  dailies.forEach((item) => {
    const daily = snakeCase(item)
    // @ts-expect-error TS(2339) FIXME: Property 'day' does not exist on type '{}'.
    const day = dayjs(daily.day, 'YYYY-MM-DD').valueOf()
    // @ts-expect-error TS(2339) FIXME: Property 'previous_day' does not exist on type '{}... Remove this comment to see the full error message
    const previousPeriodDay = dayjs(daily.previous_day, 'YYYY-MM-DD').valueOf()
    // @ts-expect-error TS(7034) FIXME: Variable 'metrics' implicitly has type 'any[]' in ... Remove this comment to see the full error message
    const metrics = []

    Object.keys(LABELS).forEach((key) => {
      // @ts-expect-error TS(2339) FIXME: Property 'organic' does not exist on type '{}'.
      const organic = snakeCase(daily.organic)
      // @ts-expect-error TS(2339) FIXME: Property 'previous_organic' does not exist on type... Remove this comment to see the full error message
      const previous_organic = snakeCase(daily.previous_organic)
      metrics.push({
        diff: previous_organic
          ? // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            calculateDifference(organic[key], previous_organic[key])
          : 0,
        key,
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        label: LABELS[key],
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        previousValue: previous_organic ? previous_organic[key] : 0,
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        value: organic[key],
      })
    })

    dailyData.push({
      day,
      // @ts-expect-error TS(7005) FIXME: Variable 'metrics' implicitly has an 'any[]' type.
      metrics,
      // @ts-expect-error TS(2339) FIXME: Property 'previous_organic' does not exist on type... Remove this comment to see the full error message
      previousPeriodDay: daily.previous_organic ? previousPeriodDay : null,
    })
  })

  const organic = snakeCase(totals.organic)
  const previous_organic = snakeCase(totals.previousOrganic)
  Object.keys(LABELS).forEach((key) => {
    totalsData.push({
      diff: previous_organic
        ? // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          calculateDifference(organic[key], previous_organic[key])
        : 0,
      key,
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      label: LABELS[key],
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      previousValue: previous_organic ? previous_organic[key] : 0,
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      value: organic[key],
    })
  })

  return {
    // @ts-expect-error TS(7005) FIXME: Variable 'dailyData' implicitly has an 'any[]' typ... Remove this comment to see the full error message
    daily: dailyData,
    // @ts-expect-error TS(7005) FIXME: Variable 'totalsData' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    totals: totalsData,
  }
}

const GET_DAILIES = gql`
  query AnalyticsDailies(
    $serviceId: String!
    $serviceType: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    analyticsDailies(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
      getPreviousPeriod: true
    ) {
      day
      previousDay
      organic {
        clicks
        comments
        engagementRate
        engagements
        followers
        followersGainOrganic
        impressions
        likes
        pageViews
        postsCount
        shares
      }
      previousOrganic {
        clicks
        comments
        engagementRate
        engagements
        followers
        followersGainOrganic
        impressions
        likes
        pageViews
        postsCount
        shares
      }
    }
    analyticsTotals(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
      getPreviousPeriod: true
    ) {
      organic {
        clicks
        comments
        engagementRate
        engagements
        followers
        followersGainOrganic
        impressions
        likes
        pageViews
        postsCount
        shares
      }
      previousOrganic {
        clicks
        comments
        engagementRate
        engagements
        followers
        followersGainOrganic
        impressions
        likes
        pageViews
        postsCount
        shares
      }
    }
  }
`

export default GET_DAILIES
