import React from 'react'
import PropTypes from 'prop-types'
import { ChartTitle } from '~/shared-components'

// @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
const Title = ({ forReport }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: string; forReport: any; }' is no... Remove this comment to see the full error message
  <ChartTitle forReport={forReport}>Answers overview</ChartTitle>
)

Title.propTypes = {
  forReport: PropTypes.bool,
}

Title.defaultProps = {
  forReport: false,
}

export default Title
