import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import React from 'react'

const Title = styled.header`
  max-width: 45ch;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ children }) => (
  <Title>
    <Text type="h2" color="black">
      {children}
    </Text>
  </Title>
)
