import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  TotalsGrid,
  Container,
  CHANNEL_TYPES,
} from '~/shared-components'
import AddReport from '~/add-report'
import Title from '../Title'

const ExportContainer = styled.div``

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
export const CampaignPulseGrid = ({ metrics }) => {
  return (
    <Container>
      <ExportContainer id="js-dom-to-png-campaign-pulse">
        <TotalsGrid metrics={metrics} />{' '}
      </ExportContainer>
    </Container>
  )
}

class CampaignPulse extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.fetch(this.props.campaign.id)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    // @ts-expect-error TS(2339) FIXME: Property 'campaign' does not exist on type 'Readon... Remove this comment to see the full error message
    if (prevProps.campaign.id !== this.props.campaign.id) {
      // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.fetch(this.props.campaign.id)
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'metrics' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { metrics, loading, hasError, campaign } = this.props

    let content = null

    if (hasError) {
      content = null
    } else if (loading) {
      // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
      content = <Loading active noBorder />
    } else if (metrics.length === 0 || metrics[0].value === null) {
      content = (
        <NoData
          chartName="campaignPulse"
          channelType={CHANNEL_TYPES.aggregates}
        />
      )
    } else {
      content = <CampaignPulseGrid metrics={metrics} />
    }
    return (
      <ChartCard hasError={hasError}>
        <ChartHeader>
          <Title />
          <AddReport
            chart="campaign-pulse"
            state={{
              campaignId: campaign.id,
            }}
          />
        </ChartHeader>
        {content}
      </ChartCard>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
CampaignPulse.defaultProps = {
  metrics: [],
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
CampaignPulse.propTypes = {
  metrics: PropTypes.array,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  fetch: PropTypes.func.isRequired,
}

export default CampaignPulse
