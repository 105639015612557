import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SingleChannel from './SingleChannel'
import SidebarHeader from '@bufferapp/ui/SidebarHeader'

const Group = styled.div`
  margin: 0 0 2rem;
`

const Section = styled.div`
  div:first-child {
    margin-bottom: 8px;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'channels' implicitly has an 'any'... Remove this comment to see the full error message
const Channels = ({ channels, ...props }) => (
  <Group>
    <Section>
      <SidebarHeader title="Channels" />
      <SingleChannel service={'facebook'} channels={channels} {...props} />
      <SingleChannel service={'instagram'} channels={channels} {...props} />
      <SingleChannel service={'twitter'} channels={channels} {...props} />
      <SingleChannel service={'linkedin'} channels={channels} {...props} />
      {props.isGlobalUser && (
        <SingleChannel service={'shopify'} channels={channels} {...props} />
      )}
    </Section>
  </Group>
)

Channels.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      profileId: PropTypes.string,
      service: PropTypes.string,
      supportedTabs: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  isGlobalUser: PropTypes.bool,
  route: PropTypes.string.isRequired,
}

Channels.defaultProps = {
  channels: [],
}

export default Channels
