import { gql } from '@apollo/client'

// @ts-expect-error TS(7006) FIXME: Parameter 'object' implicitly has an 'any' type.
const convertToCleanObject = (object) => {
  const metrics = {}
  // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
  object.forEach((x) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    metrics[x.key] = x.value
  })
  return metrics
}

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export function dataParser(data) {
  const answers = {
    day: {
      best: data.analyticsAnswers.day.best,
      metrics: convertToCleanObject(data.analyticsAnswers.day.metrics),
    },
    type: {
      best: data.analyticsAnswers.type.best,
      metrics: convertToCleanObject(data.analyticsAnswers.type.metrics),
    },
    frequency: {
      best: data.analyticsAnswers.frequency.best,
      metrics: convertToCleanObject(data.analyticsAnswers.frequency.metrics),
    },
  }
  return { answers, ...answers }
}

const GET_ANSWERS = gql`
  query AnalyticsAnswers(
    $serviceId: String!
    $serviceType: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    analyticsAnswers(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
    ) {
      day {
        best
        metrics {
          key
          value
        }
      }
      type {
        best
        metrics {
          key
          value
        }
      }
      frequency {
        best
        metrics {
          key
          value
        }
      }
    }
  }
`

export default GET_ANSWERS
