import React from 'react'
import styled from 'styled-components'
import BufferIcon from '@bufferapp/ui/Icon/Icons/Buffer'

const WatermarkContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 0;

  svg {
    fill: #e0e0e0;
  }
`

const Watermark = () => (
  <WatermarkContainer>
    <BufferIcon size="large" />
  </WatermarkContainer>
)

export default Watermark
