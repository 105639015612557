import React from 'react'
import ChartTitle from '../ChartTitle'
import Select from '@bufferapp/ui/Select'
import styled, { type FlattenSimpleInterpolation, css } from 'styled-components'
import ChevronDownIcon from '@bufferapp/ui/Icon/Icons/ChevronDown'
import { blue } from '@bufferapp/ui/style/colors'

const VariableText = styled.div<{ forReport?: boolean }>`
  border-bottom: ${(props): string =>
    props.forReport ? 'none' : '2px solid #000000'};
  display: flex;
  align-items: center;

  ${(props): FlattenSimpleInterpolation =>
    !props.forReport
      ? css`
          :hover {
            color: ${blue};
            border-bottom: 2px solid ${blue};
            cursor: pointer;

            svg {
              fill: ${blue};
            }
          }
        `
      : css``}
`

interface TitleDropdownProps {
  forReport: boolean
  loading: boolean
  selectedMetricLabel: string
  items: any
  children: any
}

const TitleDropdown = ({
  forReport,
  loading,
  selectedMetricLabel,
  items,
  children,
}: TitleDropdownProps): JSX.Element => {
  const renderSelector = (onButtonClick: () => void): JSX.Element => {
    const lowercasedMetric = selectedMetricLabel.toLowerCase()
    return (
      <VariableText onClick={onButtonClick}>
        {lowercasedMetric}
        <ChevronDownIcon />
      </VariableText>
    )
  }

  if (forReport || loading) {
    const lowercasedMetric = selectedMetricLabel.toLowerCase()
    return (
      <ChartTitle>
        {children}{' '}
        <VariableText forReport={forReport}>{lowercasedMetric}</VariableText>
      </ChartTitle>
    )
  } else {
    return (
      <ChartTitle>
        {children}{' '}
        <Select
          // @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
          customButton={(onButtonClick): void => renderSelector(onButtonClick)}
          items={items}
          hideSearch
          // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' ... Remove this comment to see the full error message
          onSelectClick={(selectedItem): void => selectedItem.onItemClick()}
        />
      </ChartTitle>
    )
  }
}

export default TitleDropdown
