import React from 'react'
import { useAccount } from '~/account'
import { EmptyState } from '~/shared-components'

// @ts-expect-error TS(7031) FIXME: Binding element 'hasPageContent' implicitly has an... Remove this comment to see the full error message
const UserIsAdmin = ({ hasPageContent, children }) => {
  const account = useAccount()

  // @ts-expect-error TS(2339) FIXME: Property 'role' does not exist on type '{}'.
  if (account?.currentOrganization?.role === 'admin') {
    return children
  }

  if (hasPageContent) {
    return (
      <EmptyState
        header="This page does not exist"
        description={'Nothing to see here'}
      />
    )
  }

  return null
}

export default UserIsAdmin
