import styled from 'styled-components'
import { Button } from '@bufferapp/ui'
import { white } from '@bufferapp/ui/style/colors'

export const ButtonStyled = styled(Button)`
  margin-left: auto;
`

export const Banner = styled.div`
  display: flex;
  background-color: #e0364f;
  color: ${white};
  font-size: 14px;
  font-weight: 500;
  padding: 0 12px;
  align-items: center;

  & > svg {
    position: relative;
    margin-right: 8px;
    top: -1px;
  }

  button {
    color: ${white};
    margin-left: auto;
    padding: 0px;
    height: 16px;
  }

  a {
    color: white;
  }
`
