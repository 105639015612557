import React from 'react'
import PropTypes from 'prop-types'
import { TitleDropdown } from '~/shared-components'

class Title extends React.Component {
  getItems = () => {
    return [
      {
        id: '1',
        title: 'Customers',
        // @ts-expect-error TS(2339) FIXME: Property 'onSelectMetric' does not exist on type '... Remove this comment to see the full error message
        onItemClick: () => this.props.onSelectMetric('customers'),
        // @ts-expect-error TS(2339) FIXME: Property 'selectedMetric' does not exist on type '... Remove this comment to see the full error message
        selected: this.props.selectedMetric === 'customers',
      },
      {
        id: '2',
        title: 'Units Sold',
        // @ts-expect-error TS(2339) FIXME: Property 'onSelectMetric' does not exist on type '... Remove this comment to see the full error message
        onItemClick: () => this.props.onSelectMetric('units_sold'),
        // @ts-expect-error TS(2339) FIXME: Property 'selectedMetric' does not exist on type '... Remove this comment to see the full error message
        selected: this.props.selectedMetric === 'units_sold',
      },
      {
        id: '3',
        title: 'Total Sales',
        // @ts-expect-error TS(2339) FIXME: Property 'onSelectMetric' does not exist on type '... Remove this comment to see the full error message
        onItemClick: () => this.props.onSelectMetric('sales'),
        // @ts-expect-error TS(2339) FIXME: Property 'selectedMetric' does not exist on type '... Remove this comment to see the full error message
        selected: this.props.selectedMetric === 'sales',
      },
    ]
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { loading, forReport } = this.props
    return (
      <TitleDropdown
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        selectedMetric={this.props.selectedMetric}
        items={this.getItems()}
        loading={loading}
        forReport={forReport}
      >
        Top products based on{' '}
      </TitleDropdown>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Title.propTypes = {
  forReport: PropTypes.bool,
  loading: PropTypes.bool,
  selectedMetric: PropTypes.string,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Title.defaultProps = {
  forReport: false,
  loading: true,
  selectedMetric: 'Visitors',
}

export default Title
