import React from 'react'
import PropTypes from 'prop-types'
import { ChartTitle } from '~/shared-components'

interface TitleProps {
  service: string
}

const Title = ({ service }: TitleProps): JSX.Element => (
  <ChartTitle>
    {service === 'twitter' ? 'Tweet insights' : 'Post insights'}
  </ChartTitle>
)
Title.propTypes = {
  service: PropTypes.string.isRequired,
  forReport: PropTypes.bool,
}

Title.defaultProps = {
  forReport: false,
}

export default Title
