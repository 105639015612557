import { Text } from '@bufferapp/ui'
import React from 'react'
import styled from 'styled-components'

const Label = styled.span`
  margin-top: 0.5rem;
  display: block;
`

export default () => (
  <Label>
    <Text type="label">$50/mo. Cancel anytime.</Text>
  </Label>
)
