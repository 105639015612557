import React from 'react'
import { TitleDropdown, ChartTitle } from '~/shared-components'
import type { Metric } from '~/utils/types'

interface TitleProps {
  forReport: boolean
  loading: boolean
  selectedMetricLabel: string
  metrics: Metric[]
  onSelectMetric: (metric: string) => void
}

interface MappedMetricsProps {
  metrics: Metric[]
  onSelectMetric: (metric: string) => void
}

interface MappedMetricsReturn {
  id: number
  title: string
  onItemClick: () => void
  selected: boolean
}

const Title = ({
  forReport,
  metrics,
  selectedMetricLabel,
  loading,
  onSelectMetric,
}: TitleProps): JSX.Element => {
  const mappedMetrics = ({
    metrics,
    onSelectMetric,
  }: MappedMetricsProps): MappedMetricsReturn[] => {
    return metrics.map((metric, index) => ({
      id: index,
      title: metric.label,
      onItemClick: () => onSelectMetric(metric.label),
      selected: selectedMetricLabel === metric.label,
    }))
  }

  if (forReport) {
    return <ChartTitle>{selectedMetricLabel}</ChartTitle>
  } else if (loading) {
    return <ChartTitle>Metrics insights</ChartTitle>
  } else {
    return (
      <TitleDropdown
        selectedMetricLabel={selectedMetricLabel}
        items={mappedMetrics({ metrics, onSelectMetric })}
        loading={loading}
        forReport={forReport}
      >
        Metrics insights for{' '}
      </TitleDropdown>
    )
  }
}

export default Title
