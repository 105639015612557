import { connect } from 'react-redux'
import AddReport from './components/AddReport'
import { actions } from './actions'
import { actions as reportListActions } from '~/report-list'

// default export = container
export default connect(
  (state) => ({
    translations: {
      // @ts-expect-error TS(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
      ...state.i18n.translations.reports,
    },
    // @ts-expect-error TS(2339) FIXME: Property 'reportList' does not exist on type 'Defa... Remove this comment to see the full error message
    reports: state.reportList.reports,
  }),
  (dispatch, props) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
    createReport: (name) =>
      // @ts-expect-error TS(2339) FIXME: Property 'chart' does not exist on type '{}'.
      dispatch(actions.createReport(name, props.chart, props.state)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    addToReport: (id) =>
      // @ts-expect-error TS(2339) FIXME: Property 'chart' does not exist on type '{}'.
      dispatch(actions.addToReport(id, props.chart, props.state)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    fetchReportList: (id) => dispatch(reportListActions.fetch(id)),
  }),
)(AddReport)

export { actions, actionTypes } from './actions'
export { default as middleware } from './middleware'
