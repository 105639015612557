// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('CSV_EXPORT', {
  EXPORT_TO_CSV_START: 'EXPORT_TO_CSV_START',
  EXPORT_TO_CSV_END: 'EXPORT_TO_CSV_END',
  EXPORT_CHART: 'CSV_EXPORT_CHART',
})

const initialState = {
  exporting: false,
  charts: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXPORT_CHART:
      if (!action.data) {
        return {
          ...state,
          exporting: true,
        }
      }
      return {
        ...state,
        exporting: true,
        charts: state.charts.concat([
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          {
            filename: action.filename,
            data: action.data,
          },
        ]),
      }
    case actionTypes.EXPORT_TO_CSV_END:
      return initialState
    default:
      return state
  }
}

export const actions = {
  endExportToCSV: () => ({
    type: actionTypes.EXPORT_TO_CSV_END,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'filename' implicitly has an 'any' type.
  exportToCSV: (filename) => ({
    type: actionTypes.EXPORT_TO_CSV_START,
    filename,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'filename' implicitly has an 'any' type.
  exportChart: (filename, data) => ({
    type: actionTypes.EXPORT_CHART,
    filename,
    data,
  }),
}
