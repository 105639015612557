import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  CHANNEL_TYPES,
} from '~/shared-components'
import AddReport from '~/add-report'
import GraphQLWrapper, {
  formatQueryVariables,
} from '~/shared-components/GraphQLWrapper'

import Chart from '../Chart'
import Title from '../Title'
import PeriodToggle from '../PeriodToggle'
import QUERY, { dataParser } from '../../query'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
`

const Header = styled.div`
  position: relative;
  top: 6px;
  right: 16px;
`

const ChartContainer = styled.div`
  padding: 0 0 24px;
  margin: 0 auto;
  position: relative;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const MainChart = (props) => {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  if (props.daily && props.daily.length === 0) {
    return (
      <Container>
        <NoData chartName="compare" channelType={CHANNEL_TYPES.aggregates} />
      </Container>
    )
  }

  return (
    <div id="js-dom-to-png-compare">
      {props.daily && <Chart {...props} />}
      {!props.daily && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: Chart,
          }}
        />
      )}
    </div>
  )
}

MainChart.propTypes = {
  daily: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string,
          diff: PropTypes.number,
          label: PropTypes.string,
          value: PropTypes.number,
          previousValue: PropTypes.number,
          postsCount: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  timezone: PropTypes.string.isRequired,
  profileService: PropTypes.string.isRequired,
  visualizePreviousPeriod: PropTypes.bool.isRequired,
  selectedMetricLabel: PropTypes.string.isRequired,
}

const Content = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'daily' implicitly has an 'any' ty... Remove this comment to see the full error message
  daily,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedMetricLabel' implicitly h... Remove this comment to see the full error message
  selectedMetricLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'totals' implicitly has an 'any' t... Remove this comment to see the full error message
  totals,
  // @ts-expect-error TS(7031) FIXME: Binding element 'timezone' implicitly has an 'any'... Remove this comment to see the full error message
  timezone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'visualizePreviousPeriod' implicit... Remove this comment to see the full error message
  visualizePreviousPeriod,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectMetric' implicitly has an '... Remove this comment to see the full error message
  selectMetric,
  // @ts-expect-error TS(7031) FIXME: Binding element 'togglePreviousPeriod' implicitly ... Remove this comment to see the full error message
  togglePreviousPeriod,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileService' implicitly has an... Remove this comment to see the full error message
  profileService,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
  profile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'missingPreviousPeriod' implicitly... Remove this comment to see the full error message
  missingPreviousPeriod,
  // @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
  forReport,
}) => {
  let content = null
  let header = null
  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; large: true;... Remove this comment to see the full error message
    content = <Loading active noBorder large />
  } else {
    content = (
      <MainChart
        daily={daily}
        selectedMetricLabel={selectedMetricLabel}
        timezone={timezone}
        visualizePreviousPeriod={visualizePreviousPeriod}
        profileService={profileService}
        // @ts-expect-error TS(2322) FIXME: Type '{ daily: any; selectedMetricLabel: any; time... Remove this comment to see the full error message
        profile={profile}
        dailyData={daily}
      />
    )
    if (daily.length > 0) {
      header = (
        <Header>
          <PeriodToggle
            disabled={missingPreviousPeriod}
            handleClick={togglePreviousPeriod}
            active={visualizePreviousPeriod}
          />
        </Header>
      )
    }
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title
          onSelectMetric={selectMetric}
          selectedMetricLabel={selectedMetricLabel}
          forReport={forReport}
          loading={loading}
          metrics={totals}
        />
        <div style={{ display: 'flex' }}>
          {header}
          <AddReport
            chart="compare"
            state={{
              visualizePreviousPeriod,
              selectedMetricLabel,
              profileService,
            }}
          />
        </div>
      </ChartHeader>
      <ChartContainer>{content}</ChartContainer>
    </ChartCard>
  )
}

Content.defaultProps = {
  loading: true,
  selectedMetricLabel: '',
  visualizePreviousPeriod: false,
  profileService: '',
}

Content.propTypes = {
  loading: PropTypes.bool,
  daily: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string,
          diff: PropTypes.number,
          label: PropTypes.string,
          value: PropTypes.number,
          previousValue: PropTypes.number,
          postsCount: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  selectedMetricLabel: PropTypes.string,
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      diff: PropTypes.number,
      label: PropTypes.string,
      previousValue: PropTypes.number,
      value: PropTypes.number,
    }),
  ).isRequired,
  timezone: PropTypes.string.isRequired,
  visualizePreviousPeriod: PropTypes.bool,
  profileService: PropTypes.string,
  // actions
  selectMetric: PropTypes.func.isRequired,
  togglePreviousPeriod: PropTypes.func.isRequired,
  missingPreviousPeriod: PropTypes.bool.isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function CompareChart(props) {
  const variables = formatQueryVariables(props.profile, {
    startDate: props.startDate,
    endDate: props.endDate,
  })

  return (
    <React.Fragment>
      {props.profile.service !== 'linkedin' && <Content {...props} />}
      {props.profile.service === 'linkedin' && (
        <GraphQLWrapper
          {...props}
          graphQlProps={{
            dataParser,
            query: QUERY,
            variables,
            content: Content,
            save: props.save,
            title: 'Metrics Insights for ...',
          }}
        />
      )}
    </React.Fragment>
  )
}

export default CompareChart
