import PropTypes from 'prop-types'
import React from 'react'
import reactDOM from 'react-dom/server'
import Text from '@bufferapp/ui/Text'
import { blueLight, grayDark } from '@bufferapp/ui/style/colors'
import { DemographicTooltip, TruncatedNumber } from '~/shared-components'

const COLOR = blueLight

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function formatGroupLabel(label) {
  return label.replace('Your ', '').toLowerCase()
}

// @ts-expect-error TS(7006) FIXME: Parameter 'cities' implicitly has an 'any' type.
function formatSeries(cities, groupLabel) {
  const color = COLOR

  // @ts-expect-error TS(7006) FIXME: Parameter 'city' implicitly has an 'any' type.
  const data = cities.slice(0, 5).map((city) => ({
    ...city,
    color,
    groupLabel,
    name: city.label.split(',')[0],
    percentage: city.percentage,
    y: city.value,
  }))

  return [
    {
      data,
    },
  ]
}

// @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
function extractMetrics(group) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  return group.metrics.find((metric) => metric.key === 'city').values
}

// @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
export default (group) => {
  const cities = extractMetrics(group)
  return {
    title: null,
    chart: {
      type: 'bar',
      plotBorderWidth: 0,
      height: 250,
    },
    xAxis: {
      gridLineWidth: 0,
      labels: {
        align: 'right',
        style: {
          color: grayDark,
          'font-size': '14px',
          'font-weight': '500',
          'font-family': 'Roboto, sans serif',
          'white-space': 'nowrap',
        },
      },
      lineColor: '#F3F5F7',
      lineWidth: 2,
      maxPadding: 0,
      minPadding: 0,
      minorGridLineWidth: 0,
      tickLength: 0,
      title: { text: null },
      type: 'category',
    },
    yAxis: {
      align: 'right',
      endOnTick: true,
      gridLineColor: '#F3F5F7',
      gridLineWidth: 2,
      lineColor: '#F3F5F7',
      maxPadding: 0.1,
      minPadding: 0.1,
      showLastLabel: true,
      title: { text: null },
      x: -15,
      y: 4,
      labels: {
        formatter() {
          return reactDOM.renderToStaticMarkup(
            // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type '{ formatt... Remove this comment to see the full error message
            <TruncatedNumber>{this.value}</TruncatedNumber>,
          )
        },
        style: {
          color: grayDark,
          'font-size': '14px',
          'font-weight': '500',
          'font-family': 'Roboto, sans serif',
          'white-space': 'nowrap',
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: 7,
      borderWidth: 0,
      formatter() {
        // @ts-expect-error TS(2339) FIXME: Property 'point' does not exist on type '{ backgro... Remove this comment to see the full error message
        return reactDOM.renderToStaticMarkup(<ChartTooltip {...this.point} />)
      },
      followPointer: false,
      shadow: false,
      useHTML: true,
    },
    series: formatSeries(cities, formatGroupLabel(group.label)),
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
const ChartTooltip = ({ value, label, groupLabel, percentage }) => (
  <DemographicTooltip title={label}>
    <Text type="label" color="white">
      <TruncatedNumber>{value}</TruncatedNumber> {groupLabel} (
      <TruncatedNumber showPercentSign>{percentage}</TruncatedNumber>)
    </Text>
  </DemographicTooltip>
)

ChartTooltip.propTypes = {
  groupLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
