import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'
import { connect } from 'react-redux'
import AddReport from '~/add-report'
import { PostsTable } from '~/shared-components'
import { Table } from '~/shared-components/PostsTable'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'

import Title from './components/Title'
import config from './metricsConfig'
import reducer, { actions } from './reducer'
import middleware from './middleware'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function getExportState(props) {
  const state = {
    ...props,
  }

  delete state.metrics
  return state
}

export class PostsTableWrapper extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.fetch()
  }

  render() {
    return (
      <div id="js-dom-to-png-campaign-posts-table">
        <PostsTable
          {...this.props}
          // @ts-expect-error TS(2322) FIXME: Type '{ postsCounts: { value: string; }[]; addToRe... Remove this comment to see the full error message
          postsCounts={[
            { value: '5' },
            { value: '10' },
            { value: '25' },
            { value: '50' },
            { value: 'All' },
          ]}
          addToReportButton={
            <AddReport
              chart="campaign-posts-table"
              state={getExportState(this.props)}
            />
          }
        />
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PostsTableWrapper.defaultProps = {
  campaignId: null,
  config,
  loading: false,
  searchTerms: [],
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PostsTableWrapper.propTypes = {
  activePostsCount: PropTypes.number.isRequired,
  campaignId: PropTypes.string,
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  config: PropTypes.arrayOf(),
  isDescendingSelected: PropTypes.bool.isRequired,
  searchTerms: PropTypes.arrayOf(PropTypes.string),
  selectedMetric: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
}

// default export = container
export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsTable' does not exist on ty... Remove this comment to see the full error message
    activePostsCount: state.campaignPostsTable.limit,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignSelector' does not exist on type... Remove this comment to see the full error message
    campaignId: state.campaignSelector.selectedCampaign.id,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsTable' does not exist on ty... Remove this comment to see the full error message
    isDescendingSelected: state.campaignPostsTable.isDescendingSelected,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsTable' does not exist on ty... Remove this comment to see the full error message
    isDropdownOpen: state.campaignPostsTable.isDropdownOpen,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsTable' does not exist on ty... Remove this comment to see the full error message
    loading: state.campaignPostsTable.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsTable' does not exist on ty... Remove this comment to see the full error message
    metrics: state.campaignPostsTable.posts,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsTable' does not exist on ty... Remove this comment to see the full error message
    searchTerms: state.campaignPostsTable.searchTerms,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsTable' does not exist on ty... Remove this comment to see the full error message
    searching: state.campaignPostsTable.searching,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPostsTable' does not exist on ty... Remove this comment to see the full error message
    selectedMetric: state.campaignPostsTable.selectedMetric,
    timezone: dayjs.tz.guess(),
    title: <Title />,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
    selectMetric: ({ metric, descending }) =>
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
      dispatch(actions.selectMetric(metric, descending)),
    toggleDropdown: () => dispatch(actions.toggleDropdown()),
    // @ts-expect-error TS(7031) FIXME: Binding element 'postsCount' implicitly has an 'an... Remove this comment to see the full error message
    handlePostsCountClick: ({ postsCount }) =>
      dispatch(actions.handlePostsCountClick(postsCount)),
    // @ts-expect-error TS(7031) FIXME: Binding element 'isDescendingSelected' implicitly ... Remove this comment to see the full error message
    handlePostsSortClick: ({ isDescendingSelected }) =>
      dispatch(actions.handlePostsSortClick(isDescendingSelected)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'tags' implicitly has an 'any' type.
    search: (tags) => dispatch(actions.search(tags)),
    fetch: () => dispatch(actions.fetch()),
  }),
)(PostsTableWrapper)

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function TableWithConfig(props) {
  return <Table {...props} config={config} />
}

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { TableWithConfig as Table }
export { default as Title } from './components/Title'

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('campaignPostsTable', reducer)
addMiddleware(middleware)
