import React from 'react'
import { connect } from 'react-redux'
import PostsSummaryTable from './components/PostsSummaryTable'
import { actions } from './reducer'

class ReduxWrapper extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'profileService' does not exist on type '... Remove this comment to see the full error message
    if (this.props.profileService !== 'linkedin') {
      // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.fetch()
    }
  }

  render() {
    return <PostsSummaryTable {...this.props} />
  }
}

const ReduxComponent = connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'postsSummary' does not exist on type 'De... Remove this comment to see the full error message
    loading: state.postsSummary.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'postsSummary' does not exist on type 'De... Remove this comment to see the full error message
    metrics: state.postsSummary.metrics,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
  }),
)(ReduxWrapper)

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profile: state.profiles.selectedProfile,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
    save: (result) => dispatch(actions.save(result)),
  }),
)((props) => (
  <React.Fragment>
    {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
    {props.profile && (
      <React.Fragment>
        {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
        {props.profile.service === 'linkedin' && (
          // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          <PostsSummaryTable {...props} metrics={[]} loading />
        )}
        {/* @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'never'. */}
        {props.profile.service !== 'linkedin' && <ReduxComponent {...props} />}
      </React.Fragment>
    )}
  </React.Fragment>
))

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { Table } from './components/PostsSummaryTable'
export { default as Title } from './components/Title'
