import { gql } from '@apollo/client'

const GET_HASHTAGS_ANALYSIS = gql`
  query AnalyticsHashtags(
    $serviceId: String!
    $serviceType: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    analyticsHashtags(
      serviceId: $serviceId
      serviceType: $serviceType
      startDate: $startDate
      endDate: $endDate
    ) {
      displayName
      postsCount
      avgEngagementRate
      avgImpressions
    }
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const dataParser = (data, location) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'singleHashtag' implicitly has an 'any' ... Remove this comment to see the full error message
  const hashtags = data.analyticsHashtags.map((singleHashtag) => {
    return {
      primary_metric: singleHashtag.avgImpressions,
      secondary_metric: singleHashtag.avgEngagementRate,
      display_name: singleHashtag.displayName,
      posts_count: singleHashtag.postsCount,
    }
  })

  return { hashtags }
}

export default GET_HASHTAGS_ANALYSIS
