import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import ArrowIcon from '../../../ArrowIcon'
import TruncatedNumber from '../../../TruncatedNumber'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`

const Value = styled.div`
  display: flex;
  margin-left: 2px;

  label {
    color: ${(props) => props.color};
    font-weight: bold;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'diff' implicitly has an 'any' typ... Remove this comment to see the full error message
const Diff = ({ diff }) => {
  if (diff === null || diff === 0) {
    return null
  }
  let color
  if (diff > 0) {
    color = '#18DB65'
  } else if (diff < 0) {
    color = '#E0364F'
  } else {
    color = 'nevada'
  }

  return (
    <Container>
      <ArrowIcon diff={diff} />
      <Value color={color}>
        <Text type="label">
          <TruncatedNumber absoluteValue shorterOption>
            {diff}
          </TruncatedNumber>
          %
        </Text>
      </Value>
    </Container>
  )
}

Diff.defaultProps = {
  diff: null,
}

Diff.propTypes = {
  diff: PropTypes.number,
}

export default Diff
