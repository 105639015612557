import dayjs from 'dayjs'

// @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
const createChartFilename = (chart, date) => {
  const { startDate, endDate } = date
  const fileDateFormat = 'YYYYMMDD'
  const startDateFormatted = dayjs(startDate, 'MM/DD/YYYY').format(
    fileDateFormat,
  )
  const endDateFormatted = dayjs(endDate, 'MM/DD/YYYY').format(fileDateFormat)

  return `${chart.filename}-${startDateFormatted}-to-${endDateFormatted}.csv`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
const getCSVHeader = (chart) =>
  Object.keys(chart).reduce((header, key) => `${header},${key}`)

// @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
const getCSVLine = (chart, index) => {
  const keys = Object.keys(chart)
  return keys.reduce((line, key, idx) => {
    const value = chart[key][index] !== undefined ? chart[key][index] : ''
    if (idx === 1) {
      return `${chart[line][index]},${value}`
    }
    return `${line},${value}`
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
const getCSVBody = (chart) => {
  const keys = Object.keys(chart)
  let body
  if (chart[keys[0]].length) {
    // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
    body = chart[keys[0]].map((_, index) => getCSVLine(chart, index)).join('\n')
  } else {
    body = keys.reduce((line, key, index) => {
      if (index === 1) {
        return `${chart[line]},${chart[key]}`
      }
      return `${line},${chart[key]}`
    })
  }
  return body
}

// @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
const convertChartToCSV = (chart) =>
  `${getCSVHeader(chart)}\n${getCSVBody(chart)}`

// @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
const generateCSV = (chart, date) => {
  const metricKeys = Object.keys(chart.data)
  const hasMetrics = metricKeys.length > 0
  if (!hasMetrics) {
    return {
      filename: null,
      csv: null,
    }
  }

  return {
    filename: createChartFilename(chart, date),
    csv: convertChartToCSV(chart.data),
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'charts' implicitly has an 'any' type.
export default (charts, date) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
  Promise.all(charts.map((chart) => generateCSV(chart, date))).then((csvs) =>
    csvs.filter((csv) => csv !== null),
  )
