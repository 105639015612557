import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import NavSidebar from '~/nav-sidebar'

export const Container = styled.div`
  position: relative;
  padding: 0 16px 24px;
  box-sizing: border-box;
  width: 100%;
`

export const LayoutPage = styled.div`
  display: grid;
  grid-template-columns: 200px minmax(0.75rem, auto) minmax(920px, 1220px) minmax(
      0.75rem,
      auto
    );
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'sidebar left toolbar right'
    'sidebar left contents right';
  width: 100%;
  height: 100%;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'full' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.full &&
    css`
      grid-template-rows: 100%;
      grid-template-areas: 'sidebar left contents right';
    `}
`

export const LayoutSidebar = styled.div`
  grid-area: sidebar;
`

export const LayoutLeft = styled.div`
  grid-area: left;
  /* used as a gutter for flexible layout */
`

export const LayoutRight = styled.div`
  grid-area: right;
  /* used as a gutter for flexible layout */
`

export const LayoutToolbar = styled.div`
  grid-area: toolbar;
  padding: 0.75rem 0 0;
`

export const LayoutContents = styled.div`
  grid-area: contents;
  display: flex;
  align-items: stretch;
  justify-items: stretch;
  padding: 0.75rem 0 0;
  overflow-y: auto;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const Page = ({ children, toolbar, location }) => (
  <LayoutPage>
    <LayoutSidebar>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ route: any; }' is not assignable to type '... Remove this comment to see the full error message */}
      <NavSidebar route={location.pathname} />
    </LayoutSidebar>
    <LayoutLeft />
    {toolbar && <LayoutToolbar>{toolbar}</LayoutToolbar>}
    <LayoutContents>{children}</LayoutContents>
    <LayoutRight />
  </LayoutPage>
)

Page.defaultProps = {
  toolbar: null,
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  toolbar: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}
