import type { Channel } from '~/utils/types'

export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export function setDismissedCookie(name: string): void {
  const expiresInDays = 30 * 24 * 60 * 60
  document.cookie = `${name}=dismissed; max-age=${expiresInDays};`
}

export function hasDismissedCookie(name: string): boolean {
  const match = document.cookie.match(new RegExp(`${name}=dismissed;*`))
  return !!match
}

export function isSupportedChannel(channel: Channel): boolean {
  return !!['instagram', 'facebook', 'twitter', 'linkedin'].find((c) => {
    if (channel.service_type === 'linkedin') {
      return channel.channel_type !== 'profile'
    }
    return c === channel.service_type
  })
}

export function getUnsupportedChannels(channels: Channel[] = []): Channel[] {
  return channels.filter((channel) => !isSupportedChannel(channel))
}
