import { connect } from 'react-redux'
import StoriesSummaryTable from './components/StoriesSummaryTable'

// default export = container
export default connect((state) => ({
  // @ts-expect-error TS(2339) FIXME: Property 'storiesSummary' does not exist on type '... Remove this comment to see the full error message
  loading: state.storiesSummary.loading,
  // @ts-expect-error TS(2339) FIXME: Property 'storiesSummary' does not exist on type '... Remove this comment to see the full error message
  metrics: state.storiesSummary.metrics,
  // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  startDate: state.date.startDate,
  // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
  endDate: state.date.endDate,
}))(StoriesSummaryTable)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { Table } from './components/StoriesSummaryTable' // eslint-disable-line import/no-named-as-default
export { default as Title } from './components/Title'
