import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  GridList,
  ReferralIcon,
} from '~/shared-components'
import AddReport from '~/add-report'
import Title from '../Title'

const Container = styled.div``

export class ShopifySourcesTable extends React.Component {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { loading, metrics, forReport } = this.props

    if (loading) {
      // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
      return <Loading active noBorder />
    } else if (metrics.length === 0) {
      return <NoData chartName="shopify-sources" />
    }
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    const metricsWithIcon = metrics.map((item) => ({
      source: item.source,
      metrics: [...item.metrics],
      icon: <ReferralIcon item={item} />,
    }))

    return (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <Container id="js-dom-to-png-shopify-sources" forReport={forReport}>
        <GridList metrics={metricsWithIcon} forReport={forReport} />
      </Container>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ShopifySourcesTable.defaultProps = {
  metrics: [],
  forReport: false,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ShopifySourcesTable.propTypes = {
  metrics: PropTypes.array,
  forReport: PropTypes.bool,
}

export class ShopifySources extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.fetch()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    if (
      // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'Reado... Remove this comment to see the full error message
      prevProps.startDate !== this.props.startDate ||
      // @ts-expect-error TS(2339) FIXME: Property 'endDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      prevProps.endDate !== this.props.endDate
    ) {
      // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.props.fetch()
    }
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'metrics' does not exist on type 'Readonl... Remove this comment to see the full error message
      metrics,
      // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      loading,
      // @ts-expect-error TS(2339) FIXME: Property 'hasError' does not exist on type 'Readon... Remove this comment to see the full error message
      hasError,
      // @ts-expect-error TS(2339) FIXME: Property 'onSelectMetric' does not exist on type '... Remove this comment to see the full error message
      onSelectMetric,
      // @ts-expect-error TS(2339) FIXME: Property 'selectedMetric' does not exist on type '... Remove this comment to see the full error message
      selectedMetric,
      // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Reado... Remove this comment to see the full error message
      forReport,
    } = this.props

    let content = null

    if (hasError) {
      content = null
    } else {
      content = (
        <ShopifySourcesTable
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          loading={loading}
          metrics={metrics}
          selectedMetric={selectedMetric}
        />
      )
    }
    return (
      <ChartCard hasError={hasError}>
        <ChartHeader>
          <Title
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            onSelectMetric={onSelectMetric}
            selectedMetric={selectedMetric}
            forReport={forReport}
            loading={loading}
          />
          <AddReport chart="shopify-sources" state={{ selectedMetric }} />
        </ChartHeader>
        {content}
      </ChartCard>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ShopifySources.defaultProps = {
  metrics: [],
  profiles: [],
  onSelectMetric: () => {},
  selectedMetric: 'visitors',
  forReport: false,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ShopifySources.propTypes = {
  metrics: PropTypes.array,
  profiles: PropTypes.array,
  onSelectMetric: PropTypes.func,
  selectedMetric: PropTypes.string,
  forReport: PropTypes.bool,
}

export default ShopifySources
