import {
  actionTypes as exportActionTypes,
  actions as exportActions,
} from '~/png-export'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch } = store
  switch (action.type) {
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-audience-summary',
          'audience-summary',
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
