import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Diff from './components/Diff'
import Label from './components/Label'
import Value from './components/Value'
import GridItemChart from './components/GridItemChart'
import ArrowIcon from '../ArrowIcon'
import PercentageBar from '../PercentageBar'
import Tooltip from '@bufferapp/ui/Tooltip'
import helpers from '../Helper/helperDictionary'

const Item = styled.li`
  display: flex;
  list-style: none;
  box-sizing: border-box;
  width: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.width};
  justify-content: center;

  margin: 0 0
    ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'inline' does not exist on type 'ThemedSt... Remove this comment to see the full error message
      props.inline ? '' : '1.375rem'};

  &:first-child > div {
    padding-left: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'withChart' does not exist on type 'Theme... Remove this comment to see the full error message
      props.withChart ? '0' : ''};
    padding-right: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'withChart' does not exist on type 'Theme... Remove this comment to see the full error message
      props.withChart ? '1.5rem' : ''};
  }

  &:last-child > div {
    padding-left: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'withChart' does not exist on type 'Theme... Remove this comment to see the full error message
      props.withChart ? '1.5rem' : ''};
    padding-right: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'withChart' does not exist on type 'Theme... Remove this comment to see the full error message
      props.withChart ? '0' : ''};
  }
`

const TableItem = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  width: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.width};
  padding-left: 0;
  padding-right: 1.5rem;
`

const Container = styled.div`
  width: 100%;
`

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;

  h3 {
    margin: 0;
  }
`

const GridItemChartContainer = styled.div`
  border: 1px solid #ccdae5;
  border-width: 0 0 2px;
  margin: 0 0 1rem;
`
const ArrowIconContainer = styled.span`
  display: flex;
  margin-left: 8px;
  align-items: center;
`

const PercentageBarContainer = styled.div`
  width: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.width};
`

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyData' implicitly has an 'any' type... Remove this comment to see the full error message
function filterDailyDataMetrics(dailyData, metricLabel) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
  return dailyData.map((day) => ({
    ...day,
    metric: day.metrics
      // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
      .filter((metric) => metric.label === metricLabel)
      .shift(),
  }))
}

const GridItem = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'chartType' implicitly has an 'any... Remove this comment to see the full error message
  chartType,
  // @ts-expect-error TS(7031) FIXME: Binding element 'chartSize' implicitly has an 'any... Remove this comment to see the full error message
  chartSize,
  // @ts-expect-error TS(7031) FIXME: Binding element 'controlMinMax' implicitly has an ... Remove this comment to see the full error message
  controlMinMax,
  // @ts-expect-error TS(7031) FIXME: Binding element 'customLabel' implicitly has an 'a... Remove this comment to see the full error message
  customLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'dailyData' implicitly has an 'any... Remove this comment to see the full error message
  dailyData,
  // @ts-expect-error TS(7031) FIXME: Binding element 'gridWidth' implicitly has an 'any... Remove this comment to see the full error message
  gridWidth,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hideDiff' implicitly has an 'any'... Remove this comment to see the full error message
  hideDiff,
  // @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
  metric,
  // @ts-expect-error TS(7031) FIXME: Binding element 'prefix' implicitly has an 'any' t... Remove this comment to see the full error message
  prefix,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showArrowIcon' implicitly has an ... Remove this comment to see the full error message
  showArrowIcon,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showPercentSign' implicitly has a... Remove this comment to see the full error message
  showPercentSign,
  // @ts-expect-error TS(7031) FIXME: Binding element 'smaller' implicitly has an 'any' ... Remove this comment to see the full error message
  smaller,
  // @ts-expect-error TS(7031) FIXME: Binding element 'standalone' implicitly has an 'an... Remove this comment to see the full error message
  standalone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'tooltip' implicitly has an 'any' ... Remove this comment to see the full error message
  tooltip,
  // @ts-expect-error TS(7031) FIXME: Binding element 'inline' implicitly has an 'any' t... Remove this comment to see the full error message
  inline,
}) => {
  const dailyMetricData = filterDailyDataMetrics(dailyData, metric.label)

  if (metric.key === 'spend' && metric.paid) {
    const [currency, value] = metric.paid.split('_')
    metric.currency = currency
    metric.paid = parseFloat(value)
  }

  const valuePaid = parseFloat(metric.paid)
  const valueOrganic = parseFloat(metric.value)

  const content = (
    <Container>
      {dailyData.length > 1 && (
        <GridItemChartContainer>
          <GridItemChart
            dailyData={dailyMetricData}
            chartType={chartType}
            chartSize={chartSize}
            controlMinMax={controlMinMax}
          />
        </GridItemChartContainer>
      )}
      <Label tooltip={tooltip}>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any[]; label: any; }' is not ass... Remove this comment to see the full error message */}
        <Tooltip label={helpers[metric.label?.toLowerCase()]}>
          <span>
            {!customLabel && metric.label}
            {customLabel && customLabel}
          </span>
        </Tooltip>
      </Label>
      <ValueWrapper>
        <Value
          hideValue={metric.key === 'spend' && !metric.paid}
          currency={metric.currency}
          smaller={smaller}
          showPercentSign={showPercentSign}
        >
          {metric.paid ? valueOrganic + valuePaid : valueOrganic}
        </Value>
        {!hideDiff && <Diff diff={metric.diff} />}
        {hideDiff && showArrowIcon && (
          <ArrowIconContainer>
            <ArrowIcon diff={metric.diff} />
          </ArrowIconContainer>
        )}
      </ValueWrapper>
      {!!metric.paid && metric.key !== 'spend' && (
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <PercentageBarContainer width={dailyData.length ? '95%' : '75%'}>
          <PercentageBar organic={valueOrganic} paid={valuePaid} />
        </PercentageBarContainer>
      )}
    </Container>
  )

  if (standalone) {
    return (
      <TableItem
        key={metric.label}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        width={gridWidth}
        inline={inline}
        withChart={dailyData.length > 0}
        chartType={chartType}
      >
        {prefix && prefix}
        {content}
      </TableItem>
    )
  }

  return (
    <Item
      key={metric.label}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      width={gridWidth}
      inline={inline}
      withChart={dailyData.length > 0}
      chartType={chartType}
    >
      {prefix && prefix}
      {content}
    </Item>
  )
}

GridItem.defaultProps = {
  chartType: 'column',
  controlMinMax: false,
  customLabel: null,
  dailyData: [],
  gridWidth: '25%',
  hideDiff: false,
  prefix: null,
  showArrowIcon: false,
  showPercentSign: false,
  smaller: false,
  standalone: false,
  tooltip: null,
  inline: false,
}

GridItem.propTypes = {
  chartType: PropTypes.string,
  chartSize: PropTypes.string,
  controlMinMax: PropTypes.bool,
  customLabel: PropTypes.element,
  dailyData: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({
          currency: PropTypes.string,
          diff: PropTypes.number,
          label: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
    }),
  ),
  gridWidth: PropTypes.string,
  hideDiff: PropTypes.bool,
  metric: PropTypes.shape({
    currency: PropTypes.string,
    diff: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  prefix: PropTypes.element,
  showArrowIcon: PropTypes.bool,
  showPercentSign: PropTypes.bool,
  smaller: PropTypes.bool,
  standalone: PropTypes.bool,
  tooltip: PropTypes.string,
  inline: PropTypes.bool,
}

export default GridItem
