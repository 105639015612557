import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import NavSidebar from './components/NavSidebar'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'account' does not exist on type 'Default... Remove this comment to see the full error message
    isOwner: state.account.isOwner,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    organizationId: state.profiles.organizationId,
    // @ts-expect-error TS(2339) FIXME: Property 'navSidebar' does not exist on type 'Defa... Remove this comment to see the full error message
    channels: state.navSidebar.channels,
    isGlobalUser:
      // @ts-expect-error TS(2339) FIXME: Property 'appSidebar' does not exist on type 'Defa... Remove this comment to see the full error message
      state.appSidebar.user.productlinks &&
      // @ts-expect-error TS(2339) FIXME: Property 'appSidebar' does not exist on type 'Defa... Remove this comment to see the full error message
      state.appSidebar.user.productlinks.length > 0,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
    onClick: (path) => dispatch(push(path)),
  }),
)(NavSidebar)

export { default as reducer } from './reducer'
