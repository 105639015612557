import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'

import reducer, { actions } from './reducer'
import middleware from './middleware'
import CampaignPulse from './components/CampaignPulse'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPulse' does not exist on type 'D... Remove this comment to see the full error message
    loading: state.campaignPulse.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPulse' does not exist on type 'D... Remove this comment to see the full error message
    hasError: state.campaignPulse.hasError,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignPulse' does not exist on type 'D... Remove this comment to see the full error message
    metrics: state.campaignPulse.metrics,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignSelector' does not exist on type... Remove this comment to see the full error message
    campaign: state.campaignSelector.selectedCampaign,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
    fetch: (campaign) => dispatch(actions.fetch(campaign)),
  }),
)(CampaignPulse)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'

export { default as Title } from './components/Title'
export { CampaignPulseGrid as Grid } from './components/CampaignPulse'

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('campaignPulse', reducer)
addMiddleware(middleware)
