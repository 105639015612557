import React from 'react'
import PropTypes from 'prop-types'
import { ChartTitle } from '~/shared-components'

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
function getSelectedMetricsGroup(metrics, selectedGroup) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  return metrics.find((group) => group.key === selectedGroup)
}

// @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
const Title = ({ forReport, selectedGroup, metrics }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: any[]; forReport: any; }' is not... Remove this comment to see the full error message
  <ChartTitle forReport={forReport}>
    Top countries{' '}
    {forReport && metrics.length > 1 && (
      <span style={{ textTransform: 'lowercase' }}>
        for {getSelectedMetricsGroup(metrics, selectedGroup).label}
      </span>
    )}
  </ChartTitle>
)

Title.propTypes = {
  forReport: PropTypes.bool,
  selectedGroup: PropTypes.string,
  metrics: PropTypes.arrayOf(PropTypes.shape({})),
}

Title.defaultProps = {
  forReport: false,
  selectedGroup: '',
  metrics: null,
}

export default Title
