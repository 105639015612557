import { connect } from 'react-redux'
import SummaryTable from './components/SummaryTable'

import { actions } from './reducer'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'summary' does not exist on type 'Default... Remove this comment to see the full error message
    loading: state.summary.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'summary' does not exist on type 'Default... Remove this comment to see the full error message
    metrics: state.summary.metrics,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    service: state.profiles.selectedProfileService,
    // @ts-expect-error TS(2339) FIXME: Property 'boostedPostsNotice' does not exist on ty... Remove this comment to see the full error message
    adsEnabled: state.boostedPostsNotice?.adsEnabled || false,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profile: state.profiles.selectedProfile,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
    save: (data) => dispatch(actions.save(data)),
  }),
)(SummaryTable)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
export { Table } from './components/SummaryTable'
export { default as Title } from './components/Title'
